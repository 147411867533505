import {
    LOAD_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    UNREAD_NOTIFICATIONS,
    READ_NOTIFICATIONS_BY_VIEW_ID,
} from './action-types';

const initialState = {
    notificationList: [],
    unreadNotificationList: [],
    viewId: [],
    meta: [],
    isLoading: false,
};

export function notificationManagement(state = initialState, { type, payload = null }) {
    switch (type) {
    case LOAD_NOTIFICATIONS:
        return {
            ...state,
            notificationList: payload.data,
            meta: payload.meta,
        };
    default:
        return state;
    case READ_NOTIFICATIONS:
        return {
            ...state,
            notificationList: payload,
        };
    case UNREAD_NOTIFICATIONS:
        return {
            ...state,
            unreadNotificationList: payload,
        };
    case READ_NOTIFICATIONS_BY_VIEW_ID:
        return {
            ...state,
            notificationId: payload,
        };
    }
}