import { getCookieFromBrowser } from './Cookie';
import qs from 'querystring';

const DOMAIN = process.env.REACT_APP_DOMAIN_API;

export const fetchApi = (url, config) =>
  config ? fetch(DOMAIN + url, config) : fetch(DOMAIN + url);

const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const Http = (url, config = {}) => {
  const token = getCookieFromBrowser('token');

  const { headers, body, params, ...rest } = config;
  const query = {
    values: '',
  };

  if (Object.keys(config) === 0) {
    return fetchApi(url);
  }

  if (params) {
    query.values = qs.stringify(params);
  }

  if (query.values) {
    query.values = `?${query.values}`;
  }

  return fetchApi(url + query.values, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      ...commonHeaders,
      ...(headers && { ...headers }),
    },
    ...rest,
    ...(body && { body }),
  });
};

export default Http;
