import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// services
import { getUnreadNotification } from '../../../modules/admin/notification-management/service';
import { useLocation } from 'react-router-dom';
// import { getNotification } from '../../../modules/admin/notification-management/service';
// import { readNotification } from '../../../modules/admin/notification-management/service';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [notificationOpen, setNotification] = React.useState(false);
  let unread = 0;
  const notification = useSelector((state) => state.notificationManagement);

  const dispatch = useDispatch();
  let notifications = [];
  const router = useLocation();
  const [invisible, setInvisible] = React.useState(true);

  React.useEffect(() => {
    dispatch(getUnreadNotification());
  }, [notification.unreadNotificationList.length]); // eslint-disable-line

  unread = notification.unreadNotificationList.length;
  notification.unreadNotificationList.forEach((values) => {
    notifications.push(values);
  });

  React.useEffect(() => {
    if (unread !== 0) {
      setInvisible(false);
    }
  }, [unread]);

  const handleClick = (event) => {
    setInvisible(true);
    setAnchorEl(event.currentTarget);
    setNotification(!notificationOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(getUnreadNotification());
    notifications = [];
  };

  const displayStyle = (params) => {
    let listItems = [];
    params.forEach(function (item, index) {
      listItems.push(
        <StyledMenuItem key={index} style={{ maxHeight: '400px' }}>
          <Link to={'/admin/notification-management'}>
            <ListItemIcon
              key="notification-management"
              selected={router.pathname === '/admin/notification-management'}
            >
              <Grid container>
                <Grid item>
                  <Typography style={{ textTransform: 'capitalize' }}>
                    {item.data.inquiry_type.replace(/_/g, ' ')}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemIcon>
          </Link>
        </StyledMenuItem>
      );
    });
    return listItems;
  };

  return (
    <div>
      <Badge
        overlap="rectangular"
        badgeContent={unread}
        color="error"
        onClick={handleClick}
        invisible={invisible}
      >
        {unread !== 0 ? <NotificationsActiveIcon /> : <NotificationsIcon />}
      </Badge>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.length !== 0 ? (
          displayStyle(notifications)
        ) : (
          <StyledMenuItem>
            <ListItemIcon>No new items</ListItemIcon>
          </StyledMenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
