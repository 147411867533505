// @material-ui/core components
import { Card, CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// styles
import { makeStyles } from '@material-ui/core/styles';
import styles from '../assets/jss/styles/layouts/common/commonStyle.js';
// components
import Header from './common/components/Header';
import Footer from './common/components/Footer';
import { Helmet } from 'react-helmet';
// metadata

const useStyles = makeStyles(styles);
const Page404 = (props) => {
  const { isCommonPage, pageExpired = false } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{pageExpired ? '419 - Page has Expired' : '404 - Page Not Found'}</title>
      </Helmet>
      {isCommonPage === undefined && <Header />}
      <main
        className={
          isCommonPage === undefined ? classes.mainContent : classes.pageNotFoundMainContent
        }
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: '55vh', marginTop: '125px' }}
        >
          <Grid item xs={12}>
            <Card className={classes.cardRoot} style={{ display: 'flex', alignItems: 'center' }}>
              <CardContent>
                {pageExpired ? (
                  <div className={classes.notFound}>
                    <Typography variant="h2" style={{ fontWeight: 900 }}>
                      419
                    </Typography>
                    <Typography variant="h4">Page has Expired</Typography>
                    <Typography variant="h6">( ページの有効期限が切れました)</Typography>
                  </div>
                ) : (
                  <div className={classes.notFound}>
                    <Typography variant="h2" style={{ fontWeight: 900 }}>
                      404
                    </Typography>
                    <Typography variant="h4">Page Not Found</Typography>
                    <Typography variant="h5">( ページが見つかりません )</Typography>
                    <Typography paragraph>
                      We're sorry, but we can't find the page you were looking for.
                    </Typography>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {isCommonPage === undefined && <Footer />}
      </main>
    </div>
  );
};

export default Page404;
