import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LogoutModal = (props) => {
  const { open, handleClose, handleConfirm } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      style={{ zIndex: 99999 }}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{process.env.REACT_APP_FRONTEND} says</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          本当にサインアウトしますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleConfirm} variant="contained">
          {t('Yes')}
        </Button>
        <Button color="secondary" onClick={handleClose} variant="contained">
          {t('No')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
