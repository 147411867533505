import {
  FETCH_COUNTRIES,
  FAIL_FETCH_COUNTRIES,
  FETCH_SEARCH_ACTIVITIES,
  SUCCESS_SEARCH_ACTIVITIES,
  FAIL_SEARCH_ACTIVITIES,
  FETCHING_COUNTRIES,
} from './action-types';

const initialState = {
  countries: [],
  cities: [],
  fetchingCountries: false,
  failFetchingCountries: false,
  isFetching: false,
  fail: false,
  error: {},
  searchResults: {},
  loading: false,
};

export function searchResult(state = initialState, { type, payload = null }) {
  switch (type) {
    case FETCH_COUNTRIES:
      return storeCountries(state, payload);
    case FETCHING_COUNTRIES:
      return fetchingCountries(state);
    case FAIL_FETCH_COUNTRIES:
      return storeFailCountries(state, payload);
    case FETCH_SEARCH_ACTIVITIES:
      return fetchSearchActivities(state);
    case SUCCESS_SEARCH_ACTIVITIES:
      return storeSearchActivities(state, payload);
    case FAIL_SEARCH_ACTIVITIES:
      return storeFailSearchActivities(state, payload);
    default:
      return state;
  }
}

function fetchingCountries(state) {
  return Object.assign({}, state, {
    ...state,
    fetchingCountries: true,
    failFetchingCountries: false,
  });
}

function storeCountries(state, payload) {
  return Object.assign({}, state, {
    ...state,
    countries: payload.data,
    fetchingCountries: false,
    failFetchingCountries: false,
  });
}

function storeFailCountries(state, payload) {
  return Object.assign({}, state, {
    ...state,
    isFetching: false,
    fail: true,
    fetchingCountries: false,
    failFetchingCountries: true,
    error: payload.error,
  });
}

function fetchSearchActivities(state) {
  return Object.assign({}, state, {
    ...state,
    loading: true,
  });
}

function storeSearchActivities(state, payload) {
  return Object.assign({}, state, {
    ...state,
    searchResults: payload,
    loading: false,
  });
}

function storeFailSearchActivities(state, payload) {
  return Object.assign({}, state, {
    ...state,
    error: payload,
    searchResults: {},
    loading: false,
  });
}
