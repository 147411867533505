import React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

function RouteListener({ children }) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    handleRouteChange();
  }, [location.pathname]); // eslint-disable-line

  // handle for route change
  const handleRouteChange = () => {
    // change country url with space/uppercase and transform to lowercase
    const path = location.pathname.split('/');
    let hasChanges = false;
    if (location.pathname.includes('country')) {
      const country = path[2].replace(' ', '-').toLowerCase();
      path[2] = country;

      hasChanges = true;
    }

    // change name url for mobi page
    if (location.pathname.includes('mobi')) {
      const name = path[2].replace(' ', '-').toLowerCase();
      path[2] = name;
      hasChanges = true;
    }

    // change city url with space/uppercase and transform to lowercase
    if (location.pathname.includes('city')) {
      const country = path[4].replace(' ', '-').toLowerCase();
      path[4] = country;

      // redirect city local report detail to country local report detail
      if (location.pathname.includes('local-report-detail')) {
        const newPath = path.filter((_, index) => index <= 2);
        newPath.push('local-report-detail');
        newPath.push(path[path.length - 1]);

        history.replace(newPath.join('/'));
        return;
      }

      hasChanges = true;
    }
    // change route is has modification
    if (hasChanges) history.replace(`${path.join('/')}${location.search}`);
  };
  return <>{children}</>;
}

export default RouteListener;
