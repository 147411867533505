import * as types from './action-types';

const initialState = {
    fetching: false,
    failed: false,
    items: [],
    error: {},
    meta: {},
    countries: [],
    cities: [],
    fetchingCountries: false,
    failFetchingCountries: false,
};

export function reservationManagement(state = initialState, {type, payload = null}) {
    switch (type) {
    case types.REQUEST_FETCH_RESERVATION:
        return {
            ...state,
            fetching: true,
            failed: false,
        };
    
    case types.FETCH_RESERVATION:
        return {
            ...state,
            fetching: false,
            failed: false,
            items: payload.data,
            meta: payload.meta
        };
    
    case types.FAIL_FETCH_RESERVATION:
        return {
            ...state,
            fetching: false,
            failed: true,
            error: payload
        };

    case types.FETCH_COUNTRIES:
        return {
            ...state,
            countries: payload.data,
            fetchingCountries: false,
            failFetchingCountries: false,
        };    

    case types.FETCHING_COUNTRIES:
        return {
            ...state,
            fetchingCountries: true,
            failFetchingCountries: false,
        };    

    case types.FAIL_FETCH_COUNTRIES:
        return {
            ...state,
            isFetching: false,
            fail: true,
            fetchingCountries: false,
            failFetchingCountries: true,
            error: payload.error
        };    

    case types.SUCCESS_UPDATE_PAYMENT_STATUS:
        return {
            ...state,
        };
    
    // update email template
    case types.UPDATE_EMAIL_TEMPLATE: 
        return {
            ...state, 
            items: state.items.map(item => {
                if(payload.reservation_id === item.id){
                    return {
                        ...item,
                        booking_details: payload,
                    }
                }

                return item;
            }),
        }
    default:
        return state;
    }
}
