import React from 'react';
// @material-ui/core components
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import NotificationAlert from '../../../../../components/common/NotificationAlert';
// translation
import { useTranslation } from 'react-i18next';

const PeriodicChangePassword = (props) => {
  const { email, periodicChangePassword, setIsPassExpired } = props;

  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState({
    confirmationCode: '',
    currentPassword: '',
    password: '',
    confirmPassword: '',
    email: email,
  });
  const [open, setOpen] = React.useState(true);
  const [notificationAlertProps, setNotificationAlertProps] = React.useState({
    message: '',
    type: '',
    open: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(!open);
    setIsPassExpired(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    periodicChangePassword(formValues)
      .then(() => {
        setOpen(!open);
        setIsLoading(false);
        setNotificationAlertProps({
          message: 'The password has been changed successfully.',
          type: 'success',
          open: true,
          handleNotificationToggle: handleNotificationToggle,
        });
      })
      .catch((data) => {
        setIsLoading(false);
        let message = data.error || 'Server Error';

        if (data.error && data.error.includes('Invalid verification code provided')) {
          message = t('The entered confirmation code is incorrect');
        }

        if (data.error === 'Password and confirmation password do not match.') {
          message = t('The password and the confirmation password do not match please confirm');
        }

        if (data.error && data.error.includes('Password does not conform to policy')) {
          message = t('Password Policy Error');
        }

        if (data.error && data.error.includes('[Password] expected string length to be >= 6')) {
          message = t('Password Policy Error');
        }

        if (data.error && data.error.includes('Attempt limit exceeded')) {
          message = t('Attempt limit exceeded, please try after some time');
        }

        setNotificationAlertProps({
          message,
          type: 'error',
          open: true,
          handleNotificationToggle: handleNotificationToggle,
        });
      });
  };

  const handleNotificationToggle = () => {
    setNotificationAlertProps({
      message: '',
      type: '',
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> {t('Change your password')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="confiramation-code">{t('Verification Code')}</InputLabel>
            <Input
              id="confiramation-code"
              aria-describedby="confiramation-code-helper"
              type="number"
              name="confirmationCode"
              value={formValues.confirmationCode}
              onChange={handleInputChange}
            />
            <FormHelperText id="confiramation-code-helper"></FormHelperText>
          </FormControl>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="email">{t('email_address')}</InputLabel>
            <Input
              id="email"
              aria-describedby="email-helper"
              type="email"
              name="email"
              value={email}
              disabled
            />
            <FormHelperText id="email-helper"></FormHelperText>
          </FormControl>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="current-password">{t('Current Password')}</InputLabel>
            <Input
              id="confiramation-code"
              aria-describedby="current-password-helper"
              type="password"
              name="currentPassword"
              value={formValues.currentPassword}
              onChange={handleInputChange}
            />
            <FormHelperText id="current-password-helper"></FormHelperText>
          </FormControl>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="new-password">{t('new password')}</InputLabel>
            <Input
              id="new-password"
              aria-describedby="new-password-helper"
              type="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <FormHelperText id="new-password-helper"></FormHelperText>
          </FormControl>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="confirm-password">{t('Confirm password')}</InputLabel>
            <Input
              id="confirm-password"
              aria-describedby="confirm-password-helper"
              type="password"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
            />
            <FormHelperText id="confirm-password-helper"></FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" fullWidth={true} onClick={handleSubmit}>
            {isLoading ? <CircularProgress color="secondary" /> : t('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <NotificationAlert {...notificationAlertProps} />
    </React.Fragment>
  );
};

export default PeriodicChangePassword;
