import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Parser from 'html-react-parser';
// styles
import { Portal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import notificationAlertStyle from '../../../assets/jss/styles/components/common/notificationAlertStyle';
import { hideAlertNotifications } from '../store/actionCreator';

const useStyles = makeStyles(notificationAlertStyle);

const AlertNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.alertNotifications);

  const colorClasses = classNames({
    [classes.success]: notifications.type === 'success',
    [classes.danger]: notifications.type === 'error',
  });

  const handleNotificationToggle = () => {
    dispatch(hideAlertNotifications());
  };

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notifications.show}
        autoHideDuration={notifications.type === 'success' ? 3000 : 5000}
        message={
          <div>
            {notifications.type === 'error' ? <Error className={classes.icon} /> : null}
            {notifications.type === 'success' ? <CheckCircle className={classes.icon} /> : null}
            <span className={classes.iconMessage}>{Parser(notifications.message)}</span>
          </div>
        }
        action={
          <IconButton
            className={classes.iconButton}
            aria-label="Close"
            key="close"
            color="inherit"
            onClick={handleNotificationToggle}
          >
            <Close className={classes.close} />
          </IconButton>
        }
        onClose={handleNotificationToggle}
        ContentProps={{
          classes: {
            root: classes.root + ' ' + colorClasses,
            message: classes.message,
          },
        }}
      />
    </Portal>
  );
};

export default AlertNotifications;
