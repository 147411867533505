import { LOAD_IMAGES, ROTATE_IMAGE } from './action-types';

const initialState = {
    images: []
};

export function imageManagement(state = initialState, {type, payload = null}) {
    switch (type) {
    case LOAD_IMAGES:
        return storeImages(state,  payload);
    case ROTATE_IMAGE:
        return rotateImage(state,  payload);
    default:
        return state;
    }
}

function storeImages(state, payload) {
    return Object.assign({}, state, {
        ...state,
        images: payload
    });
}

function rotateImage(state, payload) {
    return {
        ...state,
        images: {
            ...state.images,
            data: state.images.data.map(image => image.id === payload.id ? payload : image),
        }
    };
}