import { getCookie, removeCookie } from 'utils/Cookie';

const BACKEND = process.env.REACT_APP_DOMAIN_API;

class MembershipService {
  getCustomer(id) {
    const customerId = id ?? getCookie('c_id');
    const token = getCookie('c_token');
    return fetch(`${BACKEND}/v1/membership/get-customer?id=${customerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.status === 401) {
        removeCookie('c_token');
        removeCookie('c_expiration');
        removeCookie('c_email');
        removeCookie('c_id');

        window.location.href = '/membership/login?redirect_uri=' + window.location.pathname;
      }

      return res.json();
    });
  }
}

const membershipService = new MembershipService();

export default membershipService;
