import { defaultFont } from '../../../mainStyle';

const maxWidth = 480;

const layoutStyle = (theme) => ({
  root: {
    display: 'flex',
    ...defaultFont,
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  cardRoot: {
    marginTop: 25,
    minWidth: 350,
    minHeight: 350,
    textAlign: 'center',
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
  pageNotFoundMainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '63vh',
  },
  privacyPolicyHeader: {
    padding: '5px 15px',
    backgroundColor: '#ec5169',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  companyInfo: {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
  },
  backtoTop: {
    position: 'fixed',
    zIndex: 999,
    right: '16px',
    bottom: '16px',
  },
  backtoTopImg: {
    height: '60px',
    cursor: 'pointer',
    '@media (max-width: 390px)': {
      height: 44,
    },
  },
  notFound: {
    '@media (max-width: 400px)': {
      '& h2': {
        fontSize: '2.8em',
      },
      '& h4': {
        fontSize: '1.4em',
      },
    },
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    companyInfo: {
      display: 'inline-block',
    },
    root: {
      flexDirection: 'column',
    },
  },
});

export default layoutStyle;
