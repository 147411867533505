import {
    defaultFont,
    whiteColor,
    successColor,
    dangerColor,
} from '../../../mainStyle';

const notificationAlertStyle = {
    root: {
        ...defaultFont,
        zIndex: 9999,
    },
    icon: {
        display: 'block',
        left: '15px',
        position: 'absolute',
        top: '50%',
        marginTop: '-15px',
        width: '30px',
        height: '30px',
    },
    iconButton: {
        width: '24px',
        height: '24px',
        padding: '0px',
    },
    iconMessage: {
        paddingLeft: '50px',
        display: 'block',
        fontSize: '12px',
    },
    close: {
        width: '11px',
        height: '11px',
    },
    message: {
        padding: '0',
        display: 'block',
        maxWidth: '100%',
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
    },
};

export default notificationAlertStyle;
