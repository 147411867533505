import {
    LOAD_LOGS,
} from './action-types';

const initialState = {
    logs: [],
};

export function logManagement(state = initialState, {type, payload = null}) {
    switch (type) {
    case LOAD_LOGS:
        return storeLogs(state,  payload);
    default:
        return state;
    }
}

function storeLogs(state, payload) {
    return Object.assign({}, state, {
        ...state,
        logs: payload
    });
}