const countryFoldComponentStyle = {
  mainContainer: {
    marginTop: -28,
    '@media (max-width: 959px)': {
      marginTop: -12,
    },
  },
  searchBox: {
    display: 'flex',
    borderRadius: 5,
  },
  searchButton: {
    backgroundColor: '#EC5169',
    color: '#fff',
    marginLeft: 2,
    minWidth: 150,

    '&:hover': {
      backgroundColor: '#EC5169',
      color: '#fff',
    },

    '@media (max-width: 959.9px)': {
      display: 'none',
    },
  },
  searchContainer: {
    zIndex: 2,
    width: '80%',
  },
  searchIcon: {
    color: '#EC5169',
  },
  title: {
    color: '#fff',
    fontSize: '1.2em',

    '@media (max-width: 500px)': {
      fontSize: '0.9em',
    },
  },
  foldContainer: {
    paddingTop: 35,
    position: 'relative',
    width: '100%',
    marginTop: -28,
    zIndex: 1,
    '@media (max-width: 959px)': {
      marginTop: -16,
      paddingTop: 0,
    },
  },
  foldOverlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '60%',
    backgroundColor: '#EC5169',

    '@media (max-width: 959.9px)': {
      height: '100%',
      backgroundColor: 'transparent',
    },
  },
  foldContent: {
    position: 'relative',
    zIndex: 2,
  },
  imageOverlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: 0.4,
  },
  showCountryButton: {
    backgroundColor: '#EC5169',
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'normal',
    borderRadius: 0,
    height: 65,
    '&:hover': {
      backgroundColor: '#EC5169',
      color: '#FFFFFF',
    },
  },
  foldControl: {
    marginTop: '20px',
  },
  icons: {
    marginTop: 8,
  },
  searchTextField: {
    '& .MuiOutlinedInput-root': {
      height: 56,
      fontSize: 18,
      paddingLeft: 10,
      borderRadius: 30,
      '@media (max-width: 959px)': {
        height: 28,
        fontSize: 12,
      },
    },
    '& .MuiInputBase-root': {
      background: '#FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #999999 !important',
    },
  },
  thumbnail: {
    height: 98,
    '@media (max-width: 959px)': {
      height: 258,
    },
    '@media (max-width: 768px)': {
      height: 205,
    },
    '@media (max-width: 576px)': {
      height: 151,
    },
    '@media (max-width: 390px)': {
      height: 98,
    },
    background: 'gray',
    cursor: 'pointer',
    borderRadius: '2px',
    position: 'relative',
  },
  filterThumbnail: {
    height: 78,
    '@media (max-width: 959px)': {
      height: 218,
    },
    '@media (max-width: 768px)': {
      height: 165,
    },
    '@media (max-width: 576px)': {
      height: 111,
    },
    '@media (max-width: 376px)': {
      height: 80,
    },
    background: 'gray',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 2,
  },
  countryText: {
    textAlign: 'center',
    width: '100%',
    color: '#fff',
    position: 'absolute',
    top: '40%',
    zIndex: 2,
  },
  bottomLabel: {
    textAlign: 'center',
    width: '100%',
    color: '#fff',
    position: 'absolute',
    top: '40%',
    zIndex: 2,
    fontSize: 14,
  },
  loadingContainer: {
    marginTop: 12,
    padding: 24,
  },
  imagesContainer: {
    marginTop: 0,
    marginBottom: 28,
    '@media (max-width: 959px)': {
      marginTop: 14,
      marginBottom: 14,
    },
    paddingTop: 12,
    paddingLeft: 12,
    '@media (max-width: 575px)': {
      paddingLeft: 24,
    },
    '@media (max-width: 480px)': {
      paddingLeft: 12,
    },
  },
  imageItem: {
    paddingRight: 12,
    paddingBottom: 12,
    position: 'relative',
    boxSizing: 'border-box',
  },
  image: {
    width: 160,

    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1500px)': {
      width: 140,
    },
    '@media (max-width: 1400px)': {
      width: 130,
    },
    '@media (max-width: 1300px)': {
      width: 120,
    },
    '@media (max-width: 1200px)': {
      width: 105,
    },
    '@media (max-width: 1100px)': {
      width: 95,
    },
    '@media (max-width: 1000px)': {
      width: 90,
    },
    '@media (max-width: 959px)': {
      width: '100%',
    },
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: '60%',
  },
  filterImage: {
    '@media (max-width: 1600px)': {
      width: 130,
    },
    '@media (max-width: 1500px)': {
      width: 120,
    },
    '@media (max-width: 1400px)': {
      width: 110,
    },
    '@media (max-width: 1300px)': {
      width: 100,
    },
    '@media (max-width: 1200px)': {
      width: 85,
    },
    '@media (max-width: 1100px)': {
      width: 75,
    },
    '@media (max-width: 1000px)': {
      width: 70,
    },
    '@media (max-width: 959px)': {
      width: '80%',
    },
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: '60%',
  },
};

export default countryFoldComponentStyle;
