const privateRoutes = [
  {
    path: '/change-password',
    component: 'modules/auth/pages/change-password/ChangePassword',
    auth: true,
  },
  {
    path: '/dashboard',
    component: 'modules/admin/dashboard/Dashboard',
    auth: true,
  },
  {
    path: '/admin/categories-management',
    component: 'modules/admin/categories-management/pages/manage/CategoriesManagement',
    auth: true,
  },
  {
    path: '/admin/coupon-management',
    component: 'modules/admin/coupon-management/pages/manage/CouponManagement',
    auth: true,
  },
  {
    path: '/admin/email-subscription',
    component: 'modules/admin/email-subscription/pages/manage/EmailSubscription',
    auth: true,
  },
  {
    path: '/admin/exchange-rate-management',
    component: 'modules/admin/exchange-rate-management/pages/manage/ExchangeRateManagement',
    auth: true,
  },
  {
    path: '/admin/file-management',
    component: 'modules/admin/file-management/pages/FileManagement',
    auth: true,
  },
  {
    path: '/admin/hotel-management',
    component: 'modules/admin/hotel-management/pages/HotelManagement',
    auth: true,
  },
  {
    path: '/admin/hotel-management/upload-excel',
    component: 'modules/admin/hotel-management/pages/HotelExcelUpload',
    auth: true,
  },
  {
    path: '/admin/image-management',
    component: 'modules/admin/image-management/pages/manage/ImageManagement',
    auth: true,
  },
  {
    path: '/admin/inquiry-management',
    component: 'modules/admin/inquiry-management/pages/manage/InquiryManagement',
    auth: true,
  },
  {
    path: '/admin/log-management',
    component: 'modules/admin/log-management/pages/LogManagement',
    auth: true,
  },
  {
    path: '/admin/maintenance',
    component: 'modules/admin/maintenance/pages/MaintenancePage',
    auth: true,
  },
  {
    path: '/admin/notification-management',
    component: 'modules/admin/notification-management/pages/manage/NotificationManagement',
    auth: true,
  },
  {
    path: '/admin/reservation-management',
    component: 'modules/admin/reservation-management/pages/ReservationManagement',
    auth: true,
  },
  {
    path: '/admin/user-management',
    component: 'modules/admin/user-management/pages/manage/UserManagement',
    auth: true,
  },
  {
    path: '/admin/page-management',
    component: 'modules/admin/page-management/pages/manage/Management',
    auth: true,
  },
  {
    path: '/admin/instagram-management',
    component: 'modules/admin/instagram-management/pages/InstagramManagement',
    auth: true,
  },
  {
    path: '/admin/page-management/create/:id',
    component: 'modules/admin/page-management/pages/create/CreatePage',
    auth: true,
  },
  {
    path: '/admin/page-management/duplicate/:id',
    component: 'modules/admin/page-management/pages/duplicate/DuplicatePage',
    auth: true,
  },
  {
    path: '/admin/page-management/:id/update',
    component: 'modules/admin/page-management/pages/update/Update',
    auth: true,
  },
  {
    path: '/admin/page-management/:id/view',
    component: 'modules/admin/page-management/pages/view/ViewPage',
    auth: true,
  },
  {
    path: '/admin/popular-product-management',
    component: 'modules/admin/popular-product-management/pages/PopularProduct',
    auth: true,
  },
];

const routes = [
  ...privateRoutes,
  {
    path: '/',
    component: 'modules/top/pages/TopPage',
  },
  {
    path: '/booking-information',
    component: 'modules/booking-information/BookingInformation',
  },
  {
    path: '/booking-information/:booking_id',
    component: 'modules/booking-information/ChangeBooking',
  },
  {
    path: '/branch-information/:name',
    component: 'modules/branch-information/pages/BranchInformation',
  },
  {
    path: '/browse-history',
    component: 'modules/browse-history/pages/BrowseHistory',
  },
  {
    path: '/campaign/:id',
    component: 'modules/campaign/pages/Campaign',
  },
  {
    path: '/mobi/:country/:city',
    component: 'modules/mobi/pages/Mobi',
  },
  {
    path: '/category',
    component: 'modules/category/pages/Category',
  },
  {
    path: '/country/:country',
    component: 'modules/country/Country',
  },
  {
    path: '/country-category',
    component: 'modules/country/category/Category',
  },
  {
    path: '/country/:country/theme/:id',
    component: 'modules/country/CountryCategory',
  },
  {
    path: '/country/:country/category-detail/:categoryDetail',
    component: 'modules/country/category-detail/CategoryDetail',
  },
  {
    path: '/country/:country/local-information/:id',
    component: 'modules/country/LocalInformation',
  },
  {
    path: '/country/:country/local-report/:id',
    component: 'modules/country/LocalReport',
  },
  {
    path: '/country/:country/local-report-detail/:id',
    component: 'modules/country/LocalReportDetail',
  },
  {
    path: '/country/:country/news/:id',
    component: 'modules/country/LocalReportDetail',
  },
  // city routes
  {
    path: '/country/:country/city/:city',
    component: 'modules/country/city/City',
  },
  {
    path: '/country/:country/city/:city/local-information/:id',
    component: 'modules/country/city/LocalInformation',
  },
  {
    path: '/country/:country/city/:city/local-report/:id',
    component: 'modules/country/city/LocalReport',
  },
  {
    path: '/country/:country/city/:city/local-report-detail/:id',
    component: 'modules/country/city/LocalReportDetail',
  },
  {
    path: '/country/:country/city/:city/news',
    component: 'modules/country/city/NewsList',
  },
  {
    path: '/country/:country/city/:city/news/:id',
    component: 'modules/country/city/News',
  },
  {
    path: '/embed/:code',
    component: 'modules/embed/pages/Embed',
  },
  {
    path: '/faq/country/general-inquiry/:id',
    component: 'modules/faq/pages/GeneralInquiry',
  },
  {
    path: '/faq/country/inquiry/:id',
    component: 'modules/faq/pages/Inquiry',
  },
  {
    path: '/mail-magazine',
    component: 'modules/mail-magazine/pages/MailMagazine',
  },
  {
    path: '/reservation/:tour',
    component: 'modules/reservation/pages/ReservationPage',
  },
  {
    path: '/tour-detail/:tour',
    component: 'modules/tour-detail/pages/TourPage',
  },
  {
    path: '/tour-detail/:tour/reviews',
    component: 'modules/tour-detail/pages/Review',
  },
  {
    path: '/about-us',
    component: 'modules/about-us/pages/AboutUs',
  },
  {
    path: '/application-conditions',
    component: 'modules/application-conditions/pages/ApplicationConditions',
  },
  {
    path: '/company-profile',
    component: 'modules/company-profile/pages/CompanyProfile',
  },
  {
    path: '/customer-review-terms',
    component: 'modules/customer-review-terms/pages/CustomerReviewTerms',
  },
  {
    path: '/faq',
    component: 'modules/faq/pages/Faq',
  },
  {
    path: '/general-inquiry',
    component: 'modules/general-inquiry/pages/GeneralInquiry',
  },
  {
    path: '/inquiry',
    component: 'modules/inquiry/pages/Inquiry',
  },
  {
    path: '/callback',
    component: 'modules/membership/login/Callback',
  },
  {
    path: '/membership-terms',
    component: 'modules/membership/MembershipTerms',
  },
  {
    path: '/privacy-policy',
    component: 'modules/privacy-policy/pages/PrivacyPolicy',
  },
  {
    path: '/rakunavi',
    component: 'modules/rakunavi/pages/Rakunavi',
  },
  {
    path: '/terms',
    component: 'modules/terms/pages/Terms',
  },
];

export default routes;
