import React from 'react';
// @material-ui/core components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import NotificationAlert from '../../../../../components/common/NotificationAlert';
// translation
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const ForgotPasswordModal = (props) => {
  const router = useHistory();
  const { forgotPassword } = props;
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [notificationAlertProps, setNotificationAlertProps] = React.useState({
    message: '',
    type: '',
    open: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (_.isEmpty(email)) {
      setIsLoading(false);
      setNotificationAlertProps({
        message: t('email is required!'),
        type: 'error',
        open: true,
        handleNotificationToggle: handleNotificationToggle,
      });

      return;
    }

    forgotPassword(email)
      .then(() => {
        setOpen(!open);
        setIsLoading(false);
        router.push('/reset-password');
      })
      .catch((data) => {
        setIsLoading(false);
        setNotificationAlertProps({
          message: data.error,
          type: 'error',
          open: true,
          handleNotificationToggle: handleNotificationToggle,
        });
      });
  };

  const handleNotificationToggle = () => {
    setNotificationAlertProps({
      message: '',
      type: '',
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Button fullWidth={true} onClick={handleOpen}>
        {t('Forgot Password?')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> {t('Send Confirmation Code')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true} margin="dense" required={true}>
            <InputLabel htmlFor="email">{t('email_address')}</InputLabel>
            <Input
              id="email"
              aria-describedby="email-helper"
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <FormHelperText id="email-helper"></FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" fullWidth={true} onClick={handleSubmit}>
            {isLoading ? <CircularProgress color="secondary" /> : t('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <NotificationAlert {...notificationAlertProps} />
    </React.Fragment>
  );
};

export default ForgotPasswordModal;
