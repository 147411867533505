const membershipRoutes = [
  {
    path: 'booking-history',
    component: 'modules/membership/BookingHistory',
    auth: true,
  },
  {
    path: 'reviews',
    component: 'modules/membership/reviews/Reviews',
    auth: true,
  },
  {
    path: 'mypage',
    component: 'modules/membership/account/Account',
    auth: true,
  },
  {
    path: 'withdrawn',
    component: 'modules/membership/account/WithdrawnComplete',
    auth: true,
  },
  {
    path: 'login',
    component: 'modules/membership/login/Login',
  },
  {
    path: 'login/verify/:code',
    component: 'modules/membership/login/Verify',
  },
  {
    path: 'registration',
    component: 'modules/membership/registration/Registration',
  },
  {
    path: 'reset-password',
    component: 'modules/membership/reset-password/ResetPassword',
  },
  {
    path: 'reviews/create',
    component: 'modules/membership/reviews/CreateReview',
    auth: true,
  },
];

export default membershipRoutes;
