import { connect } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// @material-ui/icons

import MenuIcon from '@material-ui/icons/Menu';

// styles
import styles from '../../../assets/jss/styles/layouts/admin/headerStyle.js';
import Button from '@material-ui/core/Button';
//translation
import { useTranslation } from 'react-i18next';

import { authLogout } from '../../../modules/auth/store/actions';

import CustomizedMenus from '../../admin/components/Menu';

const useStyles = makeStyles(styles);
const Header = (props) => {
  const classes = useStyles();

  const { logout } = props;
  const { t, i18n } = useTranslation();

  const [translate, setTranslate] = React.useState(true);

  React.useEffect(() => {
    setTranslate(i18n.language === 'jp');
  }, []); // eslint-disable-line

  const onTranslate = () => {
    setTranslate(!translate);
    return i18n.changeLanguage(i18n.language === 'en' ? 'jp' : 'en');
  };

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerToggle}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {t('title')}
        </Typography>
        <span className={classes.enLabel}>EN</span>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={translate}
              onChange={() => onTranslate()}
              name="checkedB"
              color="default"
            />
          }
          label="JP"
        />
        <IconButton color="inherit" className={classes.notificationButton}>
          <CustomizedMenus />
        </IconButton>
        <Button className={classes.logout} onClick={logout}>
          {t('logout')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
  };
};

export default connect((state) => state, mapDispatchToProps)(Header);
