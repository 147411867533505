import { LOAD_SUBSCRIBER_LIST } from './action-types';

const initialState = {
    emailSubscriberList: [],
};

export function emailSubscription(state = initialState, {type, payload = null}) {
    switch (type) {
    case LOAD_SUBSCRIBER_LIST:
        return storeEmailSubscriberList(state,  payload);
    default:
        return state;
    }
}

function storeEmailSubscriberList(state, payload) {
    return {
        ...state,
        emailSubscriberList: payload,
    };
}
