import * as types from './action-types';

const initialState = {
    items: [],
    meta: {},
    fetching: true,
    searching: false,
    failed: false,
    updating: false,
    creating: false,
    deleting: false,
    errors: {},
    notifications: {
        state: false,
        type: 'success',
        message: ''
    }
}

export const categoriesManagement = (state = initialState, { type, payload}) => {
    switch(type) {

        // notification
        case types.HANDLE_NOTIFICATION:
            return {
                ...state,
                notifications: payload,
            };

        case types.HANDLE_CLOSE_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    state: payload,
                },
            };
        
        // search
        case types.SEARCHING_CATEGORIES:
            return {
                ...state,
                searching: true,
                failed: false,
            }
        case types.SEARCH_CATEGORIES:
            return {
                ...state,
                searching: false,
                failed: false,
                items: payload.data,
                meta:  payload.meta
            }
        case types.FAIL_SEARCH_CATEGORIES:
            return {
                ...state,
                searching: false,
                failed: true,
                errors: payload
            }

        // fetch
        case types.FETCHING_CATEGORIES:
            return {
                ...state,
                fetching: true,
                failed: false,
            }
        case types.FETCH_CATEGORIES:
            return {
                ...state,
                fetching: false,
                failed: false,
                items: payload.data,
                meta:  payload.meta
            }
        case types.FAIL_FETCH_CATEGORIES:
            return {
                ...state,
                fetching: false,
                failed: true,
                errors: payload
            }
        
        // update
        case types.REQUEST_UPDATE_CATEGORIES:
            return {
                ...state,
                updating: true,
                failed: false,
            }
        case types.UPDATE_CATEGORIES:
            return {
                ...state,
                updating: false,
                failed: false,
                items: state.items.map(item => {
                    if(item.id === payload.id){
                        return payload;
                    }

                    return item;
                })
            }
        case types.FAIL_UPDATE_CATEGORIES:
            return {
                ...state,
                updating: false,
                failed: true,
                errors: payload
            }

        // create
        case types.REQUEST_CREATE_CATEGORIES:
            return {
                ...state,
                creating: true,
                failed: false,
            }
        case types.CREATE_CATEGORIES:
            return {
                ...state,
                creating: false,
                failed: false,
                items: [payload, ...state.items],
            }
        case types.FAIL_CREATE_CATEGORIES:
            return {
                ...state,
                creating: false,
                failed: true,
                errors: payload
            }

        // delete
        case types.REQUEST_DELETE_CATEGORIES:
            return {
                ...state,
                deleting: true,
                failed: false,
            }
        case types.DELETE_CATEGORIES:
            return {
                ...state,
                deleting: false,
                failed: false,
                items: state.items.filter(item => item.id !== payload.id),
            }
        case types.FAIL_DELETE_CATEGORIES:
            return {
                ...state,
                deleting: false,
                failed: true,
                errors: payload
            }

        // default
        default: 
            return state;
    }
}
