const en = {
  translation: {
    accept_terms: 'MyBus I accept the Site Terms and Privacy Policy',
    access: 'Access',
    actual_inquiry: 'The actual inquiry (free)',
    admin_registration: 'Admin Account Registration',
    answer: 'Answer',
    asia: 'Asia',
    cancel: 'Cancel',
    create: 'Create',
    common_text: 'This is a common page.',
    company_profile: 'Company Profile',
    consultation_form: 'E-mail consultation form',
    copyright: 'Copyright © 2020',
    dashboard: 'Dashboard',
    dashboard_text: 'Hi! Welcome to your Dashboard',
    departure_date: 'Departure date from JP',
    destination_country_city: 'Destination (Country & City)',
    destinations: 'Destinations',
    edit_user_information: 'Edit Admin Information',
    email_address: 'Email address',
    email_address_correct: 'Email address (make sure it is correct)',
    email_text: "We'll never share your email.",
    english_name: 'Name in English',
    enter_comment: 'Enter comment here',
    enter_email_address: 'Enter email address',
    faq_note: 'Make sure to read the Frequently Asked Questions beforehand.',
    fill_up_required_field: 'Please enter required information',
    find_course: 'How to find the course number',
    first_name: 'First Name',
    general_inquiry: 'General Inquiry',
    home: 'Home',
    inquiry: 'Inquiry',
    how_to_use: 'How to use',
    inquiry_category: 'Inquiry Category',
    inquiry_information: 'Inquiry Information',
    inquiry_management: 'Inquiry Management',
    inquiry_notification: 'Inquiry Notification',
    image_management: 'Image Management',
    image_name: 'Image Name',
    inquiry_submit_success: 'Inquiry Submitted Successfully!',
    japanese_name: 'Name in Japanese',
    last_name: 'Last Name',
    login: 'Login',
    logout: 'Logout',
    lookup: "I have completed the application on 'Look JTB MySTYLE'",
    no: 'No',
    notification_management: 'Notification Management',
    oceania: 'Oceania',
    page_management: 'Page Management',
    password: 'Password',
    password_text: "We'll never share your email.",
    privacy_policy: 'Privacy Policy',
    proceed_confirm: 'Proceed to confirm',
    rakunavi_notification: 'Rakunavi Notification',
    rakunavi_support: 'Rakunavi support',
    rights_reserved: 'All rights reserved.',
    show_more: 'Show More',
    select_city: 'Select city',
    select_date: 'Select date',
    select_nation: 'Select nation',
    send_question: 'Send question',
    service_number: 'Service Number',
    title: 'JTB MyBus',
    user_management: 'User Management',
    yes: 'Yes',
    no_data_found: 'No data found',
    subscribe: 'Subscribe',
    available_today: 'Available Today',
    booked: 'Booked',
    sign_up_membership: 'Sign up for membership',
    subtotal: 'Subtotal',
    point_gained: 'Point gained',
    proceed_to_book: 'Proceed to book',
    city: 'City',
    country: 'Country',
    departure_date_time: 'Departure date and time',
    number_of_participants: 'Number of participants',
    remove_from_cart: 'Remove from cart',
    points_per_product: 'Points per product',
    recommendations: 'Recommendations',
    look_for_other_activities: 'Look for other activities',
    footer_text:
      "For overseas tours and optional tours, JTB's MyBus, We have a wealth of optional tours overseas in each country. There are many tours accompanied by guides who can speak Japanese so you can enjoy the charm of the area with confidence. Please experience the charm of overseas locals on an optional tour!",
    subscribe_newsletter:
      "Subscribe to JTB's newsletter to get the latest promos, exclusive discounts and helpful travel tips!",
    view_more: 'View More',
    coupon_management: 'Coupon Management',
    reservation_management: 'Reservation Management',
    logs: 'Log Management',
    email_subscription: 'Email subscriptions',
    change_password: 'Change Password',
    top_page: 'Top Page',
    REQUESTED: 'Requested',
    requesting_booking_message:
      'is requesting a reservation now. Please wait a while for the response.',
    unpaid_booking: 'Unpaid',
    STANDBY: 'Stand By',
    DECLINED: 'Declined',
    WITHDRAWN_BY_TRAVELER: 'Withdrawn by Customer',
    CANCELED_BY_TRAVELER: 'Cancelled by Customer',
    CANCELLED_BY_TRAVELER: 'Cancelled by Customer',
    CANCELED_BY_SUPPLIER: 'Cancelled by Supplier',
    CHANGED_BY_SUPPLIER: 'Changed by Supplier',
    categories_management: 'Categories Management',
    'For this resevation, Cancellation fee will be':
      'For this resevation, Cancellation fee will be',
    'Refund will be': 'Refund will be',
    'Do you want to cancel booking?': 'Do you want to cancel booking?',
    'No cancel fee for this booking': 'No cancel fee for this booking',
    Yes: 'Yes',
    No: 'No',
    'Cancel booking': 'Cancel booking',
    'Do you want to proceed capture payment?': 'Do you want to proceed capture payment?',
    'Need capture payment': 'Need capture payment',
    Done: 'Done',
    'Payment Status': 'Payment Status',
    'Capture Payment': 'Capture Payment',
    'Capture Payment Modal': 'Capture Payment?',
    'Cancel booking?': 'Cancel booking?',
    'Success Payment Capture': 'Success Payment Capture',
    'Send a Reply Email': 'Send Reply Email',
    email_sent_successfully: 'Email Sent Successfully!',
    email_sent_error: 'Error Sending Email',
    send_email: 'Send Email',
    booking_date_filter: 'Booking Date Filter',
    service_date_filter: 'Service Date Filter',
    disable_filter: 'Disable Filter',
    customer_name: 'Customer Name',
    tour_title: 'Tour Name',
    booking_date: 'Booking date',
    service_date: 'Service Date',
    plan_title: 'Plan Title',
    number_of_attendee: 'Number of Attendance',
    pickup_time: 'Pick up Time',
    pickup_location: 'Pick up Place',
    contact_number: 'Contact Number',
    emergency_number: 'Emergency Number',
    checkin_location_title: 'Staying Place',
    extra_booking_information: 'Extra Booking Information',
    paid_amount: 'Paid Amount',
    mail_sent: 'Mail Sent Date/Time',
    jtb_internal_use: 'JTB Internal Use',
    booking_information_comment_success: 'Booking Information Comment Updated Successfully!',
    booking_information_comment_fail: 'Booking Information Comment Update Fail',
    no_such_booking: 'Booking does not exist. Please try another one',
    no_veltra_booking: 'Booking does not exist in Veltra',
    edit_email: 'Edit Email',
    email_send_error: 'Email Send Error',
    'View More': 'View More',
    'Coupon Code': 'Coupon Code',
    'Coupon Name': 'Coupon Name',
    'Activity ID': 'Activity ID',
    Country: 'Country',
    City: 'City',
    Category: 'Category',
    'Purchase Price Conditions': 'Purchase Price Conditions',
    'Total Number Of Usage': 'Total Number Of Usage',
    'Discount Percentage': 'Discount Percentage',
    'Discount Value': 'Discount Value',
    'Discount Percentage/Value': 'Discount Percentage/Value',
    Currency: 'Currency',
    'Validity Start Date': 'Validity Start Date',
    'Validity End Date': 'Validity End Date',
    Status: 'Status',
    'Combination With Other Coupons': 'Combination With Other Coupons',
    'Issue Date': 'Issue Date',
    'Number Of Remaining': 'Number Of Remaining',
    Remarks: 'Remarks',
    'Except Day': 'Except Day',
    'Cancel Reservation': 'Cancel Reservation',
    Inquiries_changes_etc: 'Inquiries (changes, etc.)',
    'Automatic Cancellation Processing for VELTRA with API':
      'Automatic Cancellation Processing for VELTRA with API',
    'Cancellation Process on VELTRA by JTB branch staff':
      'Cancellation Process on VELTRA by JTB branch staff',
    'Confirmed Cancelling a Reservation': 'Confirmed Cancelling a Reservation',
    'Cancelling Reservation': 'Cancelling Reservation',
    'Cancel Booking Status': 'Cancel Booking Status',
    coupon_category: 'Category',
    None: 'None',
    'Specify (Limited)': 'Specify (Limited)',
    'Exclude Activity ID': 'Exclude Activity ID',
    'Coupon discount is required': 'Coupon discount is required',
    'Validating Coupons': 'Validating Coupons...',
    OK: 'OK',
    'Total Number Usage': 'Total Number Usage',
    'Search Reservation By Coupon': 'Search Reservation By Coupon',
    'Cancellation Fee': 'Cancellation Fee',
    'Issue Date From': 'Issue Date From',
    'Issue Date To': 'Issue Date To',
    'Validity Date From': 'Validity Date From',
    'Validity Date To': 'Validity Date To',
    'Validity Date': 'Validity Date',
    'Issue Date Range': 'Issue Date Range',
    'Login Verification': 'Login Verification',
    Resend: 'Resend',
    Confirm: 'Confirm',
    'Verification code successfully sent': 'Verification code successfully sent',
    'Resending verification code failed': 'Resending verification code failed',
    'Enter Verification Code': 'Enter Verification Code',
    'Verification Code': 'Verification Code',
    'Password Policy Error':
      'The password is not set correctly. Please set at least 8 characters including uppercase and lowercase letters, numbers, and special symbols',
    'Attempt limit exceeded, please try after some time':
      'Attempt limit exceeded, please try after some time',
    'Password must be greater than 5 in length': 'Password must be greater than 5 in length',
    'Mail Magazine Submitted Successfully': 'Mail Magazine Submitted Successfully',
    Register: 'Register',
    'Email address': 'Email Address',
    Password: 'Password',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Nickname: 'Nickname',
    Birthdate: 'Birthdate',
    'Phone number': 'Phone Number',
    'Enter the registration token': 'Enter the registration token.',
    'You have successfully registered': 'You have successfully registered!',
    Login: 'Login',
    'Forget Password': 'Forget Password',
    'Keep me login': 'Keep me login',
    'Social Media Login': 'Social Media Login',
    'You are not yet register, would you like to register':
      'You are not yet register, would you like to register',
    'Reset Password': 'Reset Password',
    'Manage account': 'Manage account',
    'Booking History': 'Booking History',
    'Browser History': 'Browser History',
    Reviews: 'Reviews',
    'Sign Out': 'Sign Out',
    Withdrawn: 'Withdrawn',
    Withdrawal: 'Withdrawal',
    Withdraw: 'Withdraw',
    Update: 'Update',
    'Personal Information': 'Personal Information',
    'Please enter your password for confirmation': 'Please enter your password for confirmation',
    'Confirm Reset Password': 'Confirm Reset Password',
    'Submit Review': 'Submit Review',
    file_management: 'File Management',
    'Hotel List Management': 'Hotel List Management',
    Maintenance: 'Maintenance',
    instagram_management: 'Instagram Management',
    popular_product: 'Popular Products',
  },
};

export default en;
