import { SET_SELECTED_CITIES } from './action-types';

const initialState = {
  selectedCities: [],
};

export function selections(state = initialState, { type, payload = null }) {
  switch (type) {
    case SET_SELECTED_CITIES:
      return {
        ...state,
        selectedCities: payload,
      };
    default:
      return state;
  }
}
