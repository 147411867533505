import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { refreshTokenMiddleware } from './authMiddleware';

const enhancers = [applyMiddleware(refreshTokenMiddleware(), thunkMiddleware)];

const store = createStore(rootReducer, {}, composeWithDevTools(...enhancers));

export default store;
