import Loader from 'layouts/Loader';
import CommonLayout from 'layouts/common/Layout';
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

function Public({ component, layout, ...rest }) {
  const Component = lazy(() => import(`../${component}`));

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <CommonLayout>
            <Component {...props} />
          </CommonLayout>
        </Suspense>
      )}
    />
  );
}

export default Public;
