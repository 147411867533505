import { defaultFont, secondaryColor } from '../../mainStyle';

const searchFilterStyle = {
  container: {
    width: '100%',
    height: '84%',
    zIndex: 3000,
    '@media (max-width: 769px)': {
      height: '88%',
    },
    '@media (max-width: 420px)': {
      height: '81%',
      paddingBottom: 40,
    },
    '@media (max-width: 415px)': {
      height: '81%',
      paddingBottom: 40,
    },
    '@media (max-width: 391px)': {
      height: '82%',
    },
    '@media (max-width: 376px)': {
      height: '80%',
    },
    '@media (max-width: 361px)': {
      height: '79%',
      paddingBottom: 40,
    },
  },
  filterContent: {
    marginTop: 20,
  },
  filterTitle: {
    color: '#ec5169',
    fontSize: 17,
    marginLeft: '20px',
    marginTop: '20px',
  },
  controls: {
    fontSize: '15px',
    color: '#ec5169',
    margin: '0 10px',
  },
  listImage: {
    height: '25px',
    marginLeft: '10px',
    marginBottom: '-8px',
  },
  sublist: {
    display: 'inline-block',
    width: 'auto',
    height: 'auto',
    marginLeft: '12px',
  },
  mainListItem: {
    display: 'flex-start',
    justifyContent: 'space-between',
    height: '40px',
  },
  listItem: {
    ...defaultFont,
    width: 'auto',
    fontWeight: 400,
    marginLeft: '0px',
    marginRight: '20px',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 20,
  },
  listIcon: {
    marginLeft: '0px',
  },
  submitButton: {
    backgroundColor: '#ec5169',
    alignSelf: 'centerd',
    color: '#fff',
    margin: '15px auto',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#ec5169',
      color: '#fff',
    },
  },
  fixedControl: {
    width: '100%',
    background: '#fff',
    borderTop: '4px solid #f4f4f4',
    // height: 'auto',
    height: 67,
    position: 'fixed',
    bottom: 0,
    filter: 'drop-shadow(0px 0px 6px gray)',
    zIndex: 50,
  },
  filterHeaders: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  mobileViewDialogTitle: {
    height: 20,
    backgroundColor: '#F5F5F5',
    paddingTop: 45,
  },
  dialogContent: {
    ...defaultFont,
    backgroundColor: '#F5F5F5',
    height: 'auto',
  },
  textSearch: {
    fontSize: 16,
    padding: '3px',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '100px',
    '& input': {
      color: '#000',
      backgroundColor: '#fff',
      borderRadius: '100px',
      fontSize: 16,
      paddingLeft: 10,
    },
    '& input::placeholder': {
      color: '#000',
    },
    '& fieldset': {
      border: 0,
    },
  },
  inputAdornment: {
    color: '#EC5169',
  },
  searchIcon: {
    zIndex: 200,
    paddingRight: 10,
  },
  form: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    color: '#564D42',
    fontFamily: '"Noto Sans JP", "Helvetica", "Arial", sans-serif',
  },
  showFilter: {
    textAlign: 'center',
    backgroundColor: secondaryColor,
    color: '#FFFFFF',
    borderRadius: 0,
    padding: '25px auto',
  },
  toggleControl: {
    width: 'auto',
    textAlign: 'center',
    display: 'justify-center',
    padding: '10px 0',
  },
  collapseButton: {
    height: 10,
    display: 'inline-block',
  },
  toggleLabel: {
    width: 'auto',
    textAlign: 'left',
    display: 'inline-block',
    fontSize: 14,
  },
  activeCountryFilter: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: secondaryColor,
    borderRadius: 0,
  },
  selections: {
    marginTop: 20,
  },
  countryFilter: {
    width: '100%',
    textAlign: 'center',
    '@media (max-width: 376px)': {
      fontSize: 13,
    },
  },
  activeCountryButton: {
    width: '100%',
    color: '#fff',
    '&:hover': {
      backgroundColor: secondaryColor,
      color: '#fff',
    },
    fontSize: 12,
    '@media (max-width: 376px)': {
      fontSize: 13,
    },
    '@media (max-width: 361px)': {
      fontSize: 12,
    },
  },
  countryButton: {
    width: '100%',
    color: '#564D42',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#564D42',
    },
    fontSize: 12,
    '@media (max-width: 376px)': {
      fontSize: 13,
      '@media (max-width: 361px)': {
        fontSize: 12,
      },
    },
  },
  imageItem: {
    paddingRight: 12,
    paddingBottom: 12,
    position: 'relative',
    boxSizing: 'border-box',
  },
  imagesContainer: {
    width: 'auto',
    paddingTop: 20,
  },
  tileContainer: {
    width: '100%',
  },
  subLabel: {
    fontSize: 14,
  },
  fullContainer: {
    width: 'auto',
  },
  dateRangeInput: {
    backgroundColor: '#fff',
    padding: 6,
    width: '100%',
    boxSizing: 'border-box',
    '@media (max-width: 599px)': {
      width: '100%',
    },
    color: secondaryColor,
  },
  calendarIcon: {
    height: 20,
    marginLeft: 8,
    '&:hover': {
      background: 'transparent !important',
    },
  },
  endLabel: {
    '&:hover': {
      background: 'transparent !important',
    },
  },
  listCheck: {
    marginLeft: 8,
  },
  filterContainer: {
    backgroundColor: 'transparent',
  },
  content: {
    backgroundColor: '#F5F5F5',
  },
  category: {
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
  },
};

export default searchFilterStyle;
