import Loader from 'layouts/Loader';
import React, { Suspense, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from 'utils/Cookie';
import CommonLayout from 'layouts/common/Layout';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function MembershipAuth({ component, ...rest }) {
  const token = getCookie('c_token');
  const location = useLocation();

  if (!token) {
    return (
      <Route
        {...rest}
        render={() => <Redirect to={`/membership/login?redirect_uri=${location.pathname}`} />}
      />
    );
  }

  const Component = lazy(() => import(`../${component}`));

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <CommonLayout>
            <Component {...props} />
          </CommonLayout>
        </Suspense>
      )}
    />
  );
}

export default MembershipAuth;
