import {
  LOAD_PAGES,
  LOAD_PAGE_TYPES,
  LOAD_CITIES,
  LOAD_COUNTRIES,
  LOAD_CATEGORIES,
  LOAD_PAGE_INFORMATION,
  UNLOAD_PAGE_INFORMATION,
  LOAD_TEMPLATES,
  ADD_PAGE,
  UPDATE_PAGE,
  LOAD_AREAS,
  FETCHING_PAGES,
  FAIL_FETCHING_PAGES,
  FETCH_DELETING_PAGES,
  FAIL_DELETING_PAGES,
  DELETING_PAGES,
  LOAD_MAIN_CATEGORIES,
} from './action-types';

const initialState = {
  loading: false,
  deleting: false,
  pages: [],
  pageTypes: [],
  cities: [],
  countries: [],
  categories: [],
  mainCategories: [],
  pageInformation: {},
  templates: [],
  areas: [],
  meta: {},
};

export function pageManagement(state = initialState, { type, payload = null }) {
  switch (type) {
    case FETCH_DELETING_PAGES:
      return {
        ...state,
        deleting: true,
      };

    case DELETING_PAGES:
      return {
        ...state,
        pages: state.pages.filter((page) => page.id !== parseInt(payload)),
        meta: state.meta.total - 1,
        deleting: false,
      };

    case FAIL_DELETING_PAGES:
      return {
        ...state,
        deleting: false,
      };

    case FETCHING_PAGES:
      return {
        ...state,
        loading: true,
      };

    case FAIL_FETCHING_PAGES:
      return {
        ...state,
        loading: false,
      };

    case LOAD_PAGES:
      return storePages(state, payload);
    case LOAD_PAGE_TYPES:
      return storePageTypes(state, payload);
    case LOAD_CITIES:
      return storeCities(state, payload);
    case LOAD_COUNTRIES:
      return storeCountries(state, payload);
    case LOAD_CATEGORIES:
      return storeCategories(state, payload);

    case LOAD_MAIN_CATEGORIES:
      return {
        ...state,
        mainCategories: payload.data,
      };

    case LOAD_PAGE_INFORMATION:
      return loadPageInformation(state, payload);
    case UNLOAD_PAGE_INFORMATION:
      return unloadPageInformation(state);
    case LOAD_TEMPLATES:
      return loadTemplates(state, payload);
    case ADD_PAGE:
      return addPage(state, payload);
    case UPDATE_PAGE:
      return updatePage(state, payload);
    case LOAD_AREAS:
      return loadAreas(state, payload);
    default:
      return state;
  }
}

function storePages(state, payload) {
  return Object.assign({}, state, {
    ...state,
    pages: payload.data,
    meta: payload.meta,
    loading: false,
  });
}

function storePageTypes(state, payload) {
  return Object.assign({}, state, {
    ...state,
    pageTypes: payload,
  });
}

function storeCities(state, payload) {
  return Object.assign({}, state, {
    ...state,
    cities: payload.data,
  });
}

function storeCountries(state, payload) {
  return Object.assign({}, state, {
    ...state,
    countries: payload.data,
  });
}

function storeCategories(state, payload) {
  return Object.assign({}, state, {
    ...state,
    categories: payload.data,
  });
}

function loadPageInformation(state, payload) {
  return Object.assign({}, state, {
    ...state,
    pageInformation: payload.data,
  });
}

function unloadPageInformation(state) {
  return Object.assign({}, state, {
    ...state,
    pageInformation: {},
  });
}

function loadTemplates(state, payload) {
  return Object.assign({}, state, {
    ...state,
    templates: payload,
  });
}

function addPage(state, payload) {
  const data = state.pages;
  const page = data.find((page) => page.id === payload.id);
  if (!page) {
    const pages = [...data, payload];
    return Object.assign({}, state, { pages });
  }

  return updatePage(state, payload);
}

function updatePage(state, payload) {
  const pages = state.pages.map((page) => {
    if (page.id === payload.id) {
      return { ...page, ...payload };
    }
    return page;
  });

  return Object.assign({}, state, { pages });
}

function loadAreas(state, payload) {
  return Object.assign({}, state, {
    ...state,
    areas: payload,
  });
}
