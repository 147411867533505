// react
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Card,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
// icons
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import OpenIcon from '@material-ui/icons/Add';

// lodash
import _ from 'lodash';
// translation
import { useTranslation } from 'react-i18next';
// styles
import styles from '../../src/assets/jss/styles/components/countryFoldComponentStyle';
import AnimateHeight from 'react-animate-height';
import Http from 'utils/Http';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { countryOrder } from 'utils/constants';

const useStyles = makeStyles(styles);

const CountryFoldComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [destination, setDestination] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [showCountry, setShowCountry] = useState(false);
  const [height, setHeight] = useState(0);
  const [searchPath, setSearchPath] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mode = 'banner', category = ['asia', 'oceania'], setOpenSearch } = props;
  const searchMode = mode === 'search';
  const bannerMode = mode === 'banner';
  const resultMode = mode === 'result';
  const getImage = (country) => {
    let area = areas && areas.filter((area) => area.name === country);
    let image = (area && area[0]?.image_url) || '';
    return image;
  };

  const countries = [
    {
      country: `${t('Philippines')}`,
      category: 'asia',
      image: getImage('Philippines'),
      location: 'Philippines',
    },
    { country: `${t('Taiwan')}`, category: 'asia', image: getImage('Taiwan'), location: 'Taiwan' },
    {
      country: `${t('New Zealand')}`,
      category: 'oceania',
      image: getImage('New Zealand'),
      location: 'New Zealand',
    },
    {
      country: `${t('Australia')}`,
      category: 'oceania',
      image: getImage('Australia'),
      location: 'Australia',
    },
    {
      country: `${t('Singapore')}`,
      category: 'asia',
      image: getImage('Singapore'),
      location: 'Singapore',
    },
    // {country: `${t('Cambodia')}`,category: 'asia',image: getImage('Cambodia'), location: 'Cambodia'},
    // {country: `${t('Myanmar')}`,category: 'asia',image: getImage('Myanmar'), location: 'Myanmar'},
    {
      country: `${t('Indonesia')}`,
      category: 'asia',
      image: getImage('Indonesia'),
      location: 'Indonesia',
    },
    {
      country: `${t('Thailand')}`,
      category: 'asia',
      image: getImage('Thailand'),
      location: 'Thailand',
    },
    {
      country: `${t('Vietnam')}`,
      category: 'asia',
      image: getImage('Vietnam'),
      location: 'Vietnam',
    },
    // {country: `${t('Macau')}`,category: 'asia',image: getImage('Macau'), location: 'Macau'},
    {
      country: `${t('Malaysia')}`,
      category: 'asia',
      image: getImage('Malaysia'),
      location: 'Malaysia',
    },
    // {country: `${t('Hong Kong')}`,category: 'asia',image: getImage('Hong Kong'), location: 'Hong Kong'},
  ];

  useEffect(() => {
    if (searchMode) {
      setShowCountry(true);
      setHeight(height === 0 ? 'auto' : 0);
    } else {
      setShowCountry(false);
    }
  }, [searchMode]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);

    countries.forEach((country) => {
      if (country.image !== undefined) {
        _.delay(function () {
          setDestination(countries);
          setLoading(false);
        }, 2000);
      }
    });

    if (_.isEmpty(areas)) {
      Http('/v1/countries')
        .then((res) => res.json())
        .then((res) => {
          setAreas(res.data);
        });
    }
  }, [areas]); // eslint-disable-line

  useEffect(() => {
    if (searchText.length > 0) setSearchPath(`/category?keyword=${searchText}`);
  }, [searchText]);

  const handleFoldCountry = () => {
    setShowCountry(!showCountry);
    setHeight(height === 0 ? 'auto' : 0);
    if (showCountry) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDownSearchCategory = (e) => {
    if (e.key === 'Enter') searchCategory();
  };

  const searchCategory = () => {
    if (searchText.length > 0) {
      localStorage.setItem('/category', '');
      history.push(searchPath);
    }
  };

  const handleLinkClick = () => {
    searchMode && !resultMode && setOpenSearch(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      {bannerMode && (
        <Grid item className={classes.searchContainer}>
          <Box className={classes.searchBox}>
            <TextField
              fullWidth
              variant="outlined"
              value={searchText}
              onChange={handleSearch}
              onKeyDown={handleKeyDownSearchCategory}
              className={classes.searchTextField}
              placeholder="何をお探しですか？"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" aria-label="search" onClick={searchCategory}>
                      <SearchIcon
                        className={classes.searchIcon}
                        fontSize={isMobile ? 'small' : 'large'}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid item className={classes.foldContainer}>
        {!isMobile && <div className={classes.foldOverlay}></div>}
        <Box className={classes.foldContent}>
          {!isMobile && (
            <Typography className={classes.title} align="center">
              旅行先から探す
            </Typography>
          )}

          <AnimateHeight duration={500} height={isMobile && bannerMode ? height : 'auto'}>
            {loading ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.loadingContainer}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'flex-start' : 'center'}
                alignItems="center"
                className={classes.imagesContainer}
              >
                {_.sortBy(destination, (item) => {
                  return countryOrder.indexOf(item.location);
                })
                  .filter((country) => category.includes(country.category))
                  .map((data, key) => {
                    return (
                      <Grid item xs={isMobile ? 6 : null} className={classes.imageItem} key={key}>
                        <Link
                          to={`/country/${_.lowerCase(data?.location)}`}
                          onClick={() => handleLinkClick()}
                        >
                          <Card
                            className={bannerMode ? classes.thumbnail : classes.filterThumbnail}
                          >
                            <img className={classes.image} alt={''} src={data.image} />
                            <div className={classes.imageOverlay}></div>
                            <Typography
                              className={bannerMode ? classes.countryText : classes.bottomLabel}
                            >
                              {' '}
                              {data.country}{' '}
                            </Typography>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </AnimateHeight>

          {bannerMode && isMobile && (
            <Button
              fullWidth
              className={classes.showCountryButton}
              style={{ paddingTop: showCountry ? 6 : 0 }}
              onClick={handleFoldCountry}
            >
              {showCountry ? (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <CloseIcon className={classes.icons} />
                  </Grid>
                  <Grid item>
                    <span>閉じる</span>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '20px', paddingTop: -1, paddingBottom: 3 }}
                >
                  <Grid item>
                    <span>国を選ぶ</span>
                  </Grid>
                  <Grid item>
                    <OpenIcon className={classes.icons} />
                  </Grid>
                </Grid>
              )}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CountryFoldComponent;
