const ja = {
  translation: {
    'About-Us': 'はじめての方へ',
    accept_terms: 'MyBusサイト利用規約およびプライバシーポリシーに同意します',
    access: 'アクセス',
    actual_inquiry: 'お問合せ内容',
    admin_creation_header: '管理者アカウント登録',
    admin_registration: '管理者アカウント登録',
    all: '全て',
    answer: '回答',
    asia: 'アジア',
    cancel: 'キャンセル',
    Cart: 'カート',
    company_profile: '会社プロフィール',
    common_text: 'これは共通のページです',
    consultation_form: 'メール相談フォーム',
    copyright: '著作権 © 2020',
    create: '登録',
    dashboard: 'ダッシュボード',
    dashboard_text: 'こんにちは！ ダッシュボードへようこそ',
    departure_date: '日本ご出発日',
    destination_country_city: '旅行先（国・都市）',
    destinations: '検索条件',
    email_address: '電子メールアドレス',
    email_address_correct: '入力が正しいか確認してください',
    email_text: '私たちはあなたのメールを決して共有しません。',
    english_name: '名前（ローマ字）',
    enter_comment: 'ここにコメントをご記載ください',
    enter_email_address: 'メールアドレスを入力',
    faq_note: '必ずよくある質問を事前に読んでください。',
    fill_up_required_field: '必要な情報を入力してください。',
    find_course: 'コースNo.の調べ方',
    first_name: '名',
    general_inquiry: '一般お問い合わせ',
    home: 'ホーム',
    inquiry: '問い合わせ',
    how_to_use: 'ご利用方法',
    inquiry_category: 'お問い合わせ項目',
    inquiry_submit_success: 'お問い合わせは送信できました。',
    japanese_name: '名前（日本語）',
    last_name: '姓',
    login: 'ログインする',
    logout: 'ログアウト',
    lookup: 'ルック JTB MySTYLEでお申し込みを完了しています',
    oceania: 'オセアニア',
    password: 'パスワード',
    password_text: 'パスワードを共有することは決してありません。',
    privacy_policy: 'プライバシーポリシー',
    proceed_confirm: '送信する',
    rakunavi_support: 'Rakuなびサポート',
    'Send a Reply Email': 'メール送信',
    'Submit Reply Email': 'Submit Reply Email',
    'Already sent reply email(s)': 'Already sent reply email(s)',
    'Reply Date': 'Reply Date',
    'Reply Email Contents': 'Reply Email Contents',
    'Send another.': 'Send another.',
    rights_reserved: '全著作権所有。',
    show_more: 'もっと見る',
    search_condition: '検索条件',
    select_city: '都市を選択',
    select_date: '日付を選択',
    select_nation: '国を選択',
    send_question: '質問送信',
    service_number: '予約受付番号',
    several_cities: '複数都市',
    title: 'マイバスJTB',
    user_management: 'ユーザー管理',
    subscribe: '購読',
    available_today: '即日予約',
    booked: '予約済み',
    sign_up_membership: '会員登録',
    subtotal: '小計',
    point_gained: 'ご予約での獲得ポイント',
    proceed_to_book: '予約へ進む',
    city: '都市',
    country: '国',
    departure_date_time: '催行日時',
    number_of_participants: '予約人数',
    remove_from_cart: 'カートから削除',
    points_per_product: 'ポイント',
    recommendations: 'おすすめクティビティ',
    look_for_other_activities: '他のアクティビティを探す',
    footer_text:
      '海外現地・オプショナルツアーならJTBのMyBus。各国にて海外現地でのオプショナルツアーを豊富に取り揃えています。 日本語対応ができるガイドが同行するツアーを多数ご用意していますので、安心して現地の魅力を楽しむことができます。 海外現地の魅力をオプショナルツアーで是非体験してください！',
    subscribe_newsletter:
      'JTBのニュースレターを購読して、最新のプロモーション、特別割引、役立つ旅行のヒントを入手してください！',
    view_more: 'もっと見る',
    'Traveler Information': '旅行者情報',
    Payment: 'お支払い',
    'Payment Confirmation': 'お支払い確認',
    'Step 1 Enter traveler information': '代表者情報の入力',
    'Update Booking Information': '予約情報の更新',
    'Reservation Required Information': '予約に必要な情報',
    Mr: '氏',
    Name: '名',
    Birthday: '生年月日',
    Gender: '性別',
    'Country Code': '国番号',
    'Phone Number': '携带電話番号',
    'Email Address': 'メールアドレス(入力が正しいか確認してください)',
    'Local Stay': '現地滞在先(場所)',
    'Emergency Contact': '現地滞在中の緊急連絡先',
    Romaji: 'ローマ字',
    'Please Select': '選択してください。',
    'Enter phone number': '電話番号を入力',
    'Enter email address': 'Emailアドレスを入力',
    Other: 'その他',
    'Please enter your contact number': '連絡先番号を入力してください',
    'Please specify': 'ご指定ください',
    'I accept the terms': '私は当サイトの申し込み条件（ご利用規約）に同意します。',
    'Terms of Service': 'ご利用規約',
    'Booking Privacy': '私は当サイトのプライバシーポリシーに同意します。',
    Policy: 'プライバシーポリシー',
    'Next >>': '次へ>>',
    'Flight Number': 'フライト番号',
    'Enter flight number': 'フライト番号を入力',
    'Others Travelers': '参加者情報',
    Height: '身長',
    'Body Weight': '体重',
    'Sizes of shoes': 'クツのサイズ',
    Comment: 'コメント',
    'Enter height': '身長を入力',
    'Enter weight': '体重を入力',
    'Step 2 Confirmation/Payment Information': 'ステップ2 確認・お支払い情報',
    'Booking Details': '予約詳細',
    'Edit Details': '予約情報入力画面に戻る',
    'Tour Name': 'ツアー名',
    'Name of representative': '代表者氏名',
    'Number of People': '人数',
    'Departure Date and Hours': '催行日',
    'Place to stay': '滞在場所',
    'Mail Address': 'メールアドレス',
    'Emergency Contact ': '緊急連絡先',
    'Total Fee': '合計金額',
    Adult: '大人',
    'Card Fee': 'カード手数料',
    Total: '合計',
    'Payment Method': 'お支払い情報の入力',
    'Card Number': 'カード番号',
    'Expiration Date': '有效期限',
    Month: '月',
    Year: '年',
    'Security Code': 'セキュリティーコード',
    Code: 'コード',
    'Card Name': 'カード名義',
    'Cancelation Policy': 'キャンセルポリシー',
    AllCategories: '全てのテーマ・カテゴリ',
    AllCountries: '全ての国・都市',
    MultipleSelectionNote: '※複数選択可',
    CategoryTitle: 'テーマ・カテゴリ',
    CountryCity: `国・都市`,
    ModalSearch: '何をお探しですか？',
    Reserve: '予約の申し込みをする',
    'email is not valid!': 'メールアドレスが無効です！',
    'Email submitted!': 'メールは送信できました！',
    'email is required!': 'メールアドレスは必須です！',
    Transportation: '移動',
    Search: '何をお探しですか?',
    SearchFor: '検索する',
    Tours: 'ツアー',
    'Mybus Search Result': 'Mybus 検索結果',
    'Tours & Activities': 'ツアーとアクティビティ',
    Restaurants: 'レストラン',
    'Ticket & Coupons': 'チケットとクーポン',
    View: 'みる',
    Eat: 'たべる',
    Buy: 'かう',
    'Play Around': 'あそぶ',
    Transfer: 'のる',
    Singapore: 'シンガポール',
    Taiwan: '台湾',
    Thailand: 'タイ',
    Indonesia: 'インドネシア',
    Australia: 'オーストラリア',
    'New Zealand': 'ニュージーランド',
    Malaysia: 'マレーシア',
    Vietnam: 'ベトナム',
    Cambodia: 'カンボジア',
    Philippines: 'フィリピン',
    Myanmar: 'ミャンマー',
    India: 'インド',
    'Hong Kong': '香港',
    Macau: 'マカオ',
    'Reservation of local optional tour': '地域のオプショナルツアーの予約',
    'Company Profile': '会社プロフィール',
    'Tour Application Conditions': 'ツアー申込条件',
    'Membership Terms': 'マイバスサイト利用規約',
    'Travel Application Terms and Conditions': '旅行申込条件',
    'My Bus Site Terms of Use': 'マイバスサイト利用規約',
    'Privacy Policy': 'プライバシーポリシー',
    no_data_found: '表示するデータがありません',
    'Coupon Discount': 'クーポン割引',
    'Name (Kanji)': '名前（日本語）',
    'First Name (Kanji)': '名（日本語）',
    'Last Name (Kanji)': '姓（日本語）',
    'Name (Romaji)': '名前（ローマ字）',
    'First Name (Romaji)': '名（ローマ字）',
    'Last Name (Romaji)': '姓（ローマ字）',
    'Please input the correct format': '(入力が正しいか確認してください)',
    'Country and City': '国または都市',
    'Departure Date': '日本ご出発日',
    'Inquiry Message': 'お問合せ内容',
    Inquire: 'お問い合わせ送信',
    'Send Inquiry': 'お問い合わせ送信',
    'To inquiry form': 'お問い合わせフォームへ',
    'Booking Reference': '受付ID',
    'Rakunavi Support': 'Rakuなびサポート',
    'Booking request is accepted': 'リクエスト受付が完了しました',
    'We will contact you at the registered email address':
      'ご登録メールアドレスへご連絡いたします。',
    'We will check the availability and reply to you within xx business days':
      '空き状況を確認の上、3日以内にご回答いたします。',
    'Please note that the reservation has not been finalize at this time':
      '※現時点ではご予約は確定しておりませんのでご注意下さい。',
    'Booking number': '予約番号',
    'The reservation could not be completed': 'ご予約が完了できませんでした',
    'Excuse me, but please make a reservation again from the beginning':
      '恐れ入りますが、最初から予約をやり直してください。',
    'Recommended Souvenirs': 'おすすめ商品',
    'Return to Top Page': '国TOPページへ戻る',
    'Go back to previous page': '前のページに戻る',
    'Your reservation is complete': 'ご予約が完了しました',
    'Thank you for your application': 'ご申し込みいただきありがとうございました。',
    'A reservation confirmation email has been sent to your registered email address':
      'ご登録メールアドレスへ予約確認メールが送信されました。',
    'A confirmation email will be sent once the reservation details is confirmed':
      '予約詳細が確定次第、確定メールをお送りします。',
    'Confirm reservation details': '予約内容を確認する',
    'Check Booking Details': '予約内容を確認する',
    'Reservation number': '受付ID',
    'Booking is completed': 'ご予約が完了しました',
    'Thank you for booking with us': 'ご申し込みいただきありがとうございました。',
    'Please make sure to check the confirmation email sent to you':
      'ご登録メールアドレスへ予約確認メールが送信されました。',
    Category: 'カテゴリ',
    'Search where do you want to go': 'カテゴリー、テーマ検索',
    'Keyword Search': 'キーワード検索',
    Top: 'トップ',
    Gifts: 'ギフト',
    'Local Reports': '現地レポート',
    Restaurant: 'レストラン',
    'Online Catalog': 'オンラインカタログ',
    'Rurubu Free': 'ルルブフリー',
    'Hotel Name': 'ホテル/滞在先名',
    'Hotel Address': 'ホテル/滞在先住所',
    'Hotel Reservation Last Name': 'ホテル予約の姓',
    'Hotel Reservation First Name': 'ホテル予約名',
    'Hotel Tel': 'ホテルテル',
    'See other destination': '他の目的地を探す',
    'Other Destination': '他の国を見る',
    'All activities': 'すべてのアクティビティ',
    'All Destinations': 'すべての目的地',
    Categories: 'カテゴリー',
    Cities: 'エリア',
    Countries: '国',
    Entertainment: 'エンターテイメント',
    'Full day tour': '終日ツアー',
    'Half day tour': '半日ツアー',
    'Items Found': '個のアクティビティが見つかりました',
    Price: '価格',
    'See More': '続きを見る',
    Ticket: 'チケット',
    Full: '終日ツアー',
    Theme: 'テーマ',
    'No Activities': 'アクティビティなし',
    'Most Popular': '最も人気のある',
    'Price Ascending': '価格の低い順',
    'Price Descending': '価格の高い順',
    Themes: 'テーマ',
    Auckland: 'オークランド',
    Aurangabad: 'オーランガバード',
    Bali: 'バリ島',
    Bangkok: 'バンコク',
    Bohol: 'ボホール',
    Cairns: 'ケアンズ',
    Cebu: 'セブ',
    'Chiang Mai': 'チェンマイ',
    Christchurch: 'クライストチャーチ',
    'Delhi/New Delhi': 'デリー/ニューデリー',
    'Gold Coast': 'ゴールドコースト',
    Hanoi: 'ハノイ',
    'Ho Chi Minh': 'ホーチミン',
    Hualien: '花蓮',
    Jakarta: 'ジャカルタ',
    'Kuala Lumpur': 'クアラルンプール',
    Langkawi: 'ランカウイ',
    Manila: 'マニラ',
    Melbourne: 'メルボルン',
    Mumbai: 'ムンバイ',
    Penang: 'ペナン',
    'Kota Kinabalu': 'コタキナバル',
    Phuket: 'プーケット',
    Rotorua: 'ロトルア',
    'Siem Reap': 'シェムリアップ',
    Sydney: 'シドニー',
    Taipei: '台北',
    Takao: '高雄',
    Yangon: 'ヤンゴン',
    'View More': '詳細を見る',
    News: 'ニュース',
    'No Data Found': '表示するデータがありません',
    OCEANIA: 'オセアニア',
    ASIA: 'アジア',
    'ALL DESTINATIONS': 'すべての目的地',
    'Tour Not Found': 'ツアーが見つかりません',
    'Local Time': '現地時間',
    'No Weather Data': '気象データなし',
    'Requesting Reservation': 'ご予約リクエスト送信中',
    'Confirming Payment': 'お支払い情報確認中',
    'Finalizing Payment': '決済手続き中',
    'Finalizing Booking': 'ご予約リクエスト確定中',
    'Search by region/city': '地域・都市から探す',
    'Confirmation Code was sent via mail': '確認コードはメールで送信されます。',
    'The entered confirmation code is incorrect': '入力された確認コードに誤りがある',
    'The password and the confirmation password do not match please confirm':
      'パスワードと確認用パスワードが一致しません。確認してください。',
    'Cannot Login Please check your username or password':
      'ログインできませんでした。ユーザー名とパスワードをご確認の上、再度お試しください。',
    'Booking Detail': 'ご予約内容',
    'Booking information not found': '予約情報が見つかりませんでした。',
    'Booking-Information': '予約情報',
    CONFIRMED: '予約確定',
    'Tour Date': '参加日',
    'Issue Voucher': 'バウチャー(確認証)',
    'Issue Receipt': '領収書',
    'Cancel Booking': '予約キャンセル',
    Inquiry: '予約に関する問合せ（取消・変更など）',
    'Total Amount': '合計金額',
    'Enter Booking ID and Email to view Booking Details':
      '受付ID と登録メールアドレスを入力ください',
    'Booking ID': '受付ID',
    Email: '登録メールアドレス',
    'Search Reservation': '予約検索',
    'Back to Booking Information': 'ご予約内容に戻る',
    'Update Booking Details': '更新する',
    'Select Time': '時間を選択',
    'Select Transportation': '場所を選択',
    PAID: '支払い済み',
    coupon_management: 'クーポン管理',
    page_management: 'ページ管理',
    reservation_management: '予約管理',
    inquiry_management: 'お問い合わせ管理',
    logs: 'ログ',
    email_subscription: 'メール配信',
    image_management: '画像管理',
    notification_management: '通知管理',
    change_password: 'パスワード変更',
    Submit: '送信',
    'The email entered does not match the email in the booking':
      '入力されたメールアドレスは予約のメールアドレスと一致しません。',
    top_page: 'トップページへ',
    'Fill Up Required Fields': '必須フィールドに入力します。',
    'Inquiry Submitted Successfully!': 'お問い合わせが正常に送信されました!',
    'Unable To Create Inquiry!': 'お問い合わせの作成ができません。！',
    'Select Date': '日付を選択',
    REQUESTED: 'リクエスト中',
    requesting_booking_message: '只今予約をリクエスト中です。回答まで今しばらくお待ちください。',
    unpaid_booking: '支払い未',
    STANDBY: 'リクエスト中',
    DECLINED: '受付不可',
    WITHDRAWN_BY_TRAVELER: 'キャンセル済み',
    CANCELED_BY_TRAVELER: 'キャンセル済み',
    CANCELLED_BY_TRAVELER: 'キャンセル済',
    CANCELED_BY_SUPPLIER: 'キャンセル済み',
    CHANGED_BY_SUPPLIER: 'キャンセル済み',
    CANCEL_AWAITING_CS_CONFIRMATION: 'キャンセル依頼中',
    'Inquiry Type': '照会型',
    'Inquiry Date': '問い合わせ日',
    MAP_OF_LOCATION: '集合場所に関する地図',
    GUIDE_AFTER_BOOKING: '詳細は予約後にご案内いたします',
    NOTE_OF_LOCATION_AND_DIRECTION: '場所、行き方の補足説明',
    LANDMARKS_TO_LOCATION: '集合場所までのランドマーク',
    PICK_UP_CAR: '迎えに行く車',
    PICK_UP_GUIDE: '迎えに行くガイド',
    ITINERARY: '旅程',
    PARKING: '駐車場について',
    'Book Date': '予約日',
    'Tour Details': 'ツアーの詳細',
    'Reservation Details': '予約の詳細',
    Close: '閉じる',
    WITHDRAWN: '引きこもった',
    CANCELLED: 'キャンセル',
    CHANGED: 'かわった',
    'No browsing history available': '利用可能な閲覧履歴はありません',
    'Exchange Rate Management': '為替レート管理',
    'Your recent browsing history': '最近見たあなたの閲覧履歴',
    'Recommended Tour': 'おすすめツアー',
    'See All': '全てを見る',
    categories_management: 'カテゴリ管理',
    'Specify Quantity': '数量を指定する',
    'For this resevation, Cancellation fee will be': 'この予約の場合、キャンセル料は',
    'Refund will be': '払い戻しは',
    'Do you want to cancel booking?': '予約をキャンセルしますか？',
    'No cancel fee for this booking': 'この予約のキャンセル料はありません',
    Yes: 'はい',
    No: 'いいえ',
    'Cancel booking': '予約をキャンセルする',
    'Do you want to proceed capture payment?': '支払いを実行しますか？',
    'Need capture payment': 'キャプチャ支払いが必要',
    Done: '完了',
    'Payment Status': '支払い状況',
    'Capture Payment': '支払の実行',
    'Capture Payment Modal': '支払処理を強制執行します。',
    'Cancel booking Modal': '予約キャンセルの確認',
    'Success Payment Capture': '成功した支払いキャプチャ',
    'Booking cancellation success': '予約キャンセル成功!',
    'Error when processing refund. Please Try again later':
      '払い戻しの処理中にエラーが発生しました。 後でもう一度やり直してください。',
    email_sent_successfully: '電子メールが送信されました ',
    email_sent_error: 'メール送信エラー',
    send_email: 'メールを送る',
    booking_date_filter: '予約日フィルター ',
    service_date_filter: 'サービス日付フィルター',
    disable_filter: 'フィルタを無効にする',
    customer_name: '顧客名',
    tour_title: 'ツアー名',
    booking_date: '予約日',
    service_date: '参加日',
    plan_title: 'プラン名',
    number_of_attendee: '参加人数',
    pickup_time: '参加時間',
    pickup_location: '集合場所',
    contact_number: '携帯電話番号',
    emergency_number: '緊急連絡先',
    checkin_location_title: '現地滞在場所',
    extra_booking_information: '予約必須情報',
    paid_amount: '購入金額',
    mail_sent: '送信日時',
    jtb_internal_use: '社内メモ',
    booking_information_comment_success: '予約情報が正常に更新されました',
    booking_information_comment_fail: '予約情報の更新に失敗する',
    no_such_booking: '予約はありません。 別のものを試してください',
    no_veltra_booking: 'ベルトラには予約がありません',
    'Booking is on process': '予約は進行中です',
    'Booking Cancellation Is On Progress': '予約のキャンセルが進行中です',
    edit_email: 'メールを編集する',
    email_send_error: 'メール送信エラー',
    'Destination Phone Number': '宛先の電話番号',
    'Hotel Phone Number': 'ホテルの電話番号',
    'Arrival Date': '到着日',
    'Hotel Latitude': 'ホテルラティチュード',
    'Hotel Longitude': 'ホテル経度',
    'Add-ons': 'オプションを選択する',
    'Return to search results': '検索結果を見る',
    CUSTOMER_HOTEL: 'お客様ホテル',
    'Booking is not yet confirmed on Veltra': 'Veltraでの予約はまだ確定していません。',
    'Rotate Image': '画像の回転',
    'Coupon Code': 'クーポンコード',
    'Coupon Name': 'クーポン名',
    'Activity ID': '対象商品',
    Country: '国別',
    City: '都市別',
    coupon_category: 'カテゴリー',
    'Purchase Price Conditions': '購入金額条件',
    'Total Number Of Usage': '総利用回数制限',
    'Discount Percentage': '割引率',
    'Discount Value': '割引額',
    'Discount Percentage/Value': '割引率/割引額',
    Currency: '通貨',
    'Validity Start Date': '有効期限（適用開始日）',
    'Validity End Date': '有効期限（適用終了日）',
    Status: 'オン／オフ',
    'Combination With Other Coupons': 'クーポン併用',
    'Combination With Other Coupons Code': 'クーポン併用対象商品',
    'Issue Date': '発行日',
    'Number Of Remaining': '残数',
    Remarks: '備考',
    'Except Day': '適用除外日',
    'Cancel Reservation': '予約の取消し',
    Inquiries_changes_etc: 'お問い合わせ（変更など）',
    'Automatic Cancellation Processing for VELTRA with API':
      'キャンセルのご依頼を承りました。別途、キャンセル手続完了メールをお送りします。',
    'Cancellation Process on VELTRA by JTB branch staff':
      'キャンセルのご依頼を承りました。改めて弊社よりご連絡させていただきます。',
    'Confirmed Cancelling a Reservation': '確定 予約のキャンセル',
    'Cancelling Reservation': '予約のキャンセル',
    'Cancel Booking Status': '予約状況のキャンセル',
    Coupon: 'クーポン',
    None: '設定しない',
    'Specify (Limited)': '指定（限定)',
    'Exclude Activity ID': '除外商品',
    'Coupon discount is required': 'クーポンによる割引が必要',
    'Validating Coupons': 'クーポンの有効化',
    OK: '閉じる',
    'Total Number Usage': '総利用回数',
    'Search Reservation By Coupon': 'クーポン利用予約の検索',
    'Cancellation Fee': '取消料',
    'Issue Date From': '発行日',
    'Issue Date To': '発行日から',
    'Validity Date From': 'Validity Date From',
    'Validity Date To': 'Validity Date To',
    'Validity Date': '有効期間フィルター',
    'Issue Date Range': '発行日フィルター',
    'Login Verification': 'ログイン確認',
    Resend: '再送する',
    Confirm: '認証する',
    'Verification code successfully sent': '認証コードの送信に成功',
    'Resending verification code failed': '認証コードの再送信に失敗しました',
    'Enter Verification Code': '認証コードを入力する',
    'Verification Code': '検証コード',
    'Password Policy Error':
      'パスワードが正しくセットされていません。（半角英大文字、小文字、数字、特殊記号を含めて8文字以上で設定してください）',
    'Attempt limit exceeded, please try after some time':
      '試行回数の制限を超えました。しばらくしてから試してください。',
    'Password must be greater than 5 in length': 'パスワードの長さが5以上であること',
    'Mail Magazine Submitted Successfully':
      'ご登録メールアドレス宛にメールをお送りしましたのでご確認ください。',
    Register: '登録',
    'Email address': 'メールアドレス',
    Password: 'パスワード',
    'New Password': '新しいパスワード',
    'First Name': '名',
    'Last Name': '姓',
    Nickname: 'ニックネーム',
    Birthdate: '生年月日',
    'Phone number': '携帯電話番号',
    'Enter the registration token': '認証コードを入力します。',
    'You have successfully registered': '登録に成功しました。',
    Login: 'ログイン',
    'Forget Password': 'パスワードをお忘れの方',
    'Keep me login': 'ログインを維持する',
    'Social Media Login': 'ソーシャルメディアログイン',
    'You are not yet register, would you like to register':
      'まだ登録されていません。登録しますか？',
    'Reset Password': 'パスワードの再設定',
    'Manage account': '会員情報の確認・変更',
    'Booking History': '予約履歴',
    'Browser History': '閲覧履歴',
    Reviews: 'レビュー投稿',
    'Sign Out': 'ログアウト',
    Withdrawn: '退会手続き',
    Withdrawal: '退会手続き',
    Withdraw: '退会する',
    Update: '更新情報',
    'Personal Information': '個人情報',
    'Please enter your password for confirmation': '確認のため、パスワードを入力してください',
    'Confirm Reset Password': 'リセットパスワードの確認',
    'Submit Review': 'レビューを投稿する',
    file_management: 'ファイルの管理',
    PENDING: '予約手配中',
    BOOK_CONFIRMED: '予約確定済',
    FULFILLED: '終了済',
    BOOK_CANCELLED: 'キャンセル済',
    edit_user_information: 'ユーザー情報の編集',
    'Hotel List Management': 'ホテルのリスト管理',
    Maintenance: '整備',
    instagram_management: 'インスタグラムの管理',
    popular_product: '人気商品',
  },
};

export default ja;
