import { AUTH_LOGIN, AUTH_LOGOUT, RESET_CODE, SET_USER, UPDATE_TOKEN } from './action-types';

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload: payload,
  };
}

export function authLogout() {
  window.location.href = '/login';
  return { type: AUTH_LOGOUT };
}

export function resetCode() {
  return { type: RESET_CODE };
}

export function updateToken(payload) {
  return {
    type: UPDATE_TOKEN,
    payload: payload,
  };
}

export function setUser(payload) {
  return {
    type: SET_USER,
    payload: payload,
  };
}
