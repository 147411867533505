export const SELECTED_CURRENCY = 'SELECTED_CURRENCY';

export const FETCH_MODIFIED_CURRENCIES = 'FETCH_MODIFIED_CURRENCIES';
export const SUCCESS_FETCH_MODIFIED_CURRENCIES = 'SUCCESS_FETCH_MODIFIED_CURRENCIES';
export const FAIL_FETCH_MODIFIED_CURRENCIES = 'FAIL_FETCH_MODIFIED_CURRENCIES';
export const CHANGE_CURRENCY_FILTER = 'CHANGE_CURRENCY_FILTER';

export const SET_PAYMENT_CURRENCY = 'SET_PAYMENT_CURRENCY';

export const FETCHING_AREAS = 'FETCHING_AREAS';
export const FETCH_AREAS = 'FETCH_AREAS';
export const FAIL_FETCHING_AREAS = 'FAIL_FETCHING_AREAS';

export const FETCHING_API = 'FETCHING_API';
