import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MybusLogo from '../assets/img/mybus_new_logo.png';

const useStyles = makeStyles({
  content: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 250,
  },
  imageBox: {
    textAlign: 'center',
  },
});
function Maintenance() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div>
        <div className={classes.imageBox}>
          <img alt="mybus-maintenance-logo" src={MybusLogo} className={classes.image} />
        </div>
        <Typography color="error" align="center" variant="h6">
          Website is under maintenance
        </Typography>
        <Typography color="error" align="center">
          ウェブサイトは現在メンテナンス中です。
        </Typography>
      </div>
    </div>
  );
}

export default Maintenance;
