import qs from 'querystring';

export const searchParams = () => {
  const queryParams = qs.parse(window.location.search || '');

  const params = {};

  for (const key in queryParams) {
    params[key.replace('?', '')] = queryParams[key];
  }

  return params;
};
