import {
    FETCH_GET_ACTIVITY_DETAILS,
    SUCCESS_GET_ACTIVITY_DETAILS,
    FAIL_GET_ACTIVITY_DETAILS,
} from './action-types';

const initialState = {
    activity: {},
    loading: false,
    error: '',
};

export function activityDetails(state = initialState, { type, payload = null }) {
    switch (type) {
    case FETCH_GET_ACTIVITY_DETAILS:
        return {
            ...initialState,
            loading: true,
        };
    case SUCCESS_GET_ACTIVITY_DETAILS:
        return {
            ...initialState,
            activity: payload,
            loading: false,
        };
    case FAIL_GET_ACTIVITY_DETAILS:
        return {
            ...initialState,
            error: payload,
            loading: false,
        };
    default:
        return state;
    }
}
