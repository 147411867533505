import * as types from "./actions";

export const showAlertNotifications = (message, type = "success") => {
  return {
    type: types.SHOW_ALERT_NOTIFICATIONS,
    payload: {
      message,
      type,
    },
  };
};

export const hideAlertNotifications = () => {
  return {
    type: types.HIDE_ALERT_NOTIFICATIONS,
  };
};
