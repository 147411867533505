// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// styles
import styles from '../../../assets/jss/styles/layouts/admin/footerStyle.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const Footer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <Typography variant="subtitle2">
          {t('copyright')} &nbsp;
          <Link to="/dashboard">{t('title')}</Link>
          &nbsp; {t('rights_reserved')}
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
