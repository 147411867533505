const layoutStyle = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    zIndex: '-2',
    position: 'absolute',
    width: '100vw',
    overflowX: 'hidden',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  outerContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
  },
  innerContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: '64px',
  },
});

export default layoutStyle;
