export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FAIL_FETCH_CATEGORIES = 'FAIL_FETCH_CATEGORIES';

export const SEARCHING_CATEGORIES = 'SEARCHING_CATEGORIES';
export const SEARCH_CATEGORIES = 'SEARCH_CATEGORIES';
export const FAIL_SEARCH_CATEGORIES = 'FAIL_SEARCH_CATEGORIES';

export const REQUEST_UPDATE_CATEGORIES = 'REQUEST_UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const FAIL_UPDATE_CATEGORIES = 'FAIL_UPDATE_CATEGORIES';

export const REQUEST_CREATE_CATEGORIES = 'REQUEST_CREATE_CATEGORIES';
export const CREATE_CATEGORIES = 'CREATE_CATEGORIES';
export const FAIL_CREATE_CATEGORIES = 'FAIL_CREATE_CATEGORIES';

export const REQUEST_DELETE_CATEGORIES = 'REQUEST_DELETE_CATEGORIES';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const FAIL_DELETE_CATEGORIES = 'FAIL_DELETE_CATEGORIES';

export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION';
export const HANDLE_CLOSE_NOTIFICATION = 'HANDLE_CLOSE_NOTIFICATION';
