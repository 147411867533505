import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/styles/layouts/admin/adminStyle.js';
// components
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import AlertNotifications from '../alert-notifications/components/AlertNotifications.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Http from 'utils/Http.js';
import { setUser } from 'modules/auth/store/actions.js';
import { isPermitted } from 'utils/permissionHelpers.js';
import Forbidden from 'route/Forbidden.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';
import { getCookieFromBrowser } from 'utils/Cookie.js';

const useStyles = makeStyles(styles);
const AdminLayout = ({ children, match }) => {
  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const user = useSelector((state) => state.authentication.user);

  const isNotForbidden = () => {
    const permittedPath = ['/dashboard', '/change-password'];

    if (permittedPath.filter((path) => location.pathname.includes(path)).length > 0) {
      return true;
    }

    return isPermitted(user, match.path);
  };

  useEffect(() => {
    if (!user.id) {
      const email = getCookieFromBrowser('email');
      Http('/v1/get-user', { params: { email } })
        .then((res) => res.json())
        .then((res) => {
          dispatch(setUser(res.data || {}));
        });
    }
  }, [user.id]); // eslint-disable-line

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={classes.root}>
      <AlertNotifications />
      <Header handleDrawerToggle={handleDrawerToggle} open={sidebarOpen} />
      <Sidebar handleDrawerToggle={handleDrawerToggle} open={sidebarOpen} />
      <main className={classes.outerContent}>
        <div className={classes.innerContent}>{isNotForbidden() ? children : <Forbidden />}</div>
        <Footer />
      </main>
    </div>
  );
};
export default AdminLayout;
