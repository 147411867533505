import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/styles/layouts/common/commonStyle.js';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// components
import Header from './components/Header';
import Footer from './components/Footer';
import backtoTop from '../../assets/img/back_to_top.svg';

// For Back to Top function
const ScrollTop = (props) => {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleBackToTop = (e) => {
    const anchor = (e.target.ownerDocument || document).querySelector('#backToTop');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleBackToTop} role="presentation" className={classes.backtoTop}>
        {children}
      </div>
    </Zoom>
  );
};

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStyles = makeStyles(styles);
const CommonLayout = ({ children }) => {
  const classes = useStyles();
  // Main template
  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.mainContent}>
        <span id="backToTop" />
        <Grid container justifyContent="center">
          {children}
        </Grid>
        <Footer />
      </main>
      <ScrollTop>
        <img alt="mybus-back-to-top" src={backtoTop} className={classes.backtoTopImg} />
      </ScrollTop>
    </div>
  );
};

export default CommonLayout;
