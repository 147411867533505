import * as types from "./actions";

const initialValue = {
  show: false,
  type: "error",
  message: "",
};

const alertNotificationReducer = (state = initialValue, { type, payload }) => {
  switch (type) {
    case types.SHOW_ALERT_NOTIFICATIONS:
      return {
        ...state,
        ...payload,
        show: true,
      };

    case types.HIDE_ALERT_NOTIFICATIONS:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
};

export default alertNotificationReducer;
