// @material-ui/core components
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
// translation
import { useTranslation } from 'react-i18next';

const Form = (props) => {
  const { isLoading, formValues, handleInputChange, handleSubmit } = props;

  const { t } = useTranslation();

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormControl fullWidth={true} margin="dense" required={true}>
        <InputLabel htmlFor="confiramation-code">{t('Verification Code')}</InputLabel>
        <Input
          id="confiramation-code"
          aria-describedby="confiramation-code-helper"
          type="number"
          name="confirmationCode"
          value={formValues.confirmationCode}
          onChange={handleInputChange}
        />
        <FormHelperText id="confiramation-code-helper"></FormHelperText>
      </FormControl>
      <FormControl fullWidth={true} margin="dense" required={true}>
        <InputLabel htmlFor="email">{t('email_address')}</InputLabel>
        <Input
          id="email"
          aria-describedby="email-helper"
          type="email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
        />
        <FormHelperText id="email-helper"></FormHelperText>
      </FormControl>
      <FormControl fullWidth={true} margin="dense" required={true}>
        <InputLabel htmlFor="password">{t('password')}</InputLabel>
        <Input
          id="password"
          aria-describedby="password-helper"
          type="password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
        />
        <FormHelperText id="password-helper"></FormHelperText>
      </FormControl>
      <FormControl fullWidth={true} margin="dense" required={true}>
        <InputLabel htmlFor="confirm-password">{t('Confirm password')}</InputLabel>
        <Input
          id="confirm-password"
          aria-describedby="confirm-password-helper"
          type="password"
          name="confirmPassword"
          value={formValues.confirmPassword}
          onChange={handleInputChange}
        />
        <FormHelperText id="confirm-password-helper"></FormHelperText>
      </FormControl>
      <Button variant="contained" color="primary" fullWidth={true} type="submit">
        {isLoading ? <CircularProgress color="secondary" /> : t('Reset Password')}
      </Button>
    </form>
  );
};

export default Form;
