import {
    LOAD_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    UNREAD_NOTIFICATIONS,
    READ_NOTIFICATIONS_BY_VIEW_ID,
} from './action-types';


export function loadNotifications(payload) {
    return {
        type: LOAD_NOTIFICATIONS,
        payload,
    };
}

export function loadUnreadNotifications(payload) {
    return {
        type: UNREAD_NOTIFICATIONS,
        payload,
    };
}

export function readNotifications(payload) {
    return {
        type: READ_NOTIFICATIONS,
        payload,
    };
}

export function readNotificationsByViewId(payload) {
    return {
        type: READ_NOTIFICATIONS_BY_VIEW_ID,
        payload,
    };
}