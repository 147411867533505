export const LOAD_PAGES = 'LOAD_PAGES';
export const LOAD_PAGE_TYPES = 'LOAD_PAGE_TYPES';
export const LOAD_CITIES = 'LOAD_CITIES';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_PAGE_INFORMATION = 'LOAD_PAGE_INFORMATION';
export const UNLOAD_PAGE_INFORMATION = 'UNLOAD_PAGE_INFORMATION';
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const ADD_PAGE = 'ADD_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const LOAD_AREAS = 'LOAD_AREAS';
export const LOAD_MAIN_CATEGORIES = 'LOAD_MAIN_CATEGORIES';

export const FAIL_FETCHING_PAGES = 'FAIL_FETCHING_PAGES';
export const FETCHING_PAGES = 'FETCHING_PAGES';

export const FAIL_DELETING_PAGES = 'FAIL_DELETING_PAGES';
export const DELETING_PAGES = 'DELETING_PAGES';
export const FETCH_DELETING_PAGES = 'FETCH_DELETING_PAGES';
