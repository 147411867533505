import * as types from './action-types';

export function setCurrency(data) {
  return {
    type: types.SELECTED_CURRENCY,
    payload: data,
  };
}

export function setPaymentCurrency(data) {
  return {
    type: types.SET_PAYMENT_CURRENCY,
    payload: data,
  };
}

export function fetchModifiedCurrencies() {
  return {
    type: types.FETCH_MODIFIED_CURRENCIES,
  };
}

export function successFetchModifiedCurrencies(data) {
  return {
    type: types.SUCCESS_FETCH_MODIFIED_CURRENCIES,
    payload: data,
  };
}

export function failFetchModifiedCurrencies(error) {
  return {
    type: types.FAIL_FETCH_MODIFIED_CURRENCIES,
    payload: error,
  };
}

export function changeCurrencyFilter(filter) {
  return {
    type: types.CHANGE_CURRENCY_FILTER,
    payload: filter,
  };
}

// areas data

export function fetchingAreas() {
  return {
    type: types.FETCHING_AREAS,
  };
}

export function fetchAreas(data) {
  return {
    type: types.FETCH_AREAS,
    payload: data,
  };
}

export function failFetchingAreas(error) {
  return {
    type: types.FAIL_FETCHING_AREAS,
    payload: error,
  };
}

export function fetchingAPI(status) {
  return {
    type: types.FETCHING_API,
    payload: status,
  };
}
