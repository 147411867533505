import * as types from "./actionTypes";

export const showNotifications = (message, type = "success") => {
  return {
    type: types.SHOW_NOTIFICATIONS,
    payload: {
      message,
      type,
    },
  };
};

export const hideNotifications = () => {
  return {
    type: types.HIDE_NOTIFICATIONS,
  };
};