import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from '@material-ui/core';
import TextField from '../../../../../components/common/TextField';
import Revalidate from 'ree-validate';
import { useTranslation } from 'react-i18next';

const domain = process.env.REACT_APP_DOMAIN_API;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validator = new Revalidate({
  code: 'required',
});

let timer;

function VerificationForm({ open, loading, onClose, handleConfirm, handleShowError, values }) {
  const { t } = useTranslation();

  const [counter, setCounter] = useState(30);
  const [sending, setSending] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      code: '',
    },
    errors: validator.errors,
  });

  useEffect(() => {
    if (open) {
      timer = setInterval(() => {
        setCounter((state) => (state === 0 ? 0 : state - 1));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [open]);

  useEffect(() => {
    if (counter === 0) {
      clearInterval(timer);
    }
  }, [counter]);

  const startTimer = () => {
    timer = setInterval(() => {
      setCounter((state) => (state === 0 ? 30 : state - 1));
    }, 1000);
  };

  const handleChange = (value) => {
    setFormState((prev) => ({
      ...prev,
      values: {
        code: value,
      },
    }));

    const { errors } = validator;

    errors.remove('code');

    validator.validate('code', value).then(() => {
      setFormState((prev) => ({
        ...prev,
        errors: errors,
      }));
    });
  };

  const handleValidate = () => {
    validator.validateAll(formState.values).then((success) => {
      if (success) {
        handleConfirm(formState.values.code);
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleValidate();
    }
  };

  const handleResend = () => {
    setSending(true);

    fetch(`${domain}/v1/auth-code/resend`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email: values.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          handleShowError(t(res.message), 'success');
          startTimer();
        }
        if (res.code >= 400) {
          handleShowError(t(res.message));
        }
        setSending(false);
      })
      .catch((err) => {
        handleShowError(err.error);
        setSending(false);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      <DialogTitle id="alert-dialog-slide-title">{t('Login Verification')}</DialogTitle>
      <DialogContent style={{ overflowY: 'clip' }}>
        <TextField
          label={t('Enter Verification Code')}
          value={formState.values.code}
          name="code"
          onChange={(e) => handleChange(e.target.value)}
          error={formState.errors}
          inputProps={{ maxLength: 6 }}
          onKeyPress={handleKeyPress}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={handleValidate}
          disabled={loading || sending}
          color="primary"
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="contained"
          onClick={handleResend}
          disabled={loading || counter !== 0 || sending}
          color="primary"
        >
          {t('Resend')} {counter !== 0 ? `(${counter})` : ''}
        </Button>
      </DialogActions>
      {(loading || sending) && (
        <div style={{ flexGrow: 1 }}>
          <LinearProgress />
        </div>
      )}
    </Dialog>
  );
}

VerificationForm.propTypes = {
  values: PropTypes.object,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  handleShowError: PropTypes.func,
};

export default VerificationForm;
