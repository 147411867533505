import React from 'react';

// @material-ui/core components
import MatGrid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// components
import Form from './components/Form';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import PeriodicChangePasswordModal from './components/PeriodicChangePasswordModal';
import NotificationAlert from '../../../../components/common/NotificationAlert';
// translation
import { useTranslation } from 'react-i18next';
// lodash
import VerificationForm from './components/VerificationForm';
import { useDispatch } from 'react-redux';
import * as authActions from '../../store/actions';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthService from '../../service';
import { getCookie, setCookie } from 'utils/Cookie';
import { Redirect } from 'react-router-dom';

const domain = process.env.REACT_APP_DOMAIN_API;
const service = new AuthService(domain);

const Login = (props) => {
  const dispatch = useDispatch();
  const { login, forgotPassword, periodicChangePassword } = props;
  const router = useHistory();
  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState({
    email: '',
    password: '',
  });
  const [isPassExpired, setIsPassExpired] = React.useState(false);
  const [notificationAlertProps, setNotificationAlertProps] = React.useState({
    message: '',
    type: '',
    open: false,
  });
  const [validating, setValidating] = React.useState(false);
  const [loginResponse, setLoginResponse] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [showVerificationModal, setShowVerificationModal] = React.useState(false);

  const token = getCookie('token');

  if (token) {
    return <Redirect to="/dashboard" />;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const credentials = {
      email: formValues.email,
      password: formValues.password,
    };

    login(credentials)
      .then((data) => {
        setShowVerificationModal(true);
        setLoginResponse(data);
        setIsLoading(false);
      })
      .catch((data) => {
        setIsLoading(false);
        handleShowError(data.error);
        if (data.errorType === 'password_expired') {
          setIsPassExpired(true);
        }
      });
  };

  const handleNotificationToggle = () => {
    setNotificationAlertProps({
      message: '',
      type: '',
      open: false,
    });
  };

  const formProps = {
    formValues,
    isLoading,
    handleInputChange: handleInputChange,
    handleSubmit: handleSubmit,
  };

  const forgotPasswordModalProps = {
    forgotPassword: forgotPassword,
  };

  const periodicChangePasswordModalProps = {
    email: formValues.email,
    periodicChangePassword: periodicChangePassword,
    setIsPassExpired: setIsPassExpired,
  };

  const handleConfirm = (value) => {
    setValidating(true);

    fetch(`${domain}/v1/auth-code/validate`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email: formValues.email,
        code: value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.valid) {
          dispatch(authActions.authLogin(loginResponse));
          setCookie('token', loginResponse.AuthenticationResult.AccessToken);
          router.push('/dashboard');
        }
        if (res.code >= 400) {
          handleShowError(res.error);
          setValidating(false);
        }
      })
      .catch((err) => {
        handleShowError(err.error);
        setValidating(false);
      });
  };

  const handleShowError = (message, type = 'error') => {
    setNotificationAlertProps({
      message: message,
      type,
      open: true,
      handleNotificationToggle: handleNotificationToggle,
    });
  };

  return (
    <React.Fragment>
      <MatGrid container justifyContent="center">
        <MatGrid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography align="center" paragraph>
                {t('login')}
              </Typography>
              <Form {...formProps} />
              <ForgotPasswordModal {...forgotPasswordModalProps} />
              {isPassExpired && (
                <PeriodicChangePasswordModal {...periodicChangePasswordModalProps} />
              )}
            </CardContent>
          </Card>
        </MatGrid>
      </MatGrid>
      <NotificationAlert {...notificationAlertProps} />

      <VerificationForm
        open={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        handleConfirm={handleConfirm}
        loading={validating}
        handleShowError={handleShowError}
        values={formValues}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(service.login(credentials)),
    forgotPassword: (email) => dispatch(service.forgotPassword(email)),
    periodicChangePassword: (credentials) => dispatch(service.periodicChangePassword(credentials)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
