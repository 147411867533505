const LINE_TAGS_ID = process.env.REACT_APP_LINE_TAGS_ID;

export const ltag = (name) => {
  if (window._lt) {
    window._lt(
      'send',
      'cv',
      {
        type: name,
      },
      [LINE_TAGS_ID]
    );
  }
};

export const ltagConfig = () => {
  let config = `(function (g, d, o) {
    g._ltq = g._ltq || [];
    g._lt =
      g._lt ||
      function () {
        g._ltq.push(arguments);
      };
    var h =
      location.protocol === "https:"
        ? "https://d.line-scdn.net"
        : "http://d.line-cdn.net";
    var s = d.createElement("script");
    s.async = 1;
    s.src = o || h + "/n/line_tag/public/release/v1/lt.js";
    var t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  })(window, document); \n`;

  config += `
  _lt("init", {
    customerType: "account",
    tagId: "${LINE_TAGS_ID}",
  }); \n`;

  config += `_lt("send", "pv", ["${LINE_TAGS_ID}"]) \n`;

  config += `
  _lt(
    "send",
    "cv",
    {
      type: "Conversion",
    },
    ["${LINE_TAGS_ID}"]
  );`;

  return config;
};
