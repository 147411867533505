import {
    FETCH_BOOKING_INFORMATION, 
    SUCCESS_GET_BOOKING_INFORMATION, 
    FAIL_GET_BOOKING_INFORMATION,
    UPDATE_CANCEL_BOOKING_STATUS,
    SUCCESS_UPDATE_CANCEL_BOOKING_STATUS, 
} from './action-types';

const initialState = {
    booking_details: {},
    loading: false,
    error: '',
};

export function bookingInformation(state = initialState, { type, payload = null }) {
    switch (type) {
    case FETCH_BOOKING_INFORMATION:
        return {
            ...initialState,
            loading: true,
        };
    case SUCCESS_GET_BOOKING_INFORMATION:
        return {
            ...initialState,
            loading: false,
            booking_details: payload,
        };
    case FAIL_GET_BOOKING_INFORMATION:
        return {
            ...initialState,
            loading: false,
            error: payload,
        };
    case SUCCESS_UPDATE_CANCEL_BOOKING_STATUS:
        return {
            ...state,
        };
    case UPDATE_CANCEL_BOOKING_STATUS:
        return {
            ...state,
        };
        
    default:
        return state;
    }
}
