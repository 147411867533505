import * as types from "./actionTypes";

const initialState = {
  open: false,
  type: "success",
  message: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SHOW_NOTIFICATIONS:
      return {
        ...state,
        ...payload,
        open: true,
      };

    case types.HIDE_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    // default
    default:
      return state;
  }
};

export default reducer;