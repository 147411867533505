import { combineReducers } from 'redux';
import { authentication } from '../modules/auth/store/reducers';
import { pageManagement } from '../modules/admin/page-management/store/reducers';
import { coupons } from '../modules/admin/coupon-management/store/reducers';
import { userManagement } from '../modules/admin/user-management/store/reducers';
import { reservationManagement } from '../modules/admin/reservation-management/store/reducers';
import { inquiryManagement } from '../modules/admin/inquiry-management/store/reducers';
import { notificationManagement } from '../modules/admin/notification-management/store/reducers';
import { logManagement } from '../modules/admin/log-management/store/reducers';
import { imageManagement } from '../modules/admin/image-management/store/reducers';
import { emailSubscription } from '../modules/admin/email-subscription/store/reducers';
import { searchResult } from '../components/services/searchActivities/store/reducers';
import { activityDetails } from '../components/settings/componentSettingsServices/getActivityDetails/store/reducers';
import { bookingInformation } from '../modules/booking-information/store/reducers';
import { reservationCart } from '../modules/cart/store/reducers';
import {
  activities,
  localReports,
} from '../components/componentServices/searchActivities/store/reducers';
import currency from '../layouts/common/store/reducers';
import exchangeCurrencies from '../layouts/common/store/exchangeReducer';
import { exchangeRates } from '../modules/admin/exchange-rate-management/store/reducers';
import { categoriesManagement } from '../modules/admin/categories-management/store/reducers';
import filterCurrency from '../layouts/common/store/filterReducer';
import paymentCurrency from '../layouts/common/store/paymentCurrencyReducer';
import alertNotifications from '../layouts/alert-notifications/store/reducer';
import notifications from '../layouts/notification/store/reducer';
import membershipAuth from '../modules/membership/store/reducer';
import common from '../layouts/common/store/commonReducer';
import { selections } from '../components/componentServices/selectedValues/store/reducer';

const rootReducer = combineReducers({
  common,
  membershipAuth,
  alertNotifications,
  authentication,
  notificationManagement,
  pageManagement,
  coupons,
  userManagement,
  reservationManagement,
  inquiryManagement,
  logManagement,
  imageManagement,
  emailSubscription,
  searchResult,
  activityDetails,
  bookingInformation,
  reservationCart,
  activities,
  currency,
  exchangeCurrencies,
  exchangeRates,
  localReports,
  categoriesManagement,
  filterCurrency,
  paymentCurrency,
  notifications,
  selections,
});

export default rootReducer;
