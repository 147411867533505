import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// styles
import styles from '../assets/jss/styles/components/areasStyle';
// lodash
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { excludedCities, countryOrder } from 'utils/constants';
import { sortedCities } from 'utils/helper';

const useStyles = makeStyles(styles);

const domain = process.env.REACT_APP_DOMAIN_API || 'http://localhost';
const headers = {
  Accept: 'application/json',
};

const isJapLang = process.env.REACT_APP_VELTRA_LANGUAGE_CODE === 'jp';

const Areas = (props) => {
  const classes = useStyles();
  const { isModal, areaList = [] } = props;
  const { t } = useTranslation();

  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (areaList.length) {
      setAreas(areaList);
    } else {
      setLoading(true);
      fetch(`${domain}/v1/areas`, {
        method: 'GET',
        headers,
      })
        .then((res) => res.json())
        .then((res) => {
          setAreas(res.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, []); // eslint-disable-line

  const initAreas = () => {
    const additional = areas.length % 2;

    let counter = 1;
    let byRow = additional === 0 ? areas.length / 2 : parseInt(areas.length / 2) + additional;
    let columns = [];
    let row = [];

    _.sortBy(areas, (item) => {
      return countryOrder.indexOf(item.country);
    }).forEach(({ country, jp_name, cities }, i) => {
      row.push(
        <Grid key={i} item xs={12} container spacing={1}>
          <Grid item xs={4}>
            <a href={`/country/${_.lowerCase(country)}`} style={{ textDecoration: 'none' }}>
              <Typography
                paragraph
                className={isModal ? classes.countryTextBlackColor : classes.countryText}
              >
                {isJapLang && jp_name ? jp_name : t(country)}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={8} container spacing={1}>
            {initCities(cities, country)}
          </Grid>
        </Grid>
      );

      if (i + 1 === byRow * counter) {
        columns.push(row);
        row = [];
        counter++;
      }

      if (areas.length - 1 === i && byRow * counter > areas.length) {
        columns.push(row);
        row = [];
        counter++;
      }
    });

    return (
      <React.Fragment>
        {columns.map((column, i) => (
          <Grid key={i} item xs={12} md={6}>
            {column}
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  const initCities = (cities, country) => {
    return sortedCities(cities, country)
      .filter((city) => !excludedCities.includes(city.jp_name))
      .map(({ name, jp_name, links }, i) => {
        const cityName = isJapLang ? jp_name : t(name);

        const url = links
          ? `${links}?area=${jp_name}`
          : `/country/${country.toLowerCase()}/city/${name.toLowerCase()}`;

        return (
          <Grid item {...(!isModal && { xs: 6 })} key={i + country} className={classes.city}>
            <a href={url} style={{ textDecoration: 'none' }}>
              <Typography
                paragraph
                className={isModal ? classes.cityTextBlackColor : classes.cityText}
              >
                {cityName}
              </Typography>
            </a>
          </Grid>
        );
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Grid item xs={12} container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        initAreas()
      )}
    </React.Fragment>
  );
};

export default memo(Areas);
