import {
    SUCCESS_SEARCH_ACTIVITIES,
    FAIL_SEARCH_ACTIVITIES,
    SUCCESS_SEARCH_LOCALREPORTS,
    FAIL_SEARCH_LOCALREPORTS,
} from './action-types';

const initialState = {
    data: {},
    loading: false,
    error: '',
};

export function activities(state = initialState, { type, payload = null }) {
    switch (type) {
        case SUCCESS_SEARCH_ACTIVITIES:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.id]: payload.data.activities || []
            },
            loading: false,
        };
    case FAIL_SEARCH_ACTIVITIES:
        return {
            ...state,
            error: payload,
            loading: false,
        };
    default:
        return state;
    }
}

export function localReports(state = initialState, { type, payload = null }) {
    switch (type) {
        case SUCCESS_SEARCH_LOCALREPORTS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.id]: payload.data || []
            },
            loading: false,
        };
    case FAIL_SEARCH_LOCALREPORTS:
        return {
            ...state,
            error: payload,
            loading: false,
        };
    default:
        return state;
    }
}
