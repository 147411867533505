import * as types from './action-types';

const initial = {
    items: [],
    fetching: false,
    searching: false,
    updating: false,
    fail: false,
    error: {},
    meta: {},
    notification: {
        state: false,
        type: 'success',
        message: '',
    },
};

export function exchangeRates(state = initial, { type, payload }) {
    switch (type) {
    // notification

    case types.HANDLE_NOTIFICATION:
        return {
            ...state,
            notification: payload,
        };

    case types.HANDLE_CLOSE_NOTIFICATION:
        return {
            ...state,
            notification: {
                ...state.notification,
                state: payload,
            },
        };

    //fetching case
    case types.REQUEST_FETCH_EXCHANGE_RATES:
        return {
            ...state,
            fetching: true,
            fail: false,
        };

    case types.FETCH_EXCHANGE_RATES:
        return {
            ...state,
            fetching: false,
            fail: false,
            items: payload.data,
            meta: payload.meta,
        };

    case types.FAIL_FETCH_EXCHANGE_RATES:
        return {
            ...state,
            fetching: false,
            fail: true,
            error: payload,
        };

    //searching case
    case types.REQUEST_SEARCH_EXCHANGE_RATES:
        return {
            ...state,
            searching: true,
            fail: false,
        };

    case types.SEARCH_EXCHANGE_RATES:
        return {
            ...state,
            searching: false,
            fail: false,
            items: payload.data,
            meta: payload.meta,
        };

    case types.FAIL_SEARCH_EXCHANGE_RATES:
        return {
            ...state,
            searching: false,
            fail: true,
            error: payload,
        };

    //update EXCHANGE_RATES case
    case types.REQUEST_UPDATE_EXCHANGE_RATES:
        return {
            ...state,
            updating: true,
            fail: false,
        };

    case types.UPDATE_EXCHANGE_RATES:
        return {
            ...state,
            updating: false,
            fail: false,
            items: state.items.map((item) => {
                if (item.id === payload.id) {
                    return payload;
                }
                return item;
            }),
        };

    case types.FAIL_UPDATE_EXCHANGE_RATES:
        return {
            ...state,
            updating: false,
            fail: true,
            error: payload,
        };

    default:
        return state;
    }
}
