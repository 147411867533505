import cookie from 'js-cookie';

export const setCookie = (key, value) => {
  cookie.set(key, value);
};

export const removeCookie = (key) => {
  cookie.remove(key);
};

export const getCookie = (key, req) => {
  return getCookieFromBrowser(key);
};

export const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};
