import { getCookieFromBrowser } from '../utils/Cookie';
import AuthService from '../modules/auth/service';
import jwt from 'jwt-decode';

const domain = process.env.REACT_APP_DOMAIN_API;
const service = new AuthService(domain);
const token = getCookieFromBrowser('token');

export function refreshTokenMiddleware() {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      // only worry about expiring token for async actions
      if (typeof action === 'function') {
        if (token) {
          // decode jwt so that we know if and when it expires
          try {
            var tokenExpiration = jwt(token, { complete: true });
          } catch (error) {
            // invalid token format
            console.log(error);
          }
          if (tokenExpiration.exp < Date.now() / 1000) {
            if (!getState().authentication.updatedToken) {
              return service.refreshToken(dispatch).then(() => next(action));
            } else {
              return next(action);
            }
          }
        }
      }

      return next(action);
    };
}
