import * as types from './action-types';

const initialState = {
  isFetching: false,
  exchangeCurrencies: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_MODIFIED_CURRENCIES:
      return fetchModifiedCurrencies(state);
    case types.SUCCESS_FETCH_MODIFIED_CURRENCIES:
      return storeModifiedCurencies(state, payload);
    case types.FAIL_FETCH_MODIFIED_CURRENCIES:
      return storeFailFetchModifiedCurrencies(state, payload);
    default:
      return state;
  }
};

function fetchModifiedCurrencies(state) {
  return Object.assign({}, state, {
    ...state,
    isFetching: true,
  });
}

function storeModifiedCurencies(state, payload) {
  return Object.assign({}, state, {
    ...state,
    exchangeCurrencies: payload,
    isFetching: false,
  });
}

function storeFailFetchModifiedCurrencies(state, payload) {
  return Object.assign({}, state, {
    ...state,
    error: payload,
    isFetching: false,
  });
}

export default reducer;
