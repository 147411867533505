import * as types from './action-types';
import { setCookie } from '../../../utils/Cookie';

const initialState = {
  name: 'JPY',
  symbol: '¥',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SELECTED_CURRENCY:
      setCookie('currentCurrency', (payload && JSON.stringify(payload)) || {});
      return payload;
    default:
      return state;
  }
};

export default reducer;
