import * as types from './action-types';

const initialState = {
  currency: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_PAYMENT_CURRENCY:
      return { currency: payload };
    default:
      return state;
  }
};

export default reducer;
