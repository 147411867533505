const drawerWidth = 280;

const headerStyle = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    badgeCounter: {
        backgroundColor: 'transparent',
    },
    menuButton: {
        marginRight: 15,
    },
    notificationButton: {
        marginRight: 10,
    },
    title: {
        flexGrow: 1,
    },
    logout: {
        color: '#fff'
    },
    enLabel: {
        fontFamily: 'Noto Sans JP, Helvetica, Arial, sans-serif',
        marginRight: 13
    }
});

export default headerStyle;