import Http from 'utils/Http';
import * as actions from './store/actions';

export const getCommonAreas = () => {
  return (dispatch) => {
    dispatch(actions.fetchingAreas());
    return Http('/v1/areas')
      .then((res) => res.json())
      .then((res) => {
        dispatch(actions.fetchAreas(res.data || []));

        return res;
      })
      .catch((err) => {
        dispatch(actions.failFetchingAreas(err || 'Server Error Message'));
        return { error: true, err };
      });
  };
};
