import { LOAD_USER_LIST, UPDATE_USER } from './action-types';

const initialState = {
  userList: [],
};

export function userManagement(state = initialState, { type, payload = null }) {
  switch (type) {
    case LOAD_USER_LIST:
      return storeUserList(state, payload);

    case UPDATE_USER:
      return {
        ...state,
        userList: {
          ...state.userList,
          data: state.userList.data.map((user) => (user.id === payload.id ? payload : user)),
        },
      };
    default:
      return state;
  }
}

function storeUserList(state, payload) {
  return {
    ...state,
    userList: payload,
  };
}
