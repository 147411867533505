import { removeCookie, setCookie } from '../../../utils/Cookie';
import * as types from './actionTypes';

export const setCustomerInfo = (payload) => {
  return {
    type: types.SET_INFO,
    payload,
  };
};

export const loggedIn = (payload) => {
  setCookie('c_token', payload.authorization_token);
  setCookie('c_expiration', payload.authorization_token_expired_at);
  setCookie('c_email', payload.email);
  setCookie('c_id', payload.customer_id);

  return {
    type: types.LOGGED_IN,
  };
};

export const loggedOut = () => {
  removeCookie('c_token');
  removeCookie('c_expiration');
  removeCookie('c_email');
  removeCookie('c_id');

  return {
    type: types.LOGGED_OUT,
  };
};

export const setAuth = () => {
  return {
    type: types.SET_AUTH,
  };
};

export const setLikedList = (payload) => {
  return {
    type: types.SET_LIKED,
    payload,
  };
};

export const setUnlikedList = (payload) => {
  return {
    type: types.SET_UNLIKED,
    payload,
  };
};

export const setActive = (payload) => {
  return {
    type: types.SET_ACTIVE,
    payload,
  };
};
