import * as types from './action-types';

const initialState = {
  filter: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_CURRENCY_FILTER:
      return { filter: payload };
    default:
      return state;
  }
};

export default reducer;
