import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
  },
});

function Forbidden() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.text} color="error">
        Forbidden
      </Typography>
    </Box>
  );
}

export default Forbidden;
