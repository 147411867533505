export const cardCollection = [
  'Card1',
  'Card2',
  'Card3',
  // 'Card4',
  'Card5',
  'Card7',
  'Card8',
  'Card10',
  'Card11',
  'Card13',
  'Card14',
];

export const envValues = {
  currencies: [
    {
      name: 'JPY',
      symbol: '¥',
    },
    {
      name: 'PHP',
      symbol: 'Php',
    },
    {
      name: 'TWD',
      symbol: 'TW$',
    },
    {
      name: 'IDR',
      symbol: 'Rp',
    },
    {
      name: 'KHR',
      symbol: '៛',
    },
    {
      name: 'MYR',
      symbol: 'RM',
    },
    // {
    //     name: 'MMK',
    //     symbol: 'K'
    // },
    {
      name: 'NZD',
      symbol: 'NZ$',
    },
    {
      name: 'THB',
      symbol: '฿',
    },
    {
      name: 'VND',
      symbol: '₫',
    },
    {
      name: 'SGD',
      symbol: 'SG$',
    },
    {
      name: 'INR',
      symbol: '₹',
    },
    {
      name: 'HKD',
      symbol: 'HK$',
    },
    {
      name: 'MOP',
      symbol: 'MOP$',
    },
    {
      name: 'AUD',
      symbol: 'AU$',
      // scope: ['AUD', 'JPY'],
    },
    {
      name: 'USD',
      symbol: '$',
    },
  ],
  banners: ['Banner1', 'Banner2', 'Banner3', 'Banner4'],
  components: [
    'Sns1',
    // 'InstagramCard',
    'Anchor1',
    'Anchor2',
    'Button1',
    'CardGroup',
    'CardGroup2',
    'Carousel1',
    'Carousel2',
    'Card1',
    'Card2',
    'Card3',
    // 'Card4',
    'Card5',
    'Card6',
    'Card7',
    'Card8',
    'Card9',
    'Card10',
    'Card11',
    'Card12',
    'Card13',
    'Card14',
    'LinkList',
    'LocalReportList',
    'CheckList',
    'TextImage4',
    'TextImage5',
    'TextImage8',
    'CardStoreInfo',
    'CardScroller1',
    'HtmlCard',
    'SearchResults',
    'CategorySearchResult',
    'CardVeltraActivity',
    'ScrollerVeltra',
    'SearchCategoryCard',
    'CardVeltraBig',
    'AdBanner',
  ],
  cards: [
    'Card1',
    'Card2',
    'Card3',
    // 'Card4',
    'Card5',
    'Card6',
    'Card7',
    'Card8',
    'Card9',
    'Card10',
    'Card11',
    'Card12',
    'Card13',
    'Card14',
    'HtmlCard',
    'TextImage4',
    'TextImage5',
    'TextImage8',
    'CardStoreInfo',
  ],
  tourCards: [
    'Card3',
    //  "Card4"
  ],
  variants: ['Default', 'Contained', 'Outlined'],
  alignments: [
    {
      name: 'flex-start',
      label: 'Left',
    },
    {
      name: 'center',
      label: 'Center',
    },
    {
      name: 'flex-end',
      label: 'Right',
    },
  ],
  directions: ['row', 'column'],
};
export const categories = {
  view: 'みる',
  eat: 'たべる',
  buy: 'かう',
  play_around: 'あそぶ',
  transfer: 'のる',
};

export const excludedCities = [
  '花蓮',
  'ペナン',
  // 'クラビ', // krabi
  // 'サムイ', // samui
  'ホイアン',
  'フエ',
  'マニラ',
  'ジャカルタ',
  'ダーウィン',
  'ホバート',
  'ダニーデン',
  'タウポ',
  'ウェリントン',
  'テアナウ',
];

export const priceFilterOptions = [
  { id: 1, value: `０円 ～ 5,000円`, display_amount_min: 0, display_amount_max: 5000 },
  { id: 2, value: `5,001円 ～ 10,000円`, display_amount_min: 5001, display_amount_max: 10000 },
  { id: 3, value: `10,001円 ～ 15,000円`, display_amount_min: 10001, display_amount_max: 15000 },
  { id: 4, value: `15,001円 ～ 20,000円`, display_amount_min: 15001, display_amount_max: 20000 },
  { id: 5, value: `20,001円 ～ 25,000円`, display_amount_min: 20001, display_amount_max: 25000 },
  { id: 6, value: `25,001円 ～ 30,000円`, display_amount_min: 25001, display_amount_max: 30000 },
  { id: 7, value: `30,001円 ～`, display_amount_min: 30001, display_amount_max: 50000 },
];

export const countryOrder = [
  'Taiwan',
  'Singapore',
  'Malaysia',
  'Thailand',
  'Vietnam',
  'Philippines',
  'Indonesia',
  'Australia',
  'New Zealand',
];

export const categoryOrder = ['みる', 'あそぶ', 'たべる', 'のる', 'かう'];

export const citiesOrder = [
  {
    country: 'Australia',
    cities: [
      'ケアンズ',
      'シドニー',
      'ウルル（エアーズロック）',
      'メルボルン',
      'ゴールドコースト',
      'パース',
      'ハミルトン島',
    ],
  },
];

//category order by category id
export const desktopCategoryOrder = [
  40, 39, 41, 42, 6, 29, 43, 44, 13, 14, 38, 28, 12, 5, 22, 37, 9, 25, 19, 30, 23, 33, 7, 17, 27,
  18, 31, 26, 36, 34, 35, 20, 24, 10, 21, 15, 16, 32,
];

const viewOrder = [
  40, 39, 41, 42, 6, 13, 14, 38, 28, 12, 9, 19, 30, 23, 33, 7, 17, 27, 10, 21, 15, 16, 32,
];
const playOrder = [9, 25, 19, 23, 18, 31, 26, 36, 34, 35, 20];

const eatOrder = [5, 22, 37];

const transferOrder = [29, 43, 44, 21];

const buyOrder = [24];

export const subCategoryOrder = (category) => {
  if (category === 'みる') {
    return viewOrder;
  } else if (category === 'あそぶ') {
    return playOrder;
  } else if (category === 'たべる') {
    return eatOrder;
  } else if (category === 'のる') {
    return transferOrder;
  } else if (category === 'かう') {
    return buyOrder;
  }
};

export const countryCode = {
  taiwan: 'TW',
  singapore: 'SG',
  malaysia: 'MY',
  thailand: 'TH',
  vietnam: 'VN',
  philippines: 'PH',
  indonesia: 'ID',
  australia: 'AU',
  cambodia: 'KH',
  mayanmar: 'MM',
  hongkong: 'HK',
  macau: 'MO',
  'new zealand': 'NZ',
};
