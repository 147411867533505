import fetch from 'isomorphic-unfetch';

const backend = process.env.REACT_APP_DOMAIN_API || 'http://localhost:3000';
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export function getExchangeRate() {
  return new Promise((resolve, reject) => {
    fetch(`${backend}/v1/modifiedExchangeRates`, {
      method: 'GET',
      headers,
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
