import * as types from './action-types';

const initial = {
    items: [],
    deleting: false,
    fetching: false,
    creating: false,
    searching: false,
    changing: false,
    updating: false,
    fail: false,
    error: {},
    meta: {},
    notification: {
        state: false,
        type: 'success',
        message: '',
    },
};

export function coupons(state = initial, { type, payload }) {
    switch (type) {
        // notification

        case types.HANDLE_NOTIFICATION:
            return {
                ...state,
                notification: payload,
            };

        case types.HANDLE_CLOSE_NOTIFICATION:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    state: payload,
                },
            };

        //fetching case
        case types.REQUEST_FETCH_COUPON:
            return {
                ...state,
                fetching: true,
                fail: false,
            };

        case types.FETCH_COUPON:
            return {
                ...state,
                fetching: false,
                fail: false,
                items: payload.data,
                meta: payload.meta,
            };

        case types.FAIL_FETCH_COUPON:
            return {
                ...state,
                fetching: false,
                fail: true,
                error: payload,
            };

        //creating case
        case types.REQUEST_CREATE_COUPON:
            return {
                ...state,
                creating: true,
                fail: false,
            };

        case types.CREATE_COUPON:
            return {
                ...state,
                creating: false,
                fail: false,
                items: [payload, ...state.items],
            };

        case types.FAIL_CREATE_COUPON:
            return {
                ...state,
                creating: false,
                fail: true,
                error: payload,
            };

        //searching case
        case types.REQUEST_SEARCH_COUPON:
            return {
                ...state,
                searching: true,
                fail: false,
            };

        case types.SEARCH_COUPON:
            return {
                ...state,
                searching: false,
                fail: false,
                items: payload.data,
                meta: payload.meta,
            };

        case types.FAIL_SEARCH_COUPON:
            return {
                ...state,
                searching: false,
                fail: true,
                error: payload,
            };

        //change coupon status  case
        case types.REQUEST_CHANGE_COUPON_STATUS:
            return {
                ...state,
                changing: true,
                fail: false,
            };

        case types.CHANGE_COUPON_STATUS:
            return {
                ...state,
                changing: false,
                fail: false,
                items: state.items.map((item) => {
                    if (item.id === payload.id) {
                        return {
                            ...item,
                            status: payload.status,
                        };
                    }

                    return item;
                }),
            };

        case types.FAIL_CHANGE_COUPON_STATUS:
            return {
                ...state,
                changing: false,
                fail: true,
                error: payload,
            };

        //update coupon case
        case types.REQUEST_UPDATE_COUPON:
            return {
                ...state,
                updating: true,
                fail: false,
            };

        case types.UPDATE_COUPON:
            return {
                ...state,
                updating: false,
                fail: false,
                items: state.items.map((item) => {
                    if (item.id === payload.id) {
                        return payload;
                    }
                    return item;
                }),
            };

        case types.FAIL_UPDATE_COUPON:
            return {
                ...state,
                updating: false,
                fail: true,
                error: payload,
            };

        //delete coupon case
        case types.REQUEST_DELETE_COUPON:
            return {
                ...state,
                deleting: true,
                fail: false,
            };

        case types.DELETE_COUPON:
            return {
                ...state,
                deleting: false,
                fail: false,
                items: state.items.filter((item) => item.id !== payload.id),
            };

        case types.FAIL_DELETE_COUPON:
            return {
                ...state,
                deleting: false,
                fail: true,
                error: payload,
            };

        default:
            return state;
    }
}
