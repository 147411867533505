import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// styles
import styles from '../../../assets/jss/styles/layouts/common/footerStyle.js';
import { useTranslation } from 'react-i18next';
import { Grid, Hidden, useMediaQuery, CircularProgress, Divider } from '@material-ui/core';
import { useState, useEffect } from 'react';
//Social media icons
import facebook from '../../../assets/img/facebook.png';
import twitter from '../../../assets/img/twitter.png';
import Mybus from '../../../assets/img/mybus_renewal_logo.png';
//components
import Areas from '../../../components/Areas';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(styles);
const domain = process.env.REACT_APP_DOMAIN_API || 'http://localhost';
const headers = {
  Accept: 'application/json',
};

const Footer = (props) => {
  const { areaList = [] } = props;
  const classes = useStyles();
  const location = useLocation();
  const Router = useHistory();
  const currentPage = location.pathname;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showCountryList, setShowCountryList] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(t('Search by region/city'));

  const isDesktop = useMediaQuery('(min-width:1490px)');

  useEffect(() => {
    if (areaList.length) {
      setCountryList(areaList);
    } else {
      setLoading(true);
      fetch(`${domain}/v1/areas`, {
        method: 'GET',
        headers,
      })
        .then((res) => res.json())
        .then((res) => {
          setCountryList(res.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, []); // eslint-disable-line

  const handleShowCountryList = () => {
    setShowCountryList(!showCountryList);
  };

  const handleSelectCountry = (value, link) => {
    setSelectedCountry(value);
    if (link) {
      Router.push({
        pathname: link.toLowerCase(),
      });
    }
    setShowCountryList(false);
  };

  const renderList = () => {
    let counter = 0;
    let list = [];

    list.push(
      <li
        className={classes.country}
        key={counter}
        value={t('Search by region/city')}
        onClick={() => handleSelectCountry(t('Search by region/city'))}
      >
        {t('Search by region/city')}
      </li>
    );

    countryList.forEach((country) => {
      counter++;

      list.push(
        <li
          className={classes.country}
          key={counter}
          onClick={() => handleSelectCountry(t(country.country), `/country/${country.country}`)}
        >
          {t(country.country)}
        </li>
      );

      if (country.cities && country.cities.length > 0) {
        country.cities.forEach((city) => {
          counter++;
          list.push(
            <li
              className={classes.city}
              key={counter}
              onClick={() =>
                handleSelectCountry(city.jp_name, `/country/${country.country}/city/${city.name}`)
              }
            >
              {city.jp_name}
            </li>
          );
        });
      }
      counter++;
    });

    return list;
  };

  const renderSocialMedia = () => {
    return (
      <section className={classes.socialMediaContent}>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={12} md={1}></Grid>

            <Grid
              item
              xs={12}
              md={10}
              container
              justifyContent="center"
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <a
                  href="https://www.facebook.com/mybusasiapacific/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialMediaImage}
                >
                  <img alt="mybus-footer-facebook-logo" src={facebook} />
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://twitter.com/JTB_globalnews"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialMediaImage}
                >
                  <img alt="mybus-footer-twitter-logo" src={twitter} />
                </a>
              </Grid>
              {/* linkedin link Hidden for future use */}
              {/* <Grid item>
                            <a
                                href="/"
                                className={classes.socialMediaImage}
                            >
                                <img src={linkedin} />
                            </a>
                        </Grid> */}
            </Grid>
          </Hidden>
        </Grid>
      </section>
    );
  };

  return (
    <footer className={classes.footer}>
      <div
        className={classes.content}
        style={{
          width: isDesktop
            ? currentPage.includes('country') ||
              currentPage.includes('campaign') ||
              currentPage === '/'
              ? '1352px'
              : '1400px'
            : '100%',
        }}
      >
        <Hidden xsDown>
          <section className={classes.sectionSubscribe}>
            <Grid container>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.textDescription}>
                    {t('footer_text')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.areas}>
                    <Grid item xs={12} container spacing={1} className={classes.countryList}>
                      <Areas areaList={props.areas} {...props} />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </section>
          <section className={classes.logoContent}>
            <Grid container>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} md={6} container spacing={1}>
                  <Grid item xs={4}>
                    <Link to="/">
                      <img alt="mybus-footer-desktop-logo" src={Mybus} className={classes.logo} />
                    </Link>
                  </Grid>
                  <Grid item xs={8} container alignContent="center">
                    <Typography paragraph className={classes.text}>
                      {t('Reservation of local optional tour')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} container spacing={1}>
                  <Grid item xs={3} container alignContent="center">
                    <Link to="/company-profile" className={classes.staticPageLinks}>
                      <Typography paragraph className={classes.text}>
                        {t('Company Profile')}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={9} container>
                    <Grid item xs={3} container alignContent="center">
                      <Link to="/terms" className={classes.staticPageLinks}>
                        <Typography paragraph className={classes.text}>
                          {t('Travel Application Terms and Conditions')}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={5} container alignContent="center">
                      <Link to="/membership-terms" className={classes.staticPageLinks}>
                        <Typography paragraph className={classes.text}>
                          {t('My Bus Site Terms of Use')}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={4} container alignContent="center">
                      <Link to="/privacy-policy" className={classes.staticPageLinks}>
                        <Typography paragraph className={classes.text}>
                          {t('Privacy Policy')}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
          <div className={classes.bottomPadding}></div>
        </Hidden>
        <Hidden smUp>
          <section>
            <Grid container>
              {loading ? (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div style={{ position: 'relative', display: 'block' }}>
                    <div className={classes.mobileInputEmail} onClick={handleShowCountryList}>
                      <div>
                        <Typography paragraph>{selectedCountry}</Typography>
                        <KeyboardArrowDownIcon />
                      </div>
                    </div>
                    {showCountryList && (
                      <div className={classes.mobileSelectCountry}>{renderList()}</div>
                    )}
                  </div>
                </Grid>
              )}

              <Grid item xs={12} container justifyContent="center">
                <Link to="/">
                  <img alt="mybus-mobile-logo" src={Mybus} className={classes.logoMobile} />
                </Link>
              </Grid>
              <Grid item xs={12} container alignContent="center">
                <Divider className={classes.footerDivider} />
              </Grid>
              <Grid item xs={12}>
                <Link to="/company-profile" className={classes.staticPageLinks}>
                  <Typography paragraph align="center" className={classes.text}>
                    {t('Company Profile')}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/terms" className={classes.staticPageLinks}>
                  <Typography paragraph align="center" className={classes.text}>
                    {t('Tour Application Conditions')}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/membership-terms" className={classes.staticPageLinks}>
                  <Typography paragraph align="center" className={classes.text}>
                    {t('Membership Terms')}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/privacy-policy" className={classes.staticPageLinks}>
                  <Typography paragraph align="center" className={classes.text}>
                    {t('Privacy Policy')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </section>
          {renderSocialMedia()}
        </Hidden>
      </div>
    </footer>
  );
};

export default Footer;
