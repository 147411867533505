import {
    FETCH_ACTIVITY_DETAILS,
    SUCCESS_FETCH_ACTIVITY_DETAILS,
    FAIL_FETCH_ACTIVITY_DETAILS,
    FETCH_SEARCH_ACTIVITIES,
    SUCCESS_SEARCH_ACTIVITIES,
    FAIL_SEARCH_ACTIVITIES
} from './action-types';

const initialState = {
    isFetching: false,
    searchResults: [],
    error: {}
};

export function reservationCart(state = initialState, { type, payload = null }) {
    switch (type) {
    case FETCH_ACTIVITY_DETAILS:
        return fetchActivityDetails(state);
    case SUCCESS_FETCH_ACTIVITY_DETAILS:
        return storeActivityDetails(state, payload);
    case FAIL_FETCH_ACTIVITY_DETAILS:
        return storeFailFetchActivityDetails(state, payload);
    case FETCH_SEARCH_ACTIVITIES:
        return fetchSearchActivities(state);
    case SUCCESS_SEARCH_ACTIVITIES:
        return storeSearchActivities(state, payload);
    case FAIL_SEARCH_ACTIVITIES:
        return storeFailSearchActivities(state, payload);
    default:
        return state;
    }
}

function fetchActivityDetails(state) {
    return Object.assign({}, state, {
        ...state,
        isFetching: true
    });
}

function storeActivityDetails(state, payload) {
    return Object.assign({}, state, {
        ...state,
        activities: [ ...state.activities, payload],
        isFetching: false
    });
}

function storeFailFetchActivityDetails(state, payload) {
    return Object.assign({}, state, {
        ...state,
        error: payload,
        isFetching: false,
    });
}

function fetchSearchActivities(state) {
    return Object.assign({}, state, {
        ...state,
        isFetching: true,
    });
}

function storeSearchActivities(state, payload) {
    return Object.assign({}, state, {
        ...state,
        searchResults: payload,
        isFetching: false,
    });
}

function storeFailSearchActivities(state, payload) {
    return Object.assign({}, state, {
        ...state,
        error: payload,
        isFetching: false,
    });
}