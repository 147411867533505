import { Helmet } from 'react-helmet';
import { GA_TRACKING_ID, gtagConfig } from './utils/gtag';
import './assets/scss/mainStyle.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import store from '../src/store';
import { Provider } from 'react-redux';
import Router from 'route/Router';
import theme from 'theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Maintenance from 'layouts/Maintenance';
// utils
import Http from 'utils/Http';
import { ltagConfig } from 'utils/ltag';
import RouteListener from 'route/RouteListener';

function App() {
  const location = useLocation();
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const [maintananceState, setMaintenanceState] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const isAdmin =
    pathname.includes('/admin') ||
    location.pathname === '/login' ||
    location.pathname === '/dashboard' ||
    location.pathname === '/change-password';
  const isAPTop = location.pathname === '/';
  const [currentPosition, setCurrentPosition] = useState({});

  useEffect(() => {
    handleMaintenance();
  }, [location]); // eslint-disable-line

  const handleMaintenance = () => {
    let isByPass = false;
    ['login', 'dashboard', 'change-password', 'admin'].forEach((link) => {
      if (window.location.pathname.includes(link)) {
        isByPass = true;
      }
    });

    if (isByPass) return;

    if (checkMaintenanceKeyOnUrl()) {
      setMaintenanceState(false);
      localStorage.setItem('maintenance', false);
      return;
    }

    setMaintenanceState(checkMaintenanceKeyOnLocalStorage());

    fetch(`${process.env.REACT_APP_DOMAIN_API}/v1/maintenance`)
      .then((res) => res.json())
      .then((res) => {
        let maintenance = res.maintenance !== undefined ? res.maintenance : true;
        localStorage.setItem('maintenance', maintenance);
        setMaintenanceState(maintenance);
      });
  };

  useEffect(() => {
    if (!isAdmin) {
      // ask for permission for user's GPS location
      navigator.geolocation.getCurrentPosition(
        (position) => redirectToCountryTop(position),
        (error) => setCurrentPosition(error)
      );
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isAPTop) sessionStorage.setItem('geoLocationRedirect', true);
  }, [location]); // eslint-disable-line

  const redirectToCountryTop = (currentPosition) => {
    setCurrentPosition(currentPosition);
    if (currentPosition.coords && isAPTop && !sessionStorage.getItem('geoLocationRedirect')) {
      let url = 'https://api.bigdatacloud.net/data/reverse-geocode-client?localityLanguage=en'; // default get location by ISP
      url = `${url}&latitude=${currentPosition.coords.latitude}&longitude=${currentPosition.coords.longitude}`; // if location permission is allowed get by GPS
      fetch(url, { method: 'GET' })
        .then((res) => res.json())
        .then((res) => {
          const currentcountryCode = res.countryCode;
          Http('/v1/countries')
            .then((res) => res.json())
            .then((res) => {
              const country = res.data.filter(
                (country) => country.country_code === currentcountryCode
              );
              if (country.length) {
                history.push(`country/${country[0].name.toLowerCase()}`);
                sessionStorage.setItem('geoLocationRedirect', true); // to prevent redirecting to country every time you visit AP top
              }
            });
        });
    }
  };

  const checkMaintenanceKeyOnUrl = () => {
    let query = window.location.search;
    query = query.replace('?', '');
    const queryParams = query.split('=');

    return queryParams.length > 0 && queryParams[0].includes('maintenance');
  };

  const checkMaintenanceKeyOnLocalStorage = () => {
    const maintenanceData = localStorage.getItem('maintenance');
    return maintenanceData === 'true' ? true : false;
  };

  return (
    <div className="App">
      {isProduction && (
        <Helmet>
          <meta name="facebook-domain-verification" content="ber59pc29kqy719y7bk16ztl0rfsub" />
          <script id="gtag">{gtagConfig()}</script>

          <script id="ltag">{ltagConfig()}</script>

          <script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
          <script src="https://www.google-analytics.com/analytics.js"></script>
        </Helmet>
      )}

      {maintananceState ? (
        <Maintenance />
      ) : (
        (currentPosition.coords || currentPosition.code || isAdmin) && (
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <RouteListener>
                  <Router />
                </RouteListener>
              </ThemeProvider>
            </Provider>
          </GoogleOAuthProvider>
        )
      )}
    </div>
  );
}

export default App;
