import moment from 'moment';
//custom helper
import iso3311a2 from 'iso-3166-1-alpha-2';
import { citiesOrder, envValues } from './constants';
import { Fragment } from 'react';
const currencies = envValues.currencies || [];
export const REGEX_JAPANESE =
  /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;

export const cityMap = [
  {
    name: 'kaohsiung',
    db: 'takao',
  },
  {
    name: 'siem_reap',
    db: 'siem reap',
  },
  {
    name: 'kuala_lumpur',
    db: 'kuala lumpur',
  },
  {
    name: 'chiang_mai',
    db: 'chiang mai',
  },
  {
    name: 'ho_chi_minh',
    db: 'ho chi minh city',
  },
  {
    name: 'new_delhi',
    db: 'delhi',
  },
  {
    name: 'gold_coast',
    db: 'gold coast',
  },
];

export const toJpDate = (date, config = { format: 'YYYY-MM-DD' }) => {
  const dte = moment(date, config.format);
  const year = dte.format('YYYY');
  const month = dte.format('MM');
  const day = dte.format('DD');

  return {
    label: `${year}年${month}月${day}日`,
    date: dte.format(config.format),
  };
};

const convertString = (str) => {
  let formattedString = '';
  for (let i = 0; i < str.length; i++) {
    if (i === 0) {
      formattedString += str.charAt(i).toUpperCase();
    } else {
      formattedString += str.charAt(i);
    }
  }

  return formattedString;
};

export const toPayableAmount = (amount, digits = 2) => {
  return Math.trunc(amount * Math.pow(10, digits)) / Math.pow(10, digits);
};

export const stringFormat = (str, map = false) => {
  let newString = str;

  if (map) {
    newString = str.split(/\r\n/g);
  }

  return newString.map((ltr, i) => (
    <Fragment key={i}>
      {ltr}
      {i !== newString.length - 1 && <br />}
    </Fragment>
  ));
};

export const stringToTime = (date = '', { addMinutes, format }) => {
  const stringDate = moment(date, 'HH:mm:ss');

  if (addMinutes < 0) {
    const minutes = addMinutes.replace('-', '');
    stringDate.subtract(parseInt(minutes), 'minutes');
  } else {
    stringDate.add(parseInt(addMinutes), 'minutes');
  }

  const dateString = stringDate.locale('en-US').format(format ? format : 'h:mm A');

  return dateString !== 'Invalid date' ? dateString : '';
};

// from JPY as base currency to selected currency
export const currencyConvertion = (amount, baseCurrency, selectedCurrency, exchangeRates) => {
  let value = amount;

  if (baseCurrency === selectedCurrency) {
    return value;
  }

  let rate = false;
  let ratePercent = false;
  let rateValue = false;

  if (!Array.isArray(exchangeRates)) {
    return value;
  }

  exchangeRates.some((currency) => {
    if (currency.currency_code === baseCurrency) {
      rate = currency[`${selectedCurrency.toLowerCase()}_exchange_rate`] || false;

      if (currency.modified_values) {
        const currentDate = moment();

        const startTime =
          currency.modified_values[`${baseCurrency}_MODIFIED_VALIDITY_FROM`] &&
          moment(currency.modified_values[`${baseCurrency}_MODIFIED_VALIDITY_FROM`], 'YYYY/MM/DD');

        const endTime =
          currency.modified_values[`${baseCurrency}_MODIFIED_VALIDITY_TO`] &&
          moment(currency.modified_values[`${baseCurrency}_MODIFIED_VALIDITY_TO`], 'YYYY/MM/DD');

        if (currentDate.isSameOrAfter(startTime) && currentDate.isSameOrBefore(endTime)) {
          if (
            currency.modified_values[`${baseCurrency}_MODIFIED_RATE_PERCENT`] &&
            currency.modified_values[`${baseCurrency}_MODIFIED_RATE_VALUE`]
          ) {
            ratePercent = currency.modified_values[`${baseCurrency}_MODIFIED_RATE_PERCENT`];
          }

          if (currency.modified_values[`${baseCurrency}_MODIFIED_RATE_PERCENT`] === '') {
            rateValue = currency.modified_values[`${baseCurrency}_MODIFIED_RATE_VALUE`];
          }

          if (currency.modified_values[`${baseCurrency}_MODIFIED_RATE_VALUE`] === '') {
            ratePercent = currency.modified_values[`${baseCurrency}_MODIFIED_RATE_PERCENT`];
          }
        }
      }
      return false;
    }

    return false;
  });

  if (rate !== false && ratePercent !== false) {
    value = amount * (rate + rate * (ratePercent / 100));
  }

  if (rate !== false && rateValue !== false) {
    value = amount * (rate + rateValue);
  }

  if (rate !== false && rateValue === false && ratePercent === false) {
    value = amount * rate;
  }

  return value;
};

const stringToDecimal = (value, precision = 2) => {
  if (!value) {
    return value.toString();
  }

  let strValue = value.toString();

  const start = strValue.indexOf('.');

  if (start !== -1) {
    let tmpValue = strValue.substr(0, strValue.indexOf('.'));

    if (precision === 0) {
      return tmpValue;
    }

    for (let index = 0; index < precision + 1; index++) {
      tmpValue = tmpValue + strValue.charAt(start + index);
    }

    strValue = tmpValue;
  }

  return strValue;
};

export const toMoneyFormat = (value = '', config = {}) => {
  let stringValue = parseFloat(value);

  if (config.baseCurrency && config.convert && config.baseCurrency !== config.currency) {
    stringValue = currencyConvertion(
      stringValue,
      config.baseCurrency,
      config.currency || '',
      config.exchangeRates || []
    );
  }

  if (config.decimal) {
    stringValue = stringToDecimal(
      stringValue,
      config.precision !== undefined ? config.precision : 2
    );
  } else {
    stringValue = stringToDecimal(stringValue, 0);
  }

  let thousandDelimiter = ',';

  if (config.thousandDelimiter !== undefined) {
    thousandDelimiter = config.thousandDelimiter;
  }

  let tmpPrice = '';
  let counter = 1;
  let counter2 = 3;
  let max = stringValue.length - 1;

  if (stringValue.indexOf('.') !== -1) {
    max = stringValue.indexOf('.') - 1;
    tmpPrice = stringValue.substr(stringValue.indexOf('.'));
  }

  for (let index = max; index >= 0; index--) {
    if (counter === counter2 && max + 1 !== counter2) {
      tmpPrice = `${thousandDelimiter}${stringValue.charAt(index)}${tmpPrice}`;
    } else {
      tmpPrice = `${stringValue.charAt(index)}${tmpPrice}`;
    }

    if (counter === counter2) {
      counter2 = counter2 + 3;
    }
    counter++;
  }

  return tmpPrice;
};

export const toSentenceCase = (value = '') => {
  const stringValue = value.toString();

  const splitName = stringValue.replace(/[ ]/g, '_').split('_');

  let tmpName = '';
  splitName.forEach((str) => {
    if (tmpName) {
      tmpName += ' ';
    }

    tmpName += convertString(str);
  });

  return tmpName.replace(/_/g, ' ');
};

export const getCountryCode = (country) => {
  switch (country) {
    case 'Taiwan':
      return 'TW';
    case 'Macau':
      return 'MO';
    case 'Vietnam':
      return 'VN';
    case 'Hgk':
      return 'HK';
    default:
      if (iso3311a2.getCode(country) === null) {
        return '';
      } else {
        return iso3311a2.getCode(country).toString();
      }
  }
};

// check if currency exist in system scope;
export const currencyScopeChecker = (baseCurrency, selectedCurrency, key = 'symbol') => {
  // set key to symbol or name as return

  let foundCurrency = false;
  let existCurrency = false;

  currencies.forEach((curr) => {
    if (curr.name === baseCurrency) {
      existCurrency = true;
    }

    if (curr.name === selectedCurrency) {
      foundCurrency = curr;
    }
  });

  if (foundCurrency && existCurrency) {
    return foundCurrency[key];
  }

  return baseCurrency;
};

export const countryCodeChecker = (country) => {
  if (country) {
    const countryInfo = [
      'SG',
      'TW',
      'TH',
      'ID',
      'AU',
      'NZ',
      'MY',
      'VN',
      'KH',
      'PH',
      'MM',
      'HK',
      'MO',
    ];
    let newCountryFilter = [];
    country.split(',').forEach((cntry) => {
      if (countryInfo.includes(cntry)) {
        newCountryFilter.push(cntry);
      }
    });

    return newCountryFilter.join(',');
  }

  return country;
};

// get currency number code for 2c2p
export const countryCodeFor2c2p = (code) => {
  const codeList = {
    SGD: '702',
    TWD: '901',
    THB: '764',
    IDR: '360',
    AUD: '036',
    NZD: '554',
    MYR: '458',
    VND: '704',
    KHR: '116',
    PHP: '608',
    MMK: '104',
    INR: '356',
    HKD: '344',
    MOP: '446',
    JPY: '392',
    USD: '840',
  };

  return codeList[code] || 702;
};

// get country name code for 2c2p
export const countryNameCode2c2p = (code) => {
  const nameList = {
    SGD: 'SG',
    TWD: 'TW',
    THB: 'TH',
    IDR: 'ID',
    AUD: 'AU',
    NZD: 'NZ',
    MYR: 'MY',
    VND: 'VN',
    KHR: 'KH',
    PHP: 'PH',
    MMK: 'MM',
    INR: 'IN',
    HKD: 'HK',
    MOP: 'MO',
    JPY: 'JP',
    USD: 'US',
  };

  return nameList[code] || 'SG';
};

export const getBookingStatus = (type) => {
  switch (type) {
    case 'REQUESTED':
    case 'STANDBY':
      return 'PENDING';

    case 'WITHDRAWN_BY_TRAVELER':
    case 'CANCELED_BY_TRAVELER':
    case 'CANCELED_BY_SUPPLIER':
      return 'CANCELED';

    default:
      return type;
  }
};

export const getAlignments = (pos) => {
  const position =
    envValues.alignments.filter((align) => align.label.toLowerCase() === pos.toLowerCase())[0] ||
    {};

  return position.name || pos;
};

export const handleErrorResponse = (err) => {
  if (err.errors && typeof err === 'object') {
    const errors = [];
    for (const key in err.errors) {
      if (err.errors[key] && err.errors[key][0]) {
        errors.push(err.errors[key][0]);
      }
    }

    if (errors.length > 0) {
      return errors.join('<br />');
    }
  }

  return err.message || 'Server Error';
};

export const generateRandomHex = (length) => {
  let result = '';
  const characters = 'ABCDEF123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

// generateId :: Integer -> String
export function generateRandomString(len) {
  var arr = new Uint8Array((len || 80) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export const sortedCities = (cities, countryName, config = { key: 'cities' }) => {
  const sortItem = citiesOrder.filter((order) => order.country === countryName)[0] || {};

  if (sortItem[config.key]) {
    const sortCities = [];

    sortItem[config.key].forEach((city) => {
      cities.forEach((cty) => {
        if (cty.jp_name === city) {
          sortCities.push(cty);
        }
      });
    });

    return sortCities;
  }

  return cities;
};
