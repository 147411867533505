import { container } from '../../../mainStyle';
const topBarBG = 'rgb(154, 154, 154)';

const scrollColor = '#EC5169';
const maxWidth = 1279;

const header = {
  backgroundColor: '#fff',
  borderBottom: '1px solid rgba(255,255,255,0.7)',
};

const navButton = {
  textTransform: 'none',
  height: 95,
  color: '#000',
  fontWeight: 300,
  borderRadius: 0,
};

const drawer = {
  width: 281,
  backgroundColor: '#fff',
  height: '100%',
  padding: '0 16px',
  '& select:focus': {
    backgroundColor: 'transparent',
  },
  '& .MuiInputAdornment-positionStart svg': {
    left: '16px',
  },
  '& .MuiSelect-iconOutlined': {
    left: '16px',
    paddingLeft: '20px',
  },
  '& .MuiSelect-outlined': {
    paddingLeft: '45px',
  },
};

const select = {
  '& fieldset': {
    border: 0,
  },
  '& .MuiSelect-outlined': {
    color: '#fff',
    paddingLeft: 60,
    paddingRight: 28,
    fontSize: 14,
    width: 40,
  },
  '& select option': {
    color: '#000',
  },
  '& svg': {
    left: 35,
    right: 'unset',
    color: '#fff',
  },
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: 0,
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: 15,
  },
  '& .MuiInputAdornment-positionStart svg': {
    marginRight: -16,
    color: '#fff',
    position: 'absolute',
  },
  '& .MuiSelect-iconOutlined': {
    paddingLeft: '20px',
  },
};

const search = {
  margin: 0,
  width: '80%',
  border: '1px solid #80808075',
  borderRadius: '5px',
  height: '30px',
  marginLeft: '17px',
  '& input': {
    color: '#000',
    height: '10px',
  },
  '& input::placeholder': {
    color: '#000',
  },
  '& fieldset': {
    backgroundColor: 'rgba(255,255,255,0.4)',
    border: 0,
  },
  '& .MuiInputAdornment-positionEnd svg': {
    color: '#000',
    opacity: '50%',
  },
};

const headerStyle = () => ({
  smDown: {
    width: '100%',
    display: 'flex',
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  smUp: {
    display: 'none',
    '@media (max-width: 992px)': {
      display: 'flex',
      width: '100%',
    },
  },
  iconImage: {
    marginLeft: 0,
  },
  scrollColor: {
    color: scrollColor,
  },
  title: {
    flexGrow: 1,
  },
  logout: {
    color: '#fff',
  },
  enLabel: {
    fontFamily: 'Noto Sans JP, Helvetica, Arial, sans-serif',
    marginRight: 13,
  },
  logoImage: {
    // transform: 'scale(0.75)',
    width: '168px',
    marginRight: '31px',
  },
  linkContent: {
    display: 'flex',
    flexGrow: 1,
    minHieght: 64,
    '& .MuiGrid-container': {
      flexWrap: 'nowrap!important',
    },
  },
  button: {
    ...navButton,
  },
  scrollButton: {
    ...navButton,
  },
  scrollButtonActive: {
    ...navButton,
    borderBottom: `2px solid ${scrollColor}`,
  },
  buttonActive: {
    ...navButton,
    borderBottom: `2px solid ${scrollColor}`,
  },
  selectBox: {
    height: 95,
    display: 'flex',
    alignItems: 'center',
  },
  drawerSelectBox: {
    height: 95,
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    ...select,
  },
  scrollSelect: {
    ...select,
    '& .MuiSelect-outlined': {
      ...select['& .MuiSelect-outlined'],
      color: '#000',
      paddingLeft: 50,
    },
    '& select option': {
      color: '#000',
    },
    '& svg': {
      ...select['& svg'],
      color: '#000',
    },
    '& .MuiInputAdornment-positionStart svg': {
      ...select['& .MuiInputAdornment-positionStart svg'],
      color: scrollColor,
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: 12,
    },
  },
  drawerSelect: {
    '& fieldset': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      '& .MuiSelect-iconOutlined': {
        left: 16,
      },
      '& .MuiSelect-root': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    '& .MuiInputAdornment-positionStart svg': {
      color: scrollColor,
    },
  },
  drawerScrollSelect: {
    ...select,
    '& .MuiSelect-outlined': {
      ...select['& .MuiSelect-outlined'],
      color: '#000',
      marginLeft: -8,
      paddingLeft: 55,
    },
    '& select option': {
      color: '#000',
    },
    '& svg': {
      ...select['& svg'],
      left: 6,
      color: '#000',
    },
    '& .MuiInputAdornment-positionStart svg': {
      ...select['& .MuiInputAdornment-positionStart svg'],
      color: scrollColor,
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: 0,
    },
  },

  list: {
    color: '#fff',
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    },
  },
  linkList: {
    color: '#000',
  },
  listActive: {
    color: '#000',
    backgroundColor: '#fff',
  },
  listActiveLink: {
    color: scrollColor,
    fontWeight: 'bold',
    backgroundColor: '#fff',
  },
  scrollListActive: {
    color: '#fff',
    backgroundColor: 'rgb(154, 154, 154);',
  },
  listSelected: {
    color: scrollColor,
    fontWeight: 'bold',
    backgroundColor: '#fff',
  },

  scrollList: {
    color: '#000',
  },
  drawer: {
    ...drawer,
  },
  scrollDrawer: {
    ...drawer,
  },

  appBar: {
    ...header,
  },
  scrollNav: {
    ...header,
  },
  searchBox: {
    height: 95,
    display: 'flex',
    alignItems: 'center',
  },
  drawerSearchBox: {
    height: 95,
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    ...search,
  },
  scrollSearch: {
    ...search,
  },
  scrollDrawerSearch: {
    ...search,
  },
  mobileClose: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
  },
  mobileCloseIcon: {
    color: '#fff',
  },
  scrollMobileCloseIcon: {
    color: '#000',
  },
  positionFixed: {
    boxShadow: 'none',
    zIndex: '1200',
    '& .MuiToolbar-regular': {
      ...container,
      overFlow: 'hidden',
      margin: 'auto',
      borderBottom: '1px solid rgba(255,255,255,0.7)',
    },
  },
  registration: {
    paddingLeft: 6,
    '& .MuiButton-root': {
      padding: '6px 0px',
    },
    '& .MuiButton-label': {
      backdropFilter: 'blur(10px)',
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      marginLeft: '1.5em',
      borderRadius: '15px',
      padding: '20px 5px',
    },
  },
  registrationScroll: {
    marginLeft: 15,
    paddingRight: 8,
    '& .MuiButton-root': {
      padding: '6px 0px',
      width: '100%',
    },
    '& .MuiButton-label': {
      backgroundColor: '#EC5169',
      marginLeft: '1.5em',
      borderRadius: '15px',
      padding: '16px 5px',
      color: '#fff',
      opacity: '50%',
      margin: 'auto !important',
    },
  },
  linkContentFlexEnd: {
    '&:nth-of-type(2)': {
      width: '13.5%',
    },
    '&:nth-of-type(3)': {
      width: '11%',
    },
    '&:nth-of-type(4)': {
      width: '100%',
      textAlign: 'center',
    },
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    registration: {
      '& .MuiButton-root': {
        padding: '6px 8px',
        width: '100%',
        // height: '22px!important',
      },
      '& .MuiButton-label': {
        marginLeft: '0px!important',
      },
    },
  },
  [`@media (max-width: 1329px)`]: {
    positionFixed: {
      '& .MuiToolbar-regular': {
        padding: '0!important',
      },
    },
    linkContentFlexEnd: {
      '& :nth-of-type(3)': {
        // width: '15%',
      },
    },
    linkContentStart: {
      '& :nth-of-type(3)': {
        '& button': {
          width: '200px',
        },
      },
    },
    logoImage: {
      width: '168px',
      margin: '10px 0 5px 20px',
    },
  },
  '@media (max-width: 992px)': {
    logoImage: {
      width: '110px',
    },
  },
  '@media print': {
    scrollNav: {
      display: 'none',
    },
    appBar: {
      display: 'none',
    },
    scrollDrawer: {
      display: 'none',
    },
    drawer: {
      display: 'none',
    },
  },
  menuBox: {
    backgroundColor: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,

    '& li': {
      color: '#000',
    },
  },
  membershipBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    fontWeight: 'bold',
  },
  membershipBoxInfo: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '15px',
    color: '#f05527',
  },
  membershipEmail: {
    fontSize: 12,
    textDecoration: 'none',
    fontWeight: 400,
  },
  drawer1: {
    '& .MuiPaper-root': {
      backgroundColor: topBarBG,
    },
  },
  scrollDrawer1: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
    },
  },
  customerLinks: {
    marginLeft: 24,

    '@media (max-width: 1279px)': {
      marginLeft: 8,
    },
  },
  iconSpacing: {
    marginLeft: -3,
    marginRight: 10,
  },
  profileName: {
    paddingRight: '5px',
  },
  headerSearchIcon: {
    width: '60px',
    height: '60px',
    marginRight: -20,
    '@media (max-width: 575px)': {},
    '&:hover': {
      background: 'none',
    },
  },
  headerIcon: {
    width: '60px',
    height: '60px',
    margin: -10,
    '&:hover': {
      background: 'none',
    },
  },
  guestIcon: {
    width: '60px',
    height: '60px',
    '&:hover': {
      background: 'none',
    },
  },
  profileIcon: {
    width: '40px',
    height: '40px',
    '@media (max-width: 992px)': {
      width: '60px',
      height: '60px',
      margin: -10,
    },
    '&:hover': {
      background: 'none',
    },
  },
  headerContainer: {
    display: 'relative',
  },
  closeBox: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  closeButton: {
    color: 'gray',
    fontSize: '1.2em',
  },
  links2: {
    padding: '4px 12px !important',
  },
  loginLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
});

export default headerStyle;
