export const REQUEST_FETCH_COUPON = 'REQUEST_FETCH_COUPON';
export const FETCH_COUPON = 'FETCH_COUPON';
export const FAIL_FETCH_COUPON = 'FAIL_FETCH_COUPON';

export const REQUEST_CREATE_COUPON = 'REQUEST_CREATE_COUPON';
export const CREATE_COUPON = 'CREATE_COUPON';
export const FAIL_CREATE_COUPON = 'FAIL_CREATE_COUPON';

export const REQUEST_SEARCH_COUPON = 'REQUEST_SEARCH_COUPON';
export const SEARCH_COUPON = 'SEARCH_COUPON';
export const FAIL_SEARCH_COUPON = 'FAIL_SEARCH_COUPON';

export const REQUEST_CHANGE_COUPON_STATUS = 'REQUEST_CHANGE_COUPON_STATUS';
export const CHANGE_COUPON_STATUS = 'CHANGE_COUPON_STATUS';
export const FAIL_CHANGE_COUPON_STATUS = 'FAIL_CHANGE_COUPON_STATUS';

export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION';
export const HANDLE_CLOSE_NOTIFICATION = 'HANDLE_CLOSE_NOTIFICATION';

export const REQUEST_UPDATE_COUPON = 'REQUEST_UPDATE_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const FAIL_UPDATE_COUPON = 'FAIL_UPDATE_COUPON';

export const REQUEST_DELETE_COUPON = 'REQUEST_DELETE_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';
export const FAIL_DELETE_COUPON = 'FAIL_DELETE_COUPON';
