export const REQUEST_FETCH_EXCHANGE_RATES = 'REQUEST_FETCH_EXCHANGE_RATES';
export const FETCH_EXCHANGE_RATES = 'FETCH_EXCHANGE_RATES';
export const FAIL_FETCH_EXCHANGE_RATES = 'FAIL_FETCH_EXCHANGE_RATES';

export const REQUEST_SEARCH_EXCHANGE_RATES = 'REQUEST_SEARCH_EXCHANGE_RATES';
export const SEARCH_EXCHANGE_RATES = 'SEARCH_EXCHANGE_RATES';
export const FAIL_SEARCH_EXCHANGE_RATES = 'FAIL_SEARCH_EXCHANGE_RATES';

export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION';
export const HANDLE_CLOSE_NOTIFICATION = 'HANDLE_CLOSE_NOTIFICATION';

export const REQUEST_UPDATE_EXCHANGE_RATES = 'REQUEST_UPDATE_EXCHANGE_RATES';
export const UPDATE_EXCHANGE_RATES = 'UPDATE_EXCHANGE_RATES';
export const FAIL_UPDATE_EXCHANGE_RATES = 'FAIL_UPDATE_EXCHANGE_RATES';