import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import MatGrid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// components
import Form from './components/Form';
import NotificationAlert from '../../../../components/common/NotificationAlert';
// translation
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';

import AuthService from '../../service';
import { useHistory } from 'react-router-dom';
const domain = process.env.REACT_APP_DOMAIN_API;
const service = new AuthService(domain);

const ResetPassword = (props) => {
  const { sendConfirmationCode } = props;
  const Router = useHistory();
  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState({
    confirmationCode: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [notificationAlertProps, setNotificationAlertProps] = React.useState({
    message: '',
    type: '',
    open: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (sendConfirmationCode) {
      setNotificationAlertProps({
        message: t('Confirmation Code was sent via mail'),
        type: 'success',
        open: true,
        handleNotificationToggle: handleNotificationToggle,
      });
    }
  }, [sendConfirmationCode]); // eslint-disable-line

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    service
      .resetPassword(formValues)
      .then(() => {
        setIsLoading(false);
        setNotificationAlertProps({
          message: 'The password has been reset successfully.',
          type: 'success',
          open: true,
          handleNotificationToggle: handleNotificationToggle,
        });
        _.delay(function () {
          Router.push('/login');
        }, 2000);
      })
      .catch((data) => {
        setIsLoading(false);

        let message = data.error || 'Server Error';

        if (data.error && data.error.includes('Invalid verification code provided')) {
          message = t('The entered confirmation code is incorrect');
        }

        if (data.error === 'Password and confirmation password do not match.') {
          message = t('The password and the confirmation password do not match please confirm');
        }

        if (data.error && data.error.includes('Password does not conform to policy')) {
          message = t('Password Policy Error');
        }

        if (data.error && data.error.includes('[Password] expected string length to be >= 6')) {
          message = t('Password Policy Error');
        }

        if (data.error && data.error.includes('Attempt limit exceeded')) {
          message = t('Attempt limit exceeded, please try after some time');
        }

        setNotificationAlertProps({
          message,
          type: 'error',
          open: true,
          handleNotificationToggle: handleNotificationToggle,
        });
      });
  };

  const handleNotificationToggle = () => {
    setNotificationAlertProps({
      message: '',
      type: '',
      open: false,
    });
  };

  const formProps = {
    formValues,
    isLoading,
    handleInputChange: handleInputChange,
    handleSubmit: handleSubmit,
  };

  return (
    <React.Fragment>
      <MatGrid container justifyContent="center">
        <MatGrid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography align="center" paragraph>
                {t('Reset Password')}
              </Typography>
              <Form {...formProps} />
            </CardContent>
          </Card>
        </MatGrid>
      </MatGrid>
      <NotificationAlert {...notificationAlertProps} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sendConfirmationCode: state.authentication.sendConfirmationCode,
  };
};

export default connect(mapStateToProps, null)(ResetPassword);
