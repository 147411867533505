import { AUTH_LOGIN, AUTH_LOGOUT, RESET_CODE, SET_USER, UPDATE_TOKEN } from './action-types';
import { setCookie, removeCookie } from '../../../utils/Cookie';

const InitialState = {
  isAuthenticated: false,
  sendConfirmationCode: false,
  user: {},
};

export function authentication(state = InitialState, { type, payload = null }) {
  switch (type) {
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_LOGOUT:
      return logout(state);
    case RESET_CODE:
      return code(state);
    case UPDATE_TOKEN:
      return updatedToken(state, payload);

    case SET_USER:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
      };
    default:
      return state;
  }
}

function login(state, payload) {
  setCookie('token', payload.AuthenticationResult.AccessToken);
  setCookie('rfToken', payload.AuthenticationResult.RefreshToken);
  setCookie('tokenExpiresIn', payload.AuthenticationResult.ExpiresIn);
  setCookie('email', payload.user.email);
  setCookie('user_id', payload.user.id);

  return {
    ...state,
    user: payload.user,
    isAuthenticated: true,
  };
}

function logout(state) {
  removeCookie('token');
  removeCookie('rfToken');
  removeCookie('email');
  removeCookie('tokenExpiresIn');

  return {
    ...state,
    user: {},
    isAuthenticated: false,
  };
}

function code(state) {
  return {
    ...state,
    sendConfirmationCode: true,
  };
}

function updatedToken(state, payload) {
  setCookie('token', payload);

  return {
    ...state,
    updatedToken: payload,
  };
}
