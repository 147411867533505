import * as types from './action-types';

const initialState = {
  areas: {
    loading: false,
    data: [],
    error: false,
  },
  fetchingAPI: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_AREAS:
      return {
        ...state,
        areas: {
          ...state.areas,
          data: payload,
          loading: false,
        },
      };

    case types.FETCHING_AREAS:
      return {
        ...state,
        areas: {
          ...state.areas,
          loading: true,
        },
      };

    case types.FAIL_FETCHING_AREAS:
      return {
        ...state,
        areas: {
          ...state.areas,
          loading: false,
          error: payload,
        },
      };

    case types.FETCHING_API:
      return {
        ...state,
        fetchingAPI: payload,
      };

    default:
      return state;
  }
};

export default reducer;
