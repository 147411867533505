import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Parser from 'html-react-parser';
// styles
import styles from '../../assets/jss/styles/components/common/notificationAlertStyle.js';
import { Portal } from '@material-ui/core';

const useStyles = makeStyles(styles);

const NotificationAlert = (props) => {
  const classes = useStyles();

  const { message, type, open, handleNotificationToggle, align = 'center' } = props;

  const colorClasses = classNames({
    [classes.success]: type === 'success',
    [classes.danger]: type === 'error',
  });

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: align,
        }}
        open={open}
        autoHideDuration={type === 'success' ? 3000 : 5000}
        message={
          <div>
            {type === 'error' ? <Error className={classes.icon} /> : null}
            {type === 'success' ? <CheckCircle className={classes.icon} /> : null}
            <span className={classes.iconMessage}>{Parser(message)}</span>
          </div>
        }
        action={
          <IconButton
            className={classes.iconButton}
            aria-label="Close"
            color="inherit"
            onClick={handleNotificationToggle}
          >
            <Close className={classes.close} />
          </IconButton>
        }
        onClose={handleNotificationToggle}
        ContentProps={{
          classes: {
            root: classes.root + ' ' + colorClasses,
            message: classes.message,
          },
        }}
      />
    </Portal>
  );
};

NotificationAlert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  open: PropTypes.bool,
  handleNotificationToggle: PropTypes.func,
};

export default NotificationAlert;
