import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    marginBottom: 12,
  },
  noPadding: { marginBottom: 12 },
  breadCrumb: {
    '& .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator': {
      // color: '#ec5169',
      color: '#000',
      fontSize: 16,
    },
    '& .MuiBreadcrumbs-li': {
      zIndex: 1,
    },
  },
  linkActive: {
    fontSize: '0.85em',
    color: '#ec5169',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  link: {
    fontSize: '0.85em',
    color: '#8d8d8d',
    '&:hover': {
      cursor: 'unset',
    },
  },
});

function BreadCrumb(props) {
  const { links = [], noPadding = false, nextLinkProps = {} } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const renderLinks = () => {
    const max = links.length;

    return links.map(({ url, name, disable, enable, ...rest }, i) => {
      if (disable || (i === max - 1 && !enable)) {
        return (
          <Typography key={i} className={classes.link}>
            {name}
          </Typography>
        );
      }

      return (
        <Link key={i} to={url} {...{ ...nextLinkProps, ...rest }}>
          <Typography className={classes.linkActive}>{name}</Typography>
        </Link>
      );
    });
  };

  return (
    <div className={noPadding ? classes.noPadding : classes.root}>
      <Breadcrumbs separator=">" aria-label="breadcrumb" className={classes.breadCrumb}>
        <Link to="/">
          <Typography className={classes.linkActive}>{t('home')}</Typography>
        </Link>
        {renderLinks()}
      </Breadcrumbs>
    </div>
  );
}

BreadCrumb.propTypes = {
  links: propTypes.arrayOf(Object),
  nextLinkProps: propTypes.object,
  noPadding: propTypes.bool,
};

export default BreadCrumb;
