import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Page404 from 'layouts/404';
import { getCookie } from 'utils/Cookie';

function NotFound() {
  const location = useLocation();
  const token = getCookie('c_token');

  if (location.pathname.includes('/review/registration')) {
    return (
      <Redirect
        to={token ? '/membership/reviews' : '/membership/login?redirect_uri=/membership/reviews'}
      />
    );
  }

  return <Page404 />;
}

export default NotFound;
