import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HistoryIcon from '@material-ui/icons/History';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//image assets
import GuestAvatar from '../../../../src/assets/img/guest-avatar.png';
import LoggedUserIcon from '../../../../src/assets/img/logged-avatar.png';
// styles
import styles from '../../../assets/jss/styles/layouts/common/headerStyle.js';
//translation
// lodash
import _ from 'lodash';

import {
  IconButton,
  Box,
  Grid,
  // InputAdornment,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  Collapse,
  Typography,
} from '@material-ui/core';
import Mybus from '../../../assets/img/mybus_new_logo.png';
import CloseIcon from '@material-ui/icons/Close';

// import TextField from '../../../components/common/TextField';
import * as currencyService from '../store/actions';
import * as service from '../../service';
import { getCookie } from '../../../utils/Cookie.js';
import { useTranslation } from 'react-i18next';
import LogoutModal from './LogoutModal.js';
import { ExpandMore } from '@material-ui/icons';
import { loggedOut, setAuth, setCustomerInfo } from '../../../modules/membership/store/actions.js';
import { showNotifications } from '../../../layouts/notification/store/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { getCommonAreas } from '../service.js';
//component
import SearchFilter from '../../../components/SearchFilter.js';
import TopMenu from './TopMenu';
import membershipService from 'services/MembershipService';

const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const currentPage = location.pathname;

  const dispatch = useDispatch();
  const ENTER_KEY = 13;

  const { t } = useTranslation();

  const membership = useSelector((state) => state.membershipAuth.info);

  const { isViewPage, exchangeRate = [] } = props;
  const [loading, setLoading] = useState(false);
  const [toggleProfile, setTogglerProfile] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [search, setSearch] = useState('');
  const [isScroll, setIsScroll] = useState(false);
  const [hasBanner, setHasBanner] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [isCustomerAuth, setIsCustomerAuth] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const isDesktop = useMediaQuery('(min-width:1279px)');

  const pageInformation = useSelector((state) => state.pageManagement.pageInformation);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      const value = e.target.value;

      // redirect to category page with params
      history.push(`/category?keyword=${value}`);
    }
  };

  useEffect(() => {
    if (!openSearch) {
      setSearch('');
    }
  }, [openSearch]);

  useEffect(() => {
    isCustomerAuth ? setTogglerProfile(true) : setTogglerProfile(false);
  }, [isCustomerAuth]);

  useEffect(() => {
    dispatch(getCommonAreas());
    const customerData = getCookie('c_token');

    if (customerData) {
      fetchCustomer();
      dispatch(setAuth());
      setIsCustomerAuth(true);
    }

    const cookieCurrency = getCookie('currentCurrency');

    if (cookieCurrency) {
      dispatch(currencyService.setCurrency(JSON.parse(cookieCurrency)));
    }

    if (exchangeRate.length > 0) {
      dispatch(currencyService.successFetchModifiedCurrencies(exchangeRate));
    } else {
      dispatch(currencyService.fetchModifiedCurrencies());
      service
        .getExchangeRate()
        .then((response) => {
          const { code } = response;
          if (!code) {
            dispatch(currencyService.successFetchModifiedCurrencies(response));
          }
        })
        .catch((error) => {
          dispatch(currencyService.failFetchModifiedCurrencies(error));
        });
    }

    setInterval(() => {
      const items = localStorage.getItem('tourID');
      if (items !== '' && items !== undefined) {
        const cookieValue = JSON.parse(items);
        if (Array.isArray(cookieValue) && cookieValue.length !== cartCount) {
          setCartCount(cookieValue.length);
        }
      }
    }, 1000);

    if (currentPage.includes('membership') || isCustomerAuth) {
      setTogglerProfile(true);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setHasBanner(false);

    if (!_.isEmpty(pageInformation)) {
      if (!_.isEmpty(pageInformation.components)) {
        const regex = new RegExp('\t', 'g'); // eslint-disable-line
        let checkBanner = JSON.parse(pageInformation.components.replace(regex, '\\t')); // eslint-disable-line
        if (
          !_.isEmpty(checkBanner[0]) &&
          checkBanner[0].component &&
          checkBanner[0].component.includes('Banner')
        ) {
          setHasBanner(true);
        }
      }
    }
  }, [pageInformation, currentPage]); // eslint-disable-line

  useEffect(() => {
    if (isDesktop) {
      setToggleDrawer(false);
    }
  }, [isDesktop]); // eslint-disable-line

  const fetchCustomer = () => {
    setLoading(true);
    membershipService.getCustomer().then((res) => {
      if (res.status) {
        const values = res.customer || {};
        dispatch(setCustomerInfo({ ...values, birthday: res.cms.birthday }));
      } else {
        dispatch(showNotifications(res.error_message || 'Server Error', 'error'));
      }
      setLoading(false);
    });
  };

  const handleScroll = () => {
    if (!isViewPage && window.pageYOffset > 65) {
      setIsScroll(true);
    } else if (isViewPage && window.pageYOffset > 89) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  const handleMembershipLogout = async () => {
    await dispatch(loggedOut());
    window.location.href = '/';
  };

  const customerLinks = [
    {
      url: '/membership/mypage',
      label: t('Manage account'),
      startIcon: <PersonOutlineIcon />,
    },
    {
      url: '/membership/booking-history',
      label: t('Booking History'),
      startIcon: <DateRangeIcon />,
    },
    {
      url: '/browse-history',
      label: t('Browser History'),
      startIcon: <HistoryIcon />,
    },
    {
      url: '/membership/reviews',
      label: '体験談を書く',
      startIcon: <RateReviewIcon />,
    },
    {
      url: '/membership/logout',
      label: t('Signout'),
      customBodyRender: (link, key) => {
        return (
          <ListItem
            key={key}
            button
            onClick={() => setOpenLogoutModal(true)}
            className={
              currentPage === link.url
                ? isScroll || hasBanner === false
                  ? classes.scrollListActive
                  : classes.listActive
                : isScroll || hasBanner === false
                ? classes.scrollList
                : classes.linkList
            }
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText> {t('Sign Out')}</ListItemText>
          </ListItem>
        );
      },
    },
  ];

  const mobileViewLinks = [
    {
      url: '/browse-history',
      label: '閲覧履歴',
    },
    {
      url: '/booking-information',
      label: '予約管理',
    },
    {
      url: '/membership/login',
      label: 'ログイン・新規会員登録',
    },
    {
      url: '/inquiry',
      label: 'よくある質問・お問い合わせ',
    },
  ];

  const getLinkClass = (url) => {
    if (currentPage === url) {
      if (isScroll || hasBanner === false) {
        return classes.scrollListActive;
      } else {
        return classes.listActiveLink;
      }
    } else {
      if (isScroll || hasBanner === false) {
        return classes.scrollList;
      } else {
        return classes.linkList;
      }
    }
  };

  return (
    <div style={{ justifyContent: 'center' }}>
      <TopMenu
        loading={loading}
        isViewPage={isViewPage}
        isScroll={isScroll}
        hasBanner={hasBanner}
        isCustomerAuth={isCustomerAuth}
        setOpenLogoutModal={setOpenLogoutModal}
        setOpenSearch={setOpenSearch}
        openSearch={openSearch}
        setToggleDrawer={setToggleDrawer}
      />
      <Drawer
        anchor="left"
        open={toggleDrawer}
        onClose={() => setToggleDrawer(!toggleDrawer)}
        className={isScroll || hasBanner === false ? classes.scrollDrawer1 : classes.drawer1}
      >
        <div className={isScroll || hasBanner === false ? classes.scrollDrawer : classes.drawer}>
          <Box>
            <Grid container spacing={1} className={classes.headerContainer}>
              <Grid item xs>
                <Link to="/">
                  {isScroll || hasBanner === false ? (
                    <img alt="mybus-header-logo" src={Mybus} className={classes.logoImage} />
                  ) : (
                    <img alt="mybus-header-logo" src={Mybus} className={classes.logoImage} />
                  )}
                </Link>
              </Grid>
              <Grid item>
                <IconButton className={classes.closeBox} onClick={() => setToggleDrawer(false)}>
                  <CloseIcon className={classes.closeButton} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <List>
            {[
              ...mobileViewLinks.filter((link) =>
                isCustomerAuth ? !link.url.includes('membership') : true
              ),
            ].map((link, i) => {
              return (
                <Link to={link.url} key={i + link.url}>
                  <ListItem button>
                    <Typography
                      className={
                        currentPage === link.url
                          ? isScroll || hasBanner === false
                            ? classes.listSelected
                            : classes.listActiveLink
                          : isScroll || hasBanner === false
                          ? classes.scrollList
                          : classes.linkList
                      }
                    >
                      {link.label}
                    </Typography>
                  </ListItem>
                </Link>
              );
            })}
            {isCustomerAuth && (
              <>
                <ListItem
                  button
                  onClick={() => setTogglerProfile(!toggleProfile)}
                  className={
                    currentPage.includes('membership')
                      ? isScroll || hasBanner === false
                        ? classes.scrollListActive
                        : classes.listActive
                      : isScroll || hasBanner === false
                      ? classes.scrollList
                      : classes.linkList
                  }
                >
                  <ListItemText disableTypography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={membership.id ? LoggedUserIcon : GuestAvatar}
                        className={membership.id ? classes.profileIcon : classes.headerIcon}
                      />
                      <span>
                        &nbsp;
                        {membership.nickname || membership.last_name}さん
                      </span>
                    </div>
                  </ListItemText>
                  <ListItemIcon>{toggleProfile ? '' : <ExpandMore />}</ListItemIcon>
                </ListItem>
                <Collapse in={toggleProfile}>
                  <List className={classes.customerLinks}>
                    {customerLinks.map((link, key) =>
                      link.customBodyRender ? (
                        link.customBodyRender(link, key)
                      ) : (
                        <Link to={link.url} key={key + link.url}>
                          <ListItem button className={getLinkClass(link.url)}>
                            <ListItemIcon>{link.startIcon}</ListItemIcon>
                            <ListItemText>{link.label}</ListItemText>
                          </ListItem>
                        </Link>
                      )
                    )}
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </Drawer>
      <SearchFilter
        setOpenSearchModal={setOpenSearch}
        openSearchModal={openSearch}
        handleKeyDown={handleKeyDown}
        search={search}
        setSearch={setSearch}
        fetching={loading}
        isViewPage={isViewPage}
        isScroll={isScroll}
        hasBanner={hasBanner}
        isCustomerAuth={isCustomerAuth}
        setOpenLogoutModal={setOpenLogoutModal}
        setToggleDrawer={setToggleDrawer}
      />

      <LogoutModal
        open={openLogoutModal}
        handleClose={() => setOpenLogoutModal(false)}
        handleConfirm={handleMembershipLogout}
      />
    </div>
  );
};

export default Header;
