import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import styles from '../assets/jss/styles/components/searchFilterStyle';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import BreadCrumb from '../layouts/common/components/BreadCrumb';
import moment from 'moment';
import SearchMainFilter from './subComponents/SearchMainFilter';
import classNames from 'classnames';
import { excludedCities } from 'utils/constants';
import TopMenu from 'layouts/common/components/TopMenu';

const domain = process.env.REACT_APP_DOMAIN_API || 'http://localhost';

const SearchFilter = (props) => {
  const history = useHistory();
  const {
    openSearchModal,
    setOpenSearchModal,
    handleKeyDown,
    search,
    setSearch,
    fetching,
    isViewPage,
    isScroll,
    hasBanner,
    isCustomerAuth,
    setOpenLogoutModal,
    setToggleDrawer,
  } = props;
  const useStyles = makeStyles(styles);
  const location = useLocation();
  const categorySearchView = location && location.pathname.includes('/category');
  const dateFormat = 'YYYY-MM-DD';

  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(openSearchModal);
  const [optionList, setOptionList] = useState({
    categories: [],
    main_categories: [],
    countries: [],
  });
  const [filterData, setFilterData] = useState({
    query: '',
    area_filter: '',
    country_filter: '',
    main_category: '',
    category: '',
    price_filter: '',
    display_amount_filter: [],
    dates: {
      from: undefined,
      to: undefined,
    },
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef();
  const searchEnabled =
    filterData.query !== '' ||
    filterData.city_filter !== '' ||
    filterData.country_filter !== '' ||
    filterData.category !== '' ||
    filterData.price_filter !== '' ||
    filterData.main_category !== '';
  const mobileView = useMediaQuery('(max-width: 992px)');

  const newLinks = [
    {
      name: t('search_condition'),
    },
  ];

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (openSearchModal) {
      setOpenSearch(openSearchModal);
    }
  }, [openSearchModal]);

  useEffect(() => {
    if (openSearch) {
      setFilterData({
        query: '',
        area_filter: '',
        country_filter: '',
        main_category: '',
        category: '',
        sub_categories: '',
        price_filter: '',
        dates: {
          from: undefined,
          to: undefined,
        },
      });
    }
    setOpenSearchModal(openSearch);
  }, [openSearch, setOpenSearchModal]); // eslint-disable-line

  const initialize = async () => {
    setLoading(true);
    const optionData = await (await fetchOptions()).json();

    setOptionList({
      categories: optionData.categories || [],
      main_categories:
        (optionData.main_categories &&
          optionData.main_categories.map((main) => ({
            ...main,
            categories:
              main.categories &&
              main.categories.map((cat) => ({
                ...cat,
                p_name: `${cat.category.name}_${main.id}`,
              })),
          }))) ||
        [],
      countries:
        (optionData.countries &&
          optionData.countries.map((cn) => ({
            ...cn,
            cities:
              (cn.cities && cn.cities.filter((cty) => !excludedCities.includes(cty.jp_name))) || [],
          }))) ||
        [],
    });

    setLoading(false);
  };

  const fetchOptions = () => {
    return fetch(`${domain}/v1/category-page-options`);
  };

  const handleFilterChange = (newFilter) => {
    setFilterData((prev) => ({
      ...prev,
      ...newFilter,
    }));
  };

  const handleKeywordChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setFilterData((prev) => ({
      ...prev,
      query: value,
    }));
  };

  const handleDateFilterChange = (from, to) => {
    setFilterData((prev) => ({
      ...prev,
      dates: {
        from,
        to,
      },
    }));
  };

  const handleSearchClick = () => {
    const from = filterData['dates'].from
      ? moment(filterData['dates'].from).format(dateFormat)
      : '';
    const to = filterData['dates'].to ? moment(filterData['dates'].to).format(dateFormat) : '';
    setOpenSearch(false);
    setOpenSearchModal(false);

    const query = {
      keyword: filterData['query'] || '',
      country: filterData['country_filter'] || '',
      area: filterData['area_filter'] || '',
      main_category: filterData['main_category'] || '',
      category: filterData['category'] || '',
      price_filter: filterData['price_filter'] || '',
      sub_categories: filterData.sub_categories || '',
      from: from || '',
      to: to || '',
    };

    localStorage.setItem('popup-search', JSON.stringify(query));

    if (window.location.pathname === '/category') {
      window.location.href = '/category?popup_search';
    } else {
      history.push(`/category?popup_search`);
    }
  };

  const handleClose = () => {
    mobileView && setOpenSearch(false) && setOpenSearchModal(false);
  };

  const handleChangeScroll = (type) => {
    if (ref.current) {
      const initHeight = ref.current?.scrollHeight;
      const id = setInterval(() => {
        if (ref.current?.scrollHeight !== initHeight) {
          if (type === 'allCategory') {
            ref.current.scrollTo({ top: 3000 });
          } else if (type === 'mainCategory') {
            ref.current.scrollTo({ top: 3500 });
          }
          clearInterval(id);
        }
      }, 300);
    }
  };

  return (
    mobileView && (
      <Box
        component="div"
        className={classNames({
          [classes.container]: !categorySearchView,
        })}
      >
        <Dialog
          classes={{
            paper: classNames(classes.container, {
              [classes.category]: true,
            }),
          }}
          fullScreen={true}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openSearchModal}
        >
          <TopMenu
            loading={fetching}
            isViewPage={isViewPage}
            isScroll={isScroll}
            hasBanner={hasBanner}
            isCustomerAuth={isCustomerAuth}
            setOpenLogoutModal={setOpenLogoutModal}
            setOpenSearch={setOpenSearch}
            openSearch={openSearch}
            setToggleDrawer={setToggleDrawer}
          />
          <div ref={ref} style={{ overflowY: 'auto' }}>
            <DialogTitle
              className={classes.mobileViewDialogTitle}
              id="customized-dialog-title"
              disableTypography
            >
              <BreadCrumb links={newLinks} />
            </DialogTitle>
            <DialogContent
              className={classNames({
                [classes.content]: true,
              })}
            >
              {!loading && (
                <SearchMainFilter
                  filterData={filterData}
                  handleSearchClick={handleSearchClick}
                  setOpenSearch={setOpenSearch}
                  search={search}
                  openSearch={openSearch}
                  handleKeywordChange={handleKeywordChange}
                  handleKeyDown={handleKeyDown}
                  handleFilterChange={handleFilterChange}
                  handleDateFilterChange={handleDateFilterChange}
                  mode="search"
                  handleChangeScroll={handleChangeScroll}
                  options={optionList}
                />
              )}
            </DialogContent>
          </div>
          <Grid item xs={12} className={classes.fixedControl}>
            <center>
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={handleSearchClick}
                disabled={!searchEnabled}
              >
                {t('SearchFor')}
              </Button>
            </center>
          </Grid>
        </Dialog>
      </Box>
    )
  );
};

SearchFilter.propTypes = {
  openSearchModal: PropTypes.bool,
  setOpenSearchModal: PropTypes.func,
  handleKeyDown: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  fetching: PropTypes.bool,
  isViewPage: PropTypes.bool,
  isScroll: PropTypes.bool,
  hasBanner: PropTypes.bool,
  isCustomerAuth: PropTypes.bool,
  setOpenLogoutModal: PropTypes.func,
  setToggleDrawer: PropTypes.func,
};

export default SearchFilter;
