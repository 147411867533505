import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import Private from './Private';
import Public from './Public';
import Login from 'modules/auth/pages/login/Login';
import ResetPassword from 'modules/auth/pages/reset-password/ResetPassword';
import membershipRoutes from './membershipRoutes';
import MembershipAuth from './MembershipAuth';
import NotFound from './NotFound';

function Router() {
  return (
    <Switch>
      {routes.map((route, index) =>
        route.auth ? (
          <Private key={index} exact {...route} />
        ) : (
          <Public key={index} exact {...route} />
        )
      )}
      {membershipRoutes
        .map((route) => ({ ...route, path: `/membership/${route.path}` }))
        .map((route, index) =>
          route.auth ? (
            <MembershipAuth key={route.path} exact {...route} />
          ) : (
            <Public key={route.path} exact {...route} />
          )
        )}
      <Route path="/login" component={Login} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export default Router;
