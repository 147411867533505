export const REQUEST_FETCH_RESERVATION = 'REQUEST_FETCH_RESERVATION';
export const FETCH_RESERVATION = 'FETCH_RESERVATION';
export const FAIL_FETCH_RESERVATION = 'FAIL_FETCH_RESERVATION';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';

export const SUCCESS_UPDATE_PAYMENT_STATUS = 'SUCCESS_UPDATE_PAYMENT_STATUS';

export const FETCHING_COUNTRIES = 'FETCHING_COUNTRIES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FAIL_FETCH_COUNTRIES = 'FAIL_FETCH_COUNTRIES';

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';