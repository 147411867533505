import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Pages from '@material-ui/icons/Pages';
import People from '@material-ui/icons/People';
import Lock from '@material-ui/icons/Lock';
import Inquiry from '@material-ui/icons/QuestionAnswer';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AltEmail from '@material-ui/icons/AlternateEmail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CategoryIcon from '@material-ui/icons/Category';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
// import InstagramIcon from '@material-ui/icons/Instagram';
// styles
import styles from '../../../assets/jss/styles/layouts/admin/sidebarStyle.js';
// assets
import avatar from '../../../assets/img/avatar.png';
// translation
import { useTranslation } from 'react-i18next';

import KeyboardIcon from '@material-ui/icons/Keyboard';
import SettingsIcon from '@material-ui/icons/Settings';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isPermitted } from 'utils/permissionHelpers.js';

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useLocation();

  const user = useSelector((state) => state.authentication.user);

  const links = [
    {
      path: '/admin/reservation-management',
      label: t('reservation_management'),
      icon: <ConfirmationNumberIcon />,
    },

    {
      path: '/admin/page-management',
      label: t('page_management'),
      icon: <Pages />,
    },
    {
      path: '/admin/popular-product-management',
      label: t('popular_product'),
      icon: <LoyaltyIcon />,
    },
    // {
    //   path: '/admin/instagram-management',
    //   label: t('instagram_management'),
    //   icon: <InstagramIcon />,
    // },
    {
      path: '/admin/exchange-rate-management',
      label: t('Exchange Rate Management'),
      icon: <AttachMoneyIcon />,
    },
    {
      path: '/admin/coupon-management',
      label: t('coupon_management'),
      icon: <KeyboardIcon />,
    },
    {
      path: '/admin/categories-management',
      label: t('categories_management'),
      icon: <CategoryIcon />,
    },
    {
      path: '/admin/hotel-management',
      label: t('Hotel List Management'),
      icon: <EmojiTransportationIcon />,
    },
    {
      path: '/admin/user-management',
      label: t('user_management'),
      icon: <People />,
    },
    {
      path: '/admin/inquiry-management',
      label: t('inquiry_management'),
      icon: <Inquiry />,
    },
    {
      path: '/admin/log-management',
      label: t('logs'),
      icon: <EventNoteIcon />,
    },
    {
      path: '/admin/email-subscription',
      label: t('email_subscription'),
      icon: <AltEmail />,
    },
    {
      path: '/admin/image-management',
      label: t('image_management'),
      icon: <PhotoLibrary />,
    },
    {
      path: '/admin/file-management',
      label: t('file_management'),
      icon: <FileCopyIcon />,
    },
    {
      path: '/admin/notification-management',
      label: t('notification_management'),
      icon: <NotificationsIcon />,
    },

    {
      path: '/admin/maintenance',
      label: t('Maintenance'),
      icon: <SettingsIcon />,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={classNames(classes.userBox, !props.open && classes.hide)}>
        <Avatar src={avatar} className={classes.large} />
        <Typography variant="subtitle1">{user.full_name || 'User'}</Typography>
        <Typography variant="caption">{(user.roles && user.roles.join(',')) || ''}</Typography>
      </div>
      <div className={classNames(classes.userMobileBox, props.open && classes.hide)}>
        <Avatar src={avatar} className={classes.small} />
      </div>
      <Divider />

      <List>
        <Link to={'/dashboard'}>
          <ListItem button selected={router.pathname === '/dashboard'}>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary={t('dashboard')} />
          </ListItem>
        </Link>
        {links
          .filter((link) => isPermitted(user, link.path))
          .map((link, index) => (
            <Link key={index} to={link.path}>
              <ListItem button selected={router.pathname === link.path}>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.label} />
              </ListItem>
            </Link>
          ))}
        <Link to={'/change-password'}>
          <ListItem button selected={router.pathname === '/change-password'}>
            <ListItemIcon>
              <Lock />
            </ListItemIcon>
            <ListItemText primary={t('change_password')} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default Sidebar;
