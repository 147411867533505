const text = {
  color: '#e6e6e6!important',
  fontSize: '.9em',
};

const modalTextColor = {
  color: 'rgba(0, 0, 0, 0.87)!important',
  '&:hover': {
    color: '#EC5169 !important',
  },
};

const areasStyle = {
  text,
  countryText: {
    cursor: 'pointer',
    color: '#fff!important',
  },
  countryTextBlackColor: {
    ...modalTextColor,
  },
  cityText: {
    ...text,
    paddingRight: 10,
    cursor: 'pointer',
  },
  cityTextBlackColor: {
    ...modalTextColor,
    paddingRight: 10,
    fontSize: '.9em',
    marginBottom: 0,
    cursor: 'pointer',
  },
  city: {
    padding: '4px 15px!important',
  },
};

export default areasStyle;
