import * as notificationActions from './store/actions';
import response from '../../../utils/Response';
import fetch from 'isomorphic-unfetch';
import { getCookieFromBrowser } from '../../../utils/Cookie';

const domain = process.env.REACT_APP_DOMAIN_API || 'http://localhost:3000';

const getToken = () => {
  return getCookieFromBrowser('token');
};

const getUserId = () => getCookieFromBrowser('user_id');

export function getNotification(params) {
  const userId = getUserId();

  const url = params
    ? `${domain}/v1/admin/notifications?id=${userId}&page=${params.page}&limit=${params.limit}`
    : `${domain}/v1/admin/notifications/${userId}`;

  return (dispatch) =>
    new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        method: 'GET',
      })
        .then(response)
        .then((res) => {
          dispatch(notificationActions.loadNotifications(res));
          resolve(res);
        })
        .catch((err) => {
          err.then((data) => {
            reject(data);
          });
        });
    });
}

export function readNotification() {
  const userId = getUserId();

  return (dispatch) =>
    new Promise((resolve, reject) => {
      fetch(`${domain}/v1/admin/readNotification/${userId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        method: 'GET',
      })
        .then(response)
        .then((res) => {
          dispatch(notificationActions.readNotifications(res.data));
          resolve(res);
        })
        .catch((err) => {
          err.then((data) => {
            reject(data);
          });
        });
    });
}

export function getUnreadNotification() {
  const userId = getUserId();

  return (dispatch) =>
    new Promise((resolve, reject) => {
      fetch(`${domain}/v1/admin/unreadNotifications/${userId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        method: 'GET',
      })
        .then(response)
        .then((res) => {
          dispatch(notificationActions.loadUnreadNotifications(res.data));
          resolve(res);
        })
        .catch((err) => {
          err.then((data) => {
            reject(data);
          });
        });
    });
}

export function readNotificationByViewId(viewId) {
  const userId = getUserId();

  return (dispatch) =>
    new Promise((resolve) => {
      fetch(`${domain}/v1/admin/readNotification/${userId}/${viewId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        method: 'GET',
      })
        .then(response)
        .then((res) => {
          dispatch(notificationActions.readNotificationsByViewId(res.data));
          resolve(res);
        })
        .catch((err) => {
          err.then((data) => {
            resolve(data);
          });
        });
    });
}
