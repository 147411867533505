import { container } from '../../../mainStyle';

const text = {
  color: '#e6e6e6',
  fontSize: '.89em',
  marginTop: '6px',
  marginBottom: '6px',
};

const boxSizing = {
  boxSizing: 'border-box',
};

const footerStyle = {
  footer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#333',
    display: 'flex',
    color: '#fff',
    fontWeight: 400,
    '@media (max-width: 960px)': {
      padding: '15px 28px',
    },
    '@media (max-width: 580px)': {
      padding: '0px 0px',
    },
  },
  city: {
    padding: '8px 12px',
    paddingLeft: 30,
    textDecoration: 'dotted',
  },
  country: {
    padding: '8px 12px',
    textDecoration: 'dotted',
  },
  content: {
    ...container,
    ...boxSizing,
    paddingLeft: '32px !important',
    paddingRight: '32px !important',
    '@media (max-width: 580px)': {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
  },
  subscribeButton: {
    textTransform: 'initial',
    height: 50,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#ec5169',
    '&:hover': {
      backgroundColor: '#ec5169',
    },
  },
  inputEmail: {
    height: 50,
    backgroundColor: '#fff',
    color: '#fff',
    borderRadius: 6,
    '& > div': {
      height: 50,
    },
    '& > div > fieldset': {
      height: 54,
    },
  },
  mobileInputEmail: {
    '@media (max-width: 576px)': {
      display: 'none',
    },
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: 6,
    padding: 12,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& div p': {
      flexGrow: 1,
      margin: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileSelectCountry: {
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    color: '#000',
    overflow: 'auto',
    maxHeight: 200,
    marginTop: 1,
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 999,
    '& li': {
      listStyleType: 'none',
    },
    '& li:hover': {
      backgroundColor: '#ec5169',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  divider: {
    marginTop: 28,
    backgroundColor: '#828181',
    marginBottom: 14,
  },
  text,
  textDescription: {
    ...text,
    ...boxSizing,
    paddingLeft: 10,
    paddingRight: 10,
  },
  areas: {
    ...boxSizing,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
  countryList: {
    marginTop: 28,
  },
  logo: {
    width: '100%',
    maxWidth: 150,
    transform: 'translate(-10px,0)',
  },
  logoMobile: {
    width: '100%',
    maxWidth: 200,
    transform: 'scale(0.6,0.6)',
  },
  logoContent: {
    ...boxSizing,
    marginTop: 28,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
  socialMediaContent: {
    ...boxSizing,
    marginTop: 28,
    marginBottom: 28,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
  socialMediaImage: {
    marginLeft: 10,
    marginRight: 10,
  },
  sectionSubscribe: {
    marginTop: 60,
  },
  '@media print': {
    footer: {
      display: 'none',
    },
  },
  staticPageLinks: {
    textDecoration: 'none !important',
  },
  footerDivider: {
    backgroundColor: 'gray',
    width: '100%',
    marginBottom: '20px',
  },
  bottomPadding: {
    height: '20px',
  },
};
export default footerStyle;
