import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import SearchOutlinedIcon from '../../../../src/assets/img/search.png';
import { Box, CircularProgress, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import menuIcon from '../../../assets/img/menu.png';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/styles/layouts/common/headerStyle.js';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Mybus from '../../../assets/img/mybus_new_logo.png';
import Avatar from '@material-ui/core/Avatar';
import GuestAvatar from '../../../../src/assets/img/guest-avatar.png';
import LoggedUserIcon from '../../../../src/assets/img/logged-avatar.png';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HistoryIcon from '@material-ui/icons/History';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const TopMenu = (props) => {
  const {
    loading,
    isViewPage,
    isScroll,
    hasBanner,
    isCustomerAuth,
    setOpenLogoutModal,
    setOpenSearch,
    openSearch,
    setToggleDrawer,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);
  //   const [openSearch, setOpenSearch] = useState(false);
  const currentPage = location.pathname;
  const classes = useStyles();
  const directPages = ['campaign', 'country', 'category'];
  const membership = useSelector((state) => state.membershipAuth.info);
  const anchorRef = useRef(null);

  const mobileView = useMediaQuery('(max-width: 992px)');

  const links = [
    // {
    //   url: '/',
    //   label: 'ホーム',
    // },
    // {
    //   url: '/category',
    //   label: 'ツアー',
    // },
  ];

  const links2 = [
    {
      url: '/inquiry',
      label: 'よくある質問・お問い合わせ',
    },
    {
      url: '/browse-history',
      label: '閲覧履歴',
    },

    {
      url: '/booking-information',
      label: '予約管理',
    },

    {
      url: '/membership/login',
      label: 'ログイン',
      customBodyRender: ({ url, label }, i) => (
        <div
          key={i}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            padding: '6px 0',
            boxSizing: 'border-box',
          }}
          className={
            currentPage === url
              ? isScroll || hasBanner === false
                ? classes.scrollButtonActive
                : classes.buttonActive
              : isScroll || hasBanner === false
              ? classes.scrollButton
              : classes.button
          }
        >
          <Link to={url} className={classes.loginLink}>
            <span>
              <Avatar
                src={membership.id ? LoggedUserIcon : GuestAvatar}
                className={membership.id ? classes.profileIcon : classes.headerIcon}
              />
            </span>
            <span
              style={{
                color: '#000',
                paddingBottom: '-10px',
                paddingLeft: '5px',
              }}
            >
              ログイン・会員登録
            </span>
          </Link>
        </div>
      ),
    },
  ];

  const handleToggleMenu = () => {
    setToggleMenu((prev) => !prev);
  };

  const handleNavigate = (url) => {
    history.push(url);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    handleToggleMenu();
  };

  const handleSearchClick = () => {
    mobileView && setOpenSearch(!openSearch);
  };

  const handleProfileClick = () => {
    if (openSearch) setOpenSearch(false);

    if (membership.id) setToggleDrawer(true);
    else history.push('/membership/login');
  };

  return (
    <AppBar
      position={isViewPage === undefined ? 'fixed' : 'relative'}
      className={isScroll || hasBanner === false ? classes.scrollNav : classes.appBar}
      classes={{
        positionFixed: classes.positionFixed,
      }}
    >
      <Toolbar
        style={{
          width:
            directPages.some((word) => currentPage.toLowerCase().includes(word)) ||
            currentPage === '/'
              ? '1352px'
              : '1400px',
        }}
      >
        <Box>
          <Link to="/">
            {isScroll || hasBanner === false ? (
              <img alt="header-banner-logo" src={Mybus} className={classes.logoImage} />
            ) : (
              <img alt="header-banner-logo" src={Mybus} className={classes.logoImage} />
            )}
          </Link>
        </Box>
        <div className={classes.smDown}>
          <div className={classes.linkContent}>
            <Grid container spacing={1} className={classes.linkContentStart}>
              {links
                .filter((link) => !link.url.includes('membership'))
                .map(({ label, url, ...rest }, i) => (
                  <Grid key={url} item>
                    <Link to={url !== '' ? url : '#'} key={i + label}>
                      <Button
                        className={
                          currentPage === url
                            ? isScroll || hasBanner === false
                              ? classes.scrollButtonActive
                              : classes.buttonActive
                            : isScroll || hasBanner === false
                            ? classes.scrollButton
                            : classes.button
                        }
                        {...rest}
                      >
                        {label}
                      </Button>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </div>
          <div className={classes.linkContent}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              className={classes.linkContentFlexEnd}
            >
              {links2
                .filter((link) => (isCustomerAuth ? !link.url.includes('membership') : true))
                .map((link, i) => {
                  const { label, url, customBodyRender, ...rest } = link;

                  return (
                    <Grid
                      key={`membership${i + 25}`}
                      item
                      {...(label === '' && {
                        style: { width: '25%' },
                      })}
                      className={classes.links2}
                    >
                      {customBodyRender ? (
                        customBodyRender(link, i)
                      ) : (
                        <Link to={url !== '' ? url : '#'}>
                          <Button
                            className={
                              currentPage === url
                                ? isScroll || hasBanner === false
                                  ? classes.scrollButtonActive
                                  : classes.buttonActive
                                : isScroll || hasBanner === false
                                ? classes.scrollButton
                                : classes.button
                            }
                            {...rest}
                          >
                            {label}
                          </Button>
                        </Link>
                      )}
                    </Grid>
                  );
                })}

              {isCustomerAuth && (
                <Grid item className={classes.links2}>
                  <Button
                    ref={anchorRef}
                    onClick={handleToggleMenu}
                    aria-controls={toggleMenu ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    className={
                      currentPage === '/membership'
                        ? isScroll || hasBanner === false
                          ? classes.scrollButtonActive
                          : classes.buttonActive
                        : isScroll || hasBanner === false
                        ? classes.scrollButton
                        : classes.button
                    }
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Avatar
                          src={membership.id ? LoggedUserIcon : GuestAvatar}
                          className={membership.id ? classes.profileIcon : classes.headerIcon}
                        />
                        <span className={classes.profileName}>
                          &nbsp;{membership.nickname || membership.last_name}さん
                        </span>
                      </>
                    )}
                  </Button>
                  <Popper
                    open={toggleMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          <ClickAwayListener onClickAway={handleMenuClose}>
                            <Paper>
                              <div className={classes.membershipBox}>
                                <Avatar
                                  src={membership.id ? LoggedUserIcon : GuestAvatar}
                                  className={
                                    membership.id ? classes.profileIcon : classes.headerIcon
                                  }
                                />
                                <div className={classes.membershipBoxInfo}>
                                  <span>
                                    {[membership.last_name, membership.first_name].join(' ')}
                                  </span>
                                  <span className={classes.membershipEmail}>
                                    {membership.email || 'email@email.com'}
                                  </span>
                                </div>
                              </div>
                              <Divider />
                              <MenuList autoFocusItem={toggleMenu} className={classes.menuBox}>
                                <MenuItem onClick={() => handleNavigate('/membership/mypage')}>
                                  <PersonOutlineIcon className={classes.iconSpacing} />{' '}
                                  {t('Manage account')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleNavigate('/membership/booking-history')}
                                >
                                  <DateRangeIcon className={classes.iconSpacing} />{' '}
                                  {t('Booking History')}
                                </MenuItem>
                                <MenuItem
                                  style={{ width: '100%' }}
                                  onClick={() => handleNavigate('/browse-history')}
                                >
                                  <HistoryIcon className={classes.iconSpacing} />{' '}
                                  {t('Browser History')}
                                </MenuItem>
                                <MenuItem onClick={() => handleNavigate('/membership/reviews')}>
                                  <RateReviewIcon className={classes.iconSpacing} /> 体験談を投稿
                                </MenuItem>
                                <MenuItem onClick={() => setOpenLogoutModal(true)}>
                                  <ExitToAppIcon className={classes.iconSpacing} /> {t('Sign Out')}
                                </MenuItem>
                              </MenuList>
                            </Paper>
                          </ClickAwayListener>
                        </div>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              )}
            </Grid>
          </div>
        </div>

        <div className={classes.smUp}>
          <div className={classes.title}></div>
          {currentPage !== '/' && (
            <IconButton edge="start" onClick={handleSearchClick}>
              <img src={SearchOutlinedIcon} alt={''} className={classes.headerSearchIcon} />
            </IconButton>
          )}
          <IconButton onClick={handleProfileClick}>
            <Avatar
              src={membership.id ? LoggedUserIcon : GuestAvatar}
              className={membership.id ? classes.profileIcon : classes.headerIcon}
            />
          </IconButton>
          <IconButton
            edge="start"
            // color="inherit"
            aria-label="open drawer"
            onClick={() => setToggleDrawer(true)}
          >
            <img src={menuIcon} alt="" className={classes.headerIcon} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopMenu.propTypes = {
  loading: PropTypes.bool,
  isViewPage: PropTypes.bool,
  isScroll: PropTypes.bool,
  hasBanner: PropTypes.bool,
  isCustomerAuth: PropTypes.bool,
  setOpenLogoutModal: PropTypes.func,
  setOpenSearch: PropTypes.func,
  openSearch: PropTypes.bool,
  setToggleDrawer: PropTypes.func,
};

export default TopMenu;
