import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'Arial', 'sans-serif'].join(','),
  },
});

export default theme;
