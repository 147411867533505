import {
  Box,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import 'moment/locale/ja';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { isMobile } from 'react-device-detect';
import calendar from '../../assets/img/calendar.png';

moment.locale('ja');

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    padding: '0 6px',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& button': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },

  cell: {
    padding: 0,
    borderBottom: 'none',
  },
  date: {
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeDate: {
    backgroundColor: ' #EB5169',
    color: 'white',
    borderRadius: 100,
  },
  headCell: {
    padding: 12,
    borderBottom: 'none',
  },
  rangeDate: {
    backgroundColor: '#EB5169',
    color: 'white',
    marginLeft: -1,
  },
  fromDate: {
    backgroundColor: ' #EB5169',
    color: 'white',
    borderRadius: 100,
  },
  toDate: {
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    backgroundColor: ' #EB5169',
    color: 'white',
    marginLeft: -1,
  },
  fromDateRange: {
    backgroundColor: ' #EB5169',
    color: 'white',
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  closeHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottomButton: {
    borderRadius: 100,
    maxWidth: 200,
    width: '100%',
    backgroundColor: ' #EB5169',
    '&:hover': {
      backgroundColor: ' #EB5169',
    },
  },
  bottomButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
  },
  disabled: {
    color: '#d4d4d4',
    textAlign: 'center',
    '&:hover': {
      cursor: 'unset',
    },
  },

  // date info
  dateInfo: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 244,
    '@media (min-width: 600px)': {
      justifyContent: 'center',
    },

    '& p:nth-child(2)': {
      margin: '0 2px',
    },

    '& p': {
      fontSize: 14,
    },
  },
  dateItem: {
    padding: '0 24px',

    '@media (max-width: 360px)': {
      padding: '0 6px',
    },
  },
  dateItemDesktop: {
    padding: '0 16px',
  },
  dateButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 6,
  },
  dateInfoBox: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    padding: '4px 12px',
    paddingBottom: 5,

    '&:hover': {
      borderBottom: '2px solid gray',
      paddingBottom: 4,
      cursor: 'pointer',
    },

    '& p': {
      fontSize: 16,
      '@media (max-width: 415px)': {
        fontSize: 17,
      },
      '@media (max-width: 391px)': {
        fontSize: 17,
        padding: '4px 8px',
      },
      '@media (max-width: 376px)': {
        fontSize: 17,
        padding: '4px 6px',
      },
      '@media (max-width: 361px)': {
        fontSize: 17,
        padding: '4px 4px',
      },
    },
  },
  dateInfoBoxDesktop: {
    padding: 12,
    borderWidth: 2,

    '&:hover': {
      padding: 12,
      cursor: 'pointer',
    },
  },
  content: {
    padding: '8px 12px',
    paddingTop: '6px !important',
  },
  iconImageCalendar: {
    width: 20,
    marginRight: -15,
    marginLeft: 5,
  },
  todayDate: {
    backgroundColor: ' #EB5169',
    color: '#fff',
    borderRadius: 100,
  },
  label: {
    fontWeight: 'lighter',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DateRange = (props) => {
  const { disablePast = true, onConfirm, open, onClose, fromDate, toDate } = props;
  const classes = useStyles();

  const [currentDate, setCurrentDate] = useState({
    month: '',
    year: '',
    date: '',
  });
  const [activeDate, setActiveDate] = useState({
    from: '',
    to: '',
  });

  const [counter, setCounter] = useState(0);
  const [hoverState, setHoverState] = useState(true);
  const weekDays = moment.weekdaysShort();

  const { from, to } = activeDate;
  const format = 'YYYY-MM-DD';

  useEffect(() => {
    if (open) {
      if (!fromDate && !toDate) {
        handleReset();
      } else {
        const newDate = moment(fromDate || toDate, format);
        const today = moment(new Date());
        const count = today.diff(newDate, 'days');
        setCounter(Math.round(newDate.diff(today, 'days') / 30));
        setCurrentDate({
          month: moment().add(count, 'days').format('MM'),
          year: moment().add(count, 'days').format('YYYY'),
          date: moment().add(count, 'days').format('YYYY年 MM月'),
        });

        setActiveDate({
          from: fromDate || '',
          to: toDate || '',
        });
      }
    }
  }, [open]); // eslint-disable-line

  const handleReset = () => {
    setCounter(0);
    setCurrentDate({
      month: moment().format('MM'),
      year: moment().format('YYYY'),
      date: moment().format('YYYY年 MM月'),
    });
    setActiveDate({
      from: '',
      to: '',
    });
  };

  useEffect(() => {
    handleUpdateDate();
  }, [counter]); // eslint-disable-line

  const handleUpdateDate = () => {
    setCurrentDate({
      month: moment().add(counter, 'M').format('MM'),
      year: moment().add(counter, 'M').format('YYYY'),
      date: moment().add(counter, 'M').format('YYYY年 MM月'),
    });
  };

  const getMonthDays = () => {
    const list = [];

    const monthDays = moment().add(counter, 'months').daysInMonth();
    const startOfMonths = moment().add(counter, 'months').startOf('month').format('e');

    for (let index = 0; index < startOfMonths; index++) {
      list.push({
        label: 0,
      });
    }

    for (let index = 1; index <= monthDays; index++) {
      list.push({
        label: index,
        date: `${moment().add(counter, 'M').format('YYYY-MM')}-${index
          .toString()
          .padStart(2, '0')}`,
      });
    }

    return list;
  };

  const renderWeek = () => {
    const days = getMonthDays();
    const list = [];

    let counter = 1;
    while (days.length) {
      const date = days.splice(0, 7);

      list.push(<TableRow key={counter}>{renderDays(date)}</TableRow>);

      counter++;
    }

    return list;
  };

  const renderDays = (days) => {
    const dt = [];

    for (let index = 0; index < 7; index++) {
      if (days && days[index]) {
        dt.push(renderCell(index, days[index]));
      } else {
        dt.push(renderCell(index));
      }
    }

    return dt;
  };

  const isRangeDate = (date) => {
    const selectedDate = moment(date, format);
    return selectedDate.isBetween(moment(from, format), moment(to, format));
  };

  const handleChangeDate = (params = {}) => {
    setActiveDate((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const onMouseLeave = (value) => {
    if (isMobile || value < from || (from && to) || !hoverState) return;

    handleChangeDate({
      to: '',
    });
  };

  const onMouseEnter = (value) => {
    if (isMobile || value < from || !hoverState) return;

    if (!from) {
      handleChangeDate({
        from: value,
      });
      return;
    }

    handleChangeDate({
      to: value,
    });
  };

  const handleMobileSelect = (value) => {
    if (from && to) {
      handleChangeDate({
        from: value,
        to: '',
      });

      return;
    }

    if (from && to && value < from) {
      handleChangeDate({
        from: value,
        to: '',
      });

      return;
    }

    if (from && value < from) {
      handleChangeDate({
        from: value,
        to: from,
      });
      return;
    }

    if (!from) {
      handleChangeDate({
        from: value,
      });
      return;
    }

    if (!to) {
      handleChangeDate({
        to: value,
      });
      return;
    }
  };

  const handleSelect = (value) => {
    if (from && to && value < from) {
      handleChangeDate({
        from: value,
        to: '',
      });
      setHoverState(true);
      return;
    }

    if (!hoverState) {
      handleChangeDate({
        from: value,
        to: '',
      });
      setHoverState(true);
      return;
    }

    if (from && to) {
      setHoverState(false);
      return;
    }

    if (from && value < from) {
      handleChangeDate({
        from: value,
        to: from,
      });
      setHoverState(false);
      return;
    }

    if (!from) {
      handleChangeDate({
        from: value,
      });
      return;
    }

    if (!to) {
      handleChangeDate({
        to: value,
      });
      return;
    }
  };

  const handlePrev = () => {
    setCounter((prev) => prev - 1);
  };

  const handleNext = () => {
    setCounter((prev) => prev + 1);
  };

  const handleConfirm = () => {
    onConfirm && onConfirm(from, to);
    handleReset();
    onClose();
  };

  const renderCell = (index, value = {}) => {
    const { date, label } = value;

    const todayDate = moment().format(format);
    const current = moment().subtract(1, 'day');

    if (Object.entries(value).length < 2) {
      return <TableCell className={classes.cell} key={index}></TableCell>;
    }

    if (disablePast && moment(date, format).isBefore(current)) {
      return (
        <TableCell className={classes.cell} key={index}>
          <Box className={classNames(classes.date, classes.disabled)}>
            <Typography>{label}</Typography>
          </Box>
        </TableCell>
      );
    }

    return (
      <TableCell className={classes.cell} key={index}>
        <Box
          onMouseEnter={() => onMouseEnter(date)}
          onMouseLeave={() => onMouseLeave(date)}
          onClick={() => (isMobile ? handleMobileSelect(date) : handleSelect(date))}
          className={classNames({
            [classes.date]: true,
            [classes.rangeDate]: isRangeDate(date),
            [classes.fromDateRange]: date === from && to > from && to,
            [classes.fromDate]: (date === from && !to) || (date === from && date === to),
            [classes.toDate]: date === to && from,
            [classes.todayDate]: !from && !to && date === todayDate,
          })}
        >
          <Typography>{label}</Typography>
        </Box>
      </TableCell>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <Box>
          <Box className={classes.closeHeader}>
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box className={classes.header}>
            <IconButton disabled={disablePast && counter === 0} onClick={handlePrev}>
              <ChevronLeft />
            </IconButton>
            <Typography>{currentDate.date}</Typography>
            <IconButton onClick={handleNext}>
              <ChevronRight />
            </IconButton>
          </Box>
          <Box>
            <Table cellSpacing={0}>
              <TableHead>
                <TableRow>
                  {weekDays.map((week, index) => (
                    <TableCell className={classes.headCell} key={index}>
                      {week}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{renderWeek()}</TableBody>
            </Table>
          </Box>
          <Box className={classes.bottomButtonBox}>
            <Button
              className={classes.bottomButton}
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              disabled={!from || !to}
            >
              検索する
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function CustomDateRange(props) {
  const { onChange, fromDate, toDate, isDesktop = false } = props;

  const format = 'MM月DD日';
  const dFormat = 'YYYY-MM-DD';

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    from: '',
    to: '',
  });

  useEffect(() => {
    setCurrentDate({
      from:
        (fromDate
          ? typeof fromDate === 'string'
            ? moment(fromDate, dFormat).format(dFormat)
            : fromDate
          : '') || '',
      to:
        (toDate
          ? typeof toDate === 'string'
            ? moment(toDate, dFormat).format(dFormat)
            : toDate
          : '') || '',
    });
  }, [fromDate, toDate]); // eslint-disable-line

  const handleChange = (from, to) => {
    onChange &&
      onChange({
        from,
        to,
      });
    setCurrentDate({
      from,
      to,
    });
  };

  return (
    <div className={classes.root}>
      <Box
        className={classNames({
          [classes.dateInfoBox]: true,
          [classes.dateInfoBoxDesktop]: isDesktop,
        })}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Box
          className={classNames({
            [classes.dateInfo]: true,
          })}
        >
          <Typography
            className={classNames(classes.dateItem, {
              [classes.dateItemDesktop]: isDesktop,
            })}
          >
            {(currentDate.from && moment(currentDate.from, dFormat).format(format)) || ''}
          </Typography>
          <Typography> ~ </Typography>
          <Typography
            className={classNames(classes.dateItem, {
              [classes.dateItemDesktop]: isDesktop,
            })}
          >
            {(currentDate.to && moment(currentDate.to, dFormat).format(format)) || ''}
          </Typography>
        </Box>
        <Box className={classes.dateButton}>
          <Typography>まで</Typography>
          <IconButton size="small">
            <img alt="calendar-icon" className={classes.iconImageCalendar} src={calendar} />
          </IconButton>
        </Box>
      </Box>
      <DateRange
        fromDate={currentDate.from || ''}
        toDate={currentDate.to || ''}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleChange}
      />
    </div>
  );
}

export default CustomDateRange;
