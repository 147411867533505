import { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Button,
  IconButton,
  FormControlLabel,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import styles from '../../assets/jss/styles/components/searchFilterStyle';
import { makeStyles } from '@material-ui/core/styles';
import ControlPoint from '@material-ui/icons/ControlPoint';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
//images
import collapse from '../../assets/img/collapse.png';

import moment from 'moment';
// import DateRangePicker from '../../components/common/DateRangePicker';
import { useTranslation } from 'react-i18next';
import CountryFoldComponent from '../CountryFoldComponent';
import _ from 'lodash';
import { searchParams } from 'utils/urlHelper';
import CustomDateRange from './CustomDateRange';
import {
  categoryOrder,
  countryOrder,
  excludedCities,
  subCategoryOrder,
  priceFilterOptions,
} from 'utils/constants';
import { sortedCities } from 'utils/helper';

const domain = process.env.REACT_APP_DOMAIN_API || 'http://localhost';

const SearchMainFilter = (props) => {
  const {
    filterData,
    setOpenSearch = true,
    search,
    handleKeywordChange,
    handleKeyDown,
    handleFilterChange,
    handleDateFilterChange,
    mode,
    // handleHideShowResult,
    handleHideShowButton,
    handleChangeScroll,
    options,
    openSearch,
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useLocation();
  const params = searchParams();
  const searchMode = mode === 'search';
  const resultsMode = mode === 'result';

  const [activeDestinationFilter, setActiveDestinationFilter] = useState('all');
  const [destination, setDestination] = useState(['asia', 'oceania']);
  const [openCategory, setOpenCategory] = useState({});
  const [openCity, setOpenCity] = useState({});
  const dateFormat = 'YYYY-MM-DD';
  const [dateValues, setDateValues] = useState({
    from: filterData.dates.from ? filterData.dates.from : undefined,
    to: filterData.dates.to ? filterData.dates.to : undefined,
  });

  const dateFromParam = router.pathname === '/category' && params.from;
  const dateToParam = router.pathname === '/category' && params.to;

  const [showFilterType, setShowFilterType] = useState({
    calendar: false,
    prices: false,
    areas: false,
    main_category: false,
    all_filters: false,
    main_filters: true,
    country_fold: false,
  });

  const [loading, setLoading] = useState(false);
  const [optionList, setOptionList] = useState({
    categories: [],
    main_categories: [],
    countries: [],
  });

  const checkPrices =
    (filterData.price_filter && filterData.price_filter.split(',').map((val) => parseInt(val))) ||
    [];
  const checkAreaFilters =
    (filterData.area_filter && filterData.area_filter.split(',').filter((val) => val)) || [];
  const checkSubCategory =
    (filterData.sub_categories && filterData.sub_categories.split(',').filter((val) => val)) || [];
  const checkMainCategory =
    (filterData.main_category && filterData.main_category.split(',').filter((val) => val)) || [];

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (openSearch) {
      setShowFilterType((prev) => ({
        ...prev,
        calendar: true,
        areas: true,
        all_filters: true,
      }));
    }
  }, [openSearch]);

  useEffect(() => {
    if (resultsMode && (dateFromParam || dateToParam)) {
      setDateValues({
        from: new Date(moment(dateFromParam).format(dateFormat)),
        to: new Date(moment(dateToParam).format(dateFormat)),
      });
      handleDateFilterChange(
        moment(dateFromParam).format(dateFormat),
        moment(dateToParam).format(dateFormat)
      );
    }
  }, [dateFromParam, dateToParam]); // eslint-disable-line

  useEffect(() => {
    if (showFilterType) {
      showFilterType.main_category && handleChangeScroll('mainCategory');
    }
  }, [showFilterType]); // eslint-disable-line

  const initialize = async () => {
    setLoading(true);

    let optionData = options;

    if (!optionData) {
      optionData = await (await fetchOptions()).json();
    }

    optionData = {
      categories: optionData.categories || [],
      main_categories:
        (optionData.main_categories &&
          optionData.main_categories.map((main) => ({
            ...main,
            categories:
              main.categories &&
              main.categories.map((cat) => ({
                ...cat,
                p_name: `${cat.category.name}_${main.id}`,
              })),
          }))) ||
        [],
      countries:
        (optionData.countries &&
          optionData.countries.map((cn) => ({
            ...cn,
            cities:
              (cn.cities && cn.cities.filter((cty) => !excludedCities.includes(cty.jp_name))) || [],
          }))) ||
        [],
    };
    setOptionList(optionData);

    if (filterData.area_filter) {
      const areaFilterList = filterData.area_filter.split(',');
      const enableCountries = {};
      optionData.countries &&
        optionData.countries.forEach((cn) => {
          cn.cities &&
            cn.cities.forEach((city) => {
              if (areaFilterList.includes(city.jp_name)) {
                enableCountries[cn.country_code] = true;
              }
            });
        });

      setOpenCity((prev) => ({
        ...prev,
        ...enableCountries,
      }));
    }

    setLoading(false);
  };

  const fetchOptions = () => {
    return fetch(`${domain}/v1/category-page-options`);
  };

  const handleOpenCategory = (name, state) => {
    setOpenCategory((prev) => ({
      ...prev,
      [name]: !state,
    }));
    if (name === 'かう' && !state) handleChangeScroll('mainCategory');
  };

  const handleDateChange = ({ from, to }) => {
    setDateValues({
      ...dateValues,
      from: from || '',
      to: to || '',
    });
    handleDateFilterChange(
      (from && moment(from).format(dateFormat)) || '',
      (to && moment(to).format(dateFormat)) || ''
    );
  };

  const handleSetDestinationFilter = (filter) => {
    setActiveDestinationFilter(filter);
    const countryFilter =
      filter === 'asia' ? ['asia'] : filter === 'oceania' ? ['oceania'] : ['asia', 'oceania'];
    setDestination(countryFilter);
  };

  const handleToggleCountryFold = () => {
    resultsMode && handleHideShowButton(showFilterType.country_fold);
    setShowFilterType((prev) => ({
      ...prev,
      country_fold: !prev.country_fold,
      main_filters: !prev.main_filters,
    }));
  };

  const handleOpenCity = (countryCode, state) => {
    setOpenCity((prev) => ({
      ...prev,
      [countryCode]: !state,
    }));
  };

  const handleChange = ({ name, value, status, child, parent, ...others }) => {
    // handle all price
    if (name === 'all_price') {
      handleFilterChange({
        price_filter: !status ? priceFilterOptions.map((price) => price.id).join(',') : '',
      });

      return;
    }

    // handle for price filter
    if (name === 'price_filter') {
      if (others.is_all_prices) {
        handleFilterChange({
          price_filter: value.toString(),
        });

        return;
      }

      handleFilterChange({
        price_filter: checkPrices.includes(value)
          ? checkPrices.filter((price) => price !== value).join(',')
          : [...checkPrices, value].join(','),
      });

      return;
    }

    // handle for area filter all countries
    if (name === 'all_countries') {
      handleFilterChange({
        area_filter: !status
          ? optionList.countries
              .map((cn) => (cn.cities && cn.cities.map((cty) => cty.jp_name).join(',')) || [])
              .join(',')
          : '',
      });

      return;
    }

    // handle for area filter single country
    if (name === 'all_cities') {
      if (others.is_all_countries) {
        handleFilterChange({
          area_filter: child.join(','),
        });
        return;
      }

      handleFilterChange({
        area_filter: !status
          ? [...checkAreaFilters.filter((area) => !child.includes(area)), ...child].join(',')
          : checkAreaFilters.filter((area) => !child.includes(area)).join(','),
      });

      return;
    }

    // handle for single area filter
    if (name === 'area_filter') {
      if (others.is_all_countries) {
        handleFilterChange({
          area_filter: value,
        });

        return;
      }

      if (others.is_all_cities) {
        handleFilterChange({
          area_filter: [...checkAreaFilters.filter((area) => !child.includes(area)), value].join(
            ','
          ),
        });

        return;
      }

      handleFilterChange({
        area_filter: checkAreaFilters.includes(value)
          ? checkAreaFilters.filter((area) => area !== value).join(',')
          : [...checkAreaFilters, value].join(','),
      });

      return;
    }

    // handle for all_main_categories
    if (name === 'all_main_categories') {
      handleFilterChange({
        main_category: !status ? optionList.main_categories.map((main) => main.name).join(',') : '',
        sub_categories: !status
          ? optionList.main_categories
              .map(
                (main) =>
                  (main.categories && main.categories.map((cat) => cat.p_name).join(',')) || []
              )
              .join(',')
          : '',
      });

      return;
    }

    // handle for main_category
    if (name === 'all_subcategories') {
      if (others.is_all_main_categories) {
        handleFilterChange({
          main_category: value,
          sub_categories: child.join(','),
        });

        return;
      }

      handleFilterChange({
        main_category: status
          ? checkMainCategory.filter((main) => main !== value).join(',')
          : [...checkMainCategory, value].join(','),
        sub_categories: status
          ? checkSubCategory.filter((sub) => !child.includes(sub)).join(',')
          : [...checkSubCategory.filter((sub) => !child.includes(sub)), ...child].join(','),
      });

      return;
    }

    // handle for category
    if (name === 'category') {
      if (others.is_all_main_categories) {
        handleFilterChange({
          main_category: '',
          sub_categories: value,
        });
        return;
      }

      // check if main category is selected, then set only 1 sub category
      if (others.is_all_subcategories) {
        handleFilterChange({
          main_category: checkMainCategory.filter((main) => main !== parent).join(','),
          sub_categories: [...checkSubCategory.filter((sub) => !child.includes(sub)), value].join(
            ','
          ),
        });

        return;
      }

      const newValue = {
        sub_categories: checkSubCategory.includes(value)
          ? checkSubCategory.filter((sub) => sub !== value).join(',')
          : [...checkSubCategory, value].join(','),
      };
      handleFilterChange({
        ...newValue,
        main_category:
          newValue.sub_categories.split(',').filter((sub) => child.includes(sub)).length ===
          child.length
            ? [...checkMainCategory.filter((main) => main !== parent), parent].join(',')
            : checkMainCategory.filter((main) => main !== parent).join(','),
      });

      return;
    }
  };

  const handleShowFilterType = (name) => {
    const newFilters = {
      [name]: !showFilterType[name],
    };

    if (name === 'all_filters') {
      newFilters.areas = true;
      newFilters.calendar = true;
    }

    setShowFilterType((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const isAllPrice = () => {
    return (
      priceFilterOptions.length ===
      ((filterData.price_filter && filterData.price_filter.split(',').length) || 0)
    );
  };

  const getAllCategories = () => {
    return _.sortBy(optionList.main_categories, (category) => {
      return categoryOrder.indexOf(category.name);
    });
  };

  const getAllCountries = () => {
    return _.sortBy(optionList.countries, (item) => {
      return countryOrder.indexOf(item.name);
    });
  };

  const isAllCountries = () => {
    const cities = optionList.countries
      .map((cn) => (cn.cities && cn.cities.map((cty) => cty.jp_name).join(',')) || [])
      .join(',')
      .split(',');

    return (
      cities.length === ((filterData.area_filter && filterData.area_filter.split(',').length) || 0)
    );
  };

  const isAllCities = (cities) => {
    const areaList = checkAreaFilters.filter((area) => cities.includes(area));
    return areaList.length === cities.length;
  };

  const isAllMainCategory = () => {
    const categories = optionList.main_categories
      .map((cat) => (cat.categories && cat.categories.map((sub) => sub.p_name).join(',')) || [])
      .join(',')
      .split(',');
    return categories.length === checkSubCategory.length;
  };

  const isAllSubCategories = (categories) => {
    const subList = checkSubCategory.filter((sub) => categories.includes(sub));
    return subList.length === categories.length;
  };

  return loading ? (
    <Grid container justifyContent="center">
      <CircularProgress size={24} />{' '}
    </Grid>
  ) : (
    <Grid container className={classes.filterContainer}>
      <Grid container>
        <TextField
          className={classes.textSearch}
          placeholder={t('ModalSearch')}
          value={resultsMode ? filterData.query : search}
          onChange={handleKeywordChange}
          onClick={handleToggleCountryFold}
          onKeyDown={handleKeyDown}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Collapse in={showFilterType.country_fold} style={{ width: '100%' }}>
        <Grid>
          <Grid container item xs={12} className={classes.selections}>
            <Grid
              item
              xs={4}
              className={
                activeDestinationFilter === 'all'
                  ? classes.activeCountryFilter
                  : classes.countryFilter
              }
            >
              <Button
                className={
                  activeDestinationFilter === 'all'
                    ? classes.activeCountryButton
                    : classes.countryButton
                }
                onClick={() => handleSetDestinationFilter('all')}
              >
                すべての目的地
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              className={
                activeDestinationFilter === 'asia'
                  ? classes.activeCountryFilter
                  : classes.countryFilter
              }
            >
              <Button
                className={
                  activeDestinationFilter === 'asia'
                    ? classes.activeCountryButton
                    : classes.countryButton
                }
                onClick={() => handleSetDestinationFilter('asia')}
              >
                アジア
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              className={
                activeDestinationFilter === 'oceania'
                  ? classes.activeCountryFilter
                  : classes.countryFilter
              }
            >
              <Button
                className={
                  activeDestinationFilter === 'oceania'
                    ? classes.activeCountryButton
                    : classes.countryButton
                }
                onClick={() => handleSetDestinationFilter('oceania')}
              >
                オセアニア
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.imagesContainer}>
            <CountryFoldComponent
              mode={searchMode ? 'search' : 'result'}
              category={destination}
              setOpenSearch={setOpenSearch}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Grid item xs={12}>
        <Collapse in={showFilterType.main_filters}>
          <Grid item xs={12}>
            <h2 className={classes.title}>検索条件</h2>
          </Grid>

          <Grid item xs={12} className={classes.form}>
            <Grid container item xs={12} className={classes.showFilter}>
              <Grid item xs={12} className={classes.toggleControl}>
                <Typography className={classes.toggleLabel}>絞り込み条件</Typography>
                <IconButton onClick={() => handleShowFilterType('all_filters')}>
                  <img src={collapse} alt="" className={classes.collapseButton} />
                </IconButton>
              </Grid>
            </Grid>

            <Collapse in={showFilterType.all_filters}>
              <Grid item xs={12} className={classes.filterContent}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography className={classes.filterHeaders}>
                      <Box component="span" className={classes.filterTitle}>
                        参加日
                      </Box>
                      <Box component="span">
                        {showFilterType.calendar ? (
                          <RemoveCircleOutline
                            className={classes.controls}
                            onClick={() => handleShowFilterType('calendar')}
                          />
                        ) : (
                          <ControlPoint
                            className={classes.controls}
                            onClick={() => handleShowFilterType('calendar')}
                          />
                        )}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={showFilterType.calendar}>
                      <Grid item xs={12}>
                        <CustomDateRange
                          fromDate={dateValues.from}
                          toDate={dateValues.to}
                          onChange={handleDateChange}
                        />
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography className={classes.filterHeaders}>
                      <Box component="span" className={classes.filterTitle}>
                        価格
                      </Box>
                      <Box component="span">
                        {showFilterType.prices ? (
                          <RemoveCircleOutline
                            className={classes.controls}
                            onClick={() => handleShowFilterType('prices')}
                          />
                        ) : (
                          <ControlPoint
                            className={classes.controls}
                            onClick={() => handleShowFilterType('prices')}
                          />
                        )}
                      </Box>
                      <Box component="span" className={classes.subLabel}>
                        {t('MultipleSelectionNote')}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={showFilterType.prices}>
                      <Grid item>
                        <ListItem button>
                          <ListItemIcon>
                            <Checkbox
                              className={classes.listCheck}
                              edge="start"
                              disableRipple
                              onClick={(e) =>
                                handleChange({
                                  name: 'all_price',
                                  status: isAllPrice(),
                                })
                              }
                              checked={isAllPrice()}
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.checkLabel}
                            primary={<Box component="span">全ての価格</Box>}
                          />
                        </ListItem>
                      </Grid>
                      {priceFilterOptions.map((price, index) => {
                        return (
                          <Grid item key={index}>
                            <ListItem button>
                              <ListItemIcon>
                                <Checkbox
                                  className={classes.listCheck}
                                  edge="start"
                                  disableRipple
                                  onClick={(e) =>
                                    handleChange({
                                      name: 'price_filter',
                                      is_all_prices: isAllPrice(),
                                      value: parseInt(price.id),
                                    })
                                  }
                                  checked={checkPrices.includes(price.id)}
                                />
                              </ListItemIcon>
                              <ListItemText
                                className={classes.checkLabel}
                                primary={<Box component="span">{price.value}</Box>}
                              />
                            </ListItem>
                          </Grid>
                        );
                      })}
                    </Collapse>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.filterHeaders}>
                    <Box component="span" className={classes.filterTitle}>
                      {t('CountryCity')}
                    </Box>
                    <Box component="span">
                      {showFilterType.areas ? (
                        <RemoveCircleOutline
                          className={classes.controls}
                          onClick={() => handleShowFilterType('areas')}
                        />
                      ) : (
                        <ControlPoint
                          className={classes.controls}
                          onClick={() => handleShowFilterType('areas')}
                        />
                      )}
                    </Box>
                    <Box component="span" className={classes.subLabel}>
                      {t('MultipleSelectionNote')}
                    </Box>
                  </Typography>
                  <Collapse in={showFilterType.areas}>
                    <List component="nav">
                      <ListItem button>
                        <ListItemIcon>
                          <Checkbox
                            className={classes.listCheck}
                            edge="start"
                            disableRipple
                            checked={isAllCountries()}
                            onClick={(e) =>
                              handleChange({
                                name: 'all_countries',
                                status: isAllCountries(),
                              })
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={<Box component="span">{t('AllCountries')}</Box>} />
                      </ListItem>
                      {getAllCountries().map((country, i) => {
                        const cities =
                          (country.cities && country.cities.map((cty) => cty.jp_name)) || [];

                        return (
                          <Fragment key={i}>
                            <ListItem button>
                              <ListItemIcon>
                                <Checkbox
                                  className={classes.listCheck}
                                  edge="start"
                                  disableRipple
                                  checked={isAllCities(cities)}
                                  onClick={(e) =>
                                    handleChange({
                                      name: 'all_cities',
                                      status: isAllCities(cities),
                                      child: cities,
                                      is_all_countries: isAllCountries(),
                                    })
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Box component="span">{t(country.name)}</Box>
                                    <Box component="span">
                                      {country.cities &&
                                        country.cities.length > 0 &&
                                        (openCity[country.country_code] ? (
                                          <RemoveCircleOutline
                                            className={classes.controls}
                                            onClick={() =>
                                              handleOpenCity(country.country_code, true)
                                            }
                                          />
                                        ) : (
                                          <ControlPoint
                                            className={classes.controls}
                                            onClick={() =>
                                              handleOpenCity(country.country_code, false)
                                            }
                                          />
                                        ))}
                                    </Box>
                                  </>
                                }
                              />
                            </ListItem>
                            <Box component="div" className={classes.list}>
                              <Collapse
                                in={openCity[country.country_code] ? true : false}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box component="div" className={classes.list}>
                                  {country.cities &&
                                    sortedCities(country.cities, country.name).map(
                                      (city, index) => {
                                        return (
                                          <Box
                                            component="span"
                                            className={classes.sublist}
                                            key={index}
                                          >
                                            <Box component="span" className={classes.listIcon}>
                                              <Checkbox
                                                edge="start"
                                                disableRipple
                                                checked={checkAreaFilters.includes(city.jp_name)}
                                                onClick={(e) =>
                                                  handleChange({
                                                    name: 'area_filter',
                                                    value: city.jp_name,
                                                    child: cities,
                                                    is_all_countries: isAllCountries(),
                                                    is_all_cities: isAllCities(cities),
                                                  })
                                                }
                                              />
                                            </Box>
                                            <Box component="span" className={classes.listItem}>
                                              {city.jp_name}
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Box>
                              </Collapse>
                            </Box>
                          </Fragment>
                        );
                      })}
                    </List>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.filterHeaders}>
                    <Box component="span" className={classes.filterTitle}>
                      {t('CategoryTitle')}
                    </Box>
                    <Box component="span">
                      {showFilterType.main_category ? (
                        <RemoveCircleOutline
                          className={classes.controls}
                          onClick={() => handleShowFilterType('main_category')}
                        />
                      ) : (
                        <ControlPoint
                          className={classes.controls}
                          onClick={() => handleShowFilterType('main_category')}
                        />
                      )}
                    </Box>
                    <Box component="span" className={classes.subLabel}>
                      {t('MultipleSelectionNote')}
                    </Box>
                  </Typography>
                  <Grid item xs={12}>
                    <Collapse in={showFilterType.main_category}>
                      <List component="nav">
                        <ListItem button>
                          <ListItemIcon>
                            <Checkbox
                              className={classes.listCheck}
                              edge="start"
                              disableRipple
                              checked={isAllMainCategory()}
                              onClick={(e) =>
                                handleChange({
                                  name: 'all_main_categories',
                                  status: isAllMainCategory(),
                                })
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={<Box component="span">{t('AllCategories')}</Box>}
                          />
                        </ListItem>

                        {getAllCategories().map((category, i) => {
                          const categories =
                            (category.categories &&
                              category.categories.map((cat) => cat.p_name || '')) ||
                            [];

                          return (
                            <Fragment key={i}>
                              <ListItem button>
                                <ListItemIcon>
                                  <Checkbox
                                    className={classes.listCheck}
                                    edge="start"
                                    disableRipple
                                    checked={isAllSubCategories(categories)}
                                    onClick={() =>
                                      handleChange({
                                        name: 'all_subcategories',
                                        value: category.name,
                                        child: categories,
                                        status: isAllSubCategories(categories),
                                        is_all_main_categories: isAllMainCategory(),
                                      })
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <>
                                      <Box component="span" className={classes.mainListItem}>
                                        {category.name}
                                      </Box>
                                      <Box component="span">
                                        {openCategory[category.name] ? (
                                          <RemoveCircleOutline
                                            className={classes.controls}
                                            onClick={() => handleOpenCategory(category.name, true)}
                                          />
                                        ) : (
                                          <ControlPoint
                                            className={classes.controls}
                                            onClick={() => handleOpenCategory(category.name, false)}
                                          />
                                        )}
                                      </Box>
                                    </>
                                  }
                                />
                              </ListItem>
                              <div style={{ paddingLeft: 20 }}>
                                <Collapse
                                  in={openCategory[category.name] ? true : false}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box component="div" className={classes.list}>
                                    {category &&
                                      _.sortBy(category.categories, (item) => {
                                        return subCategoryOrder(category.name).indexOf(
                                          item.category.id
                                        );
                                      }).map((subcategory, index) => {
                                        const subCatName =
                                          (subcategory.category && subcategory.category.name) || '';

                                        return (
                                          <Box
                                            component="span"
                                            className={classes.sublist}
                                            key={index}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  edge="start"
                                                  disableRipple
                                                  checked={checkSubCategory.includes(
                                                    subcategory.p_name
                                                  )}
                                                  onClick={() =>
                                                    handleChange({
                                                      name: 'category',
                                                      value: subcategory.p_name,
                                                      child: categories,
                                                      is_all_subcategories:
                                                        isAllSubCategories(categories),
                                                      is_all_main_categories: isAllMainCategory(),
                                                      parent: category.name,
                                                    })
                                                  }
                                                />
                                              }
                                              label={subCatName}
                                            />
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                </Collapse>
                              </div>
                            </Fragment>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SearchMainFilter;
