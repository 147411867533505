import Loader from 'layouts/Loader';
import AdminLayout from 'layouts/admin/Layout';
import React, { Suspense, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from 'utils/Cookie';

function Private({ component, ...rest }) {
  const token = getCookie('token');

  if (!token) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  const Component = lazy(() => import(`../${component}`));

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <AdminLayout {...props}>
            <Component {...props} />
          </AdminLayout>
        </Suspense>
      )}
    />
  );
}

export default Private;
