import * as authActions from './store/actions';
import response from '../../utils/Response';
import fetch from 'isomorphic-unfetch';
import { getCookieFromBrowser } from '../../utils/Cookie';

const token = getCookieFromBrowser('token');
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};

export default class AuthService {
    constructor(domain) {
        this.domain = domain || 'http://localhost';
        this.login = this.login.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.refreshToken = this.refreshToken(this);
    }

    login(credentials) {
        return (dispatch) =>
            new Promise((resolve, reject) => {
                fetch(`${this.domain}/v1/login`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(credentials),
                })
                    .then(response)
                    .then((res) => {
                        // dispatch(authActions.authLogin(res.data));
                        resolve(res.data);
                    })
                    .catch((err) => {
                        err.then((data) => {
                            reject(data);
                        });
                    });
            });
    }

    forgotPassword(email) {
        return (dispatch) =>
            new Promise((resolve, reject) => {
                fetch(`${this.domain}/v1/password/forgot`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        email,
                    }),
                })
                    .then(response)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(authActions.resetCode());
                    })
                    .catch((err) => {
                        err.then((data) => {
                            reject(data);
                        });
                    });
            });
    }

    resetPassword(credentials) {
        return new Promise((resolve, reject) => {
            fetch(`${this.domain}/v1/password/reset`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    actor_id: getCookieFromBrowser('user_id'),
                    ...credentials,
                }),
            })
                .then(response)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    err.then((data) => {
                        reject(data);
                    });
                });
        });
    }

    changePassword(credentials) {
        credentials['actor_id'] = getCookieFromBrowser('user_id');
        const token = getCookieFromBrowser('token');
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        return new Promise((resolve, reject) => {
            fetch(`${this.domain}/v1/users/change_password`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    accessToken: getCookieFromBrowser('token'),
                    email: getCookieFromBrowser('email'),
                    ...credentials,
                }),
            })
                .then(response)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    err.then((data) => {
                        reject(data);
                    });
                });
        });
    }

    periodicChangePassword(credentials) {
        return () =>
            new Promise((resolve, reject) => {
                fetch(`${this.domain}/v1/password/change_expired_password`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        ...credentials,
                    }),
                })
                    .then(response)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        err.then((data) => {
                            reject(data);
                        });
                    });
            });
    }

    refreshToken() {
        return (dispatch) =>
            new Promise((resolve, reject) => {
                const rfToken = getCookieFromBrowser('rfToken');
                let credentials = {
                    refreshToken: rfToken,
                };
                fetch(`${this.domain}/v1/refresh_token`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(credentials),
                })
                    .then(response)
                    .then((res) => {
                        dispatch(
                            authActions.updateToken(
                                res.data.AuthenticationResult.AccessToken
                            )
                        );
                        window.location.reload(true);
                        resolve(res);
                    })
                    .catch((err) => {
                        err.then((data) => {
                            reject(data);
                        });
                    });
            });
    }

    validateAuthCode(creds) {
        return fetch(`${this.domain}/v1/auth-code/resend`, {
            headers: headers,
            method: 'GET',
            body: JSON.stringify(creds),
        }).then(response);
    }
}
