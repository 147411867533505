export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || ''; // This is your GA Tracking ID
export const GA_TRACKING_ID_OLD = process.env.REACT_APP_GA_TRACKING_ID_OLD || ''; // This is your GA Tracking ID

export const ids = {
  singapore: process.env.REACT_APP_GA_TRACKING_ID_SINGAPORE,
  australia: process.env.REACT_APP_GA_TRACKING_ID_AUSTRALIA,
  taiwan: process.env.REACT_APP_GA_TRACKING_ID_TAIWAN,
  hong_kong: process.env.REACT_APP_GA_TRACKING_ID_HONG_KONG,
  philippines: process.env.REACT_APP_GA_TRACKING_ID_PHILIPPINES,
  malaysia: process.env.REACT_APP_GA_TRACKING_ID_MALAYSIA,
  new_zealand: process.env.REACT_APP_GA_TRACKING_ID_NEW_ZEALAND,
  thailand: process.env.REACT_APP_GA_TRACKING_ID_THAILAND,
  indonesia: process.env.REACT_APP_GA_TRACKING_ID_INDONESIA,
  vietnam: process.env.REACT_APP_GA_TRACKING_ID_VIETNAM,
  cambodia: process.env.REACT_APP_GA_TRACKING_ID_CAMBODIA,
  myanmar: process.env.REACT_APP_GA_TRACKING_ID_MYANMAR,
  macau: process.env.REACT_APP_GA_TRACKING_ID_MACAU,
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (args) => {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      ...args,
      send_to: GA_TRACKING_ID,
    });

    const country = (args.traffic_type && args.traffic_type.replace(' ', '_').toLowerCase()) || '';

    if (ids[country]) {
      window.gtag('event', 'page_view', {
        ...args,
        send_to: ids[country],
      });
    }
  }
};

export const ga_pageview = (args) => {
  if (window.ga) {
    window.ga('send', {
      hitType: 'pageview',
      page: document.location.pathname,
      location: args.page_path,
      title: args.page_title,
    });
  }
};

const gEvent = (event, params) => {
  if (window.gtag) {
    window.gtag('event', event, {
      ...params,
      send_to: GA_TRACKING_ID,
    });

    const country =
      (params.traffic_type && params.traffic_type.replace(' ', '_').toLowerCase()) || '';

    if (ids[country]) {
      window.gtag('event', event, {
        ...params,
        send_to: ids[country],
      });
    }
  }
};

const addToCart = (items) => {
  if (window.ga) {
    items.forEach((item) => {
      window.ga('ec:addProduct', item);
    });
    window.ga('ec:setAction', 'add');
    window.ga('send', 'event', 'UX', 'click', 'add to cart');
  }
};

const productView = (items) => {
  if (window.ga) {
    items.forEach((item) => {
      window.ga('ec:addProduct', item);
    });
    window.ga('ec:setAction', 'detail');
    window.ga('send', 'pageview');
  }
};

const removeToCart = (items) => {
  if (window.ga) {
    items.forEach((item) => {
      window.ga('ec:addProduct', item);
    });
    window.ga('ec:setAction', 'remove');
    window.ga('send', 'event', 'UX', 'click', 'remove to cart');
  }
};

const purchase = (params, items, currency) => {
  if (window.ga) {
    window.ga('set', 'currencyCode', currency);

    items.forEach((item) => {
      window.ga('ec:addProduct', item);
    });
    window.ga('ec:setAction', 'purchase', params);
    window.ga('send', 'pageview');
  }
};

export const gtagConfig = () => {
  let config =
    'window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date; \n';

  if (GA_TRACKING_ID_OLD) {
    config += `ga('create', '${GA_TRACKING_ID_OLD}', 'auto'); \n`;
  }

  config += `ga('require', 'ec'); \n\n`;
  config += `window.dataLayer = window.dataLayer || []; \n`;
  config += `function gtag(){dataLayer.push(arguments);} \n\n`;
  config += `gtag('js', new Date()); \n`;
  config += `gtag('set', { send_page_view: false }); \n\n`;

  if (GA_TRACKING_ID) {
    config += `gtag('config', '${GA_TRACKING_ID}'); \n`;
  }

  for (const key in ids) {
    if (ids[key]) {
      config += `gtag('config', '${ids[key]}');\n`;
    }
  }

  return config;
};

export const gtags = {
  pageview,
  gEvent,
  addToCart,
  productView,
  purchase,
  removeToCart,
  ga_pageview,
};
