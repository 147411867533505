const footerStyle = {
    footer: {
        padding: '15px 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    container: {
        margin: '0 auto',
        padding: '0 15px',
        display: 'flex',
    },
};
export default footerStyle;
