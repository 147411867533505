export const isPermitted = (user, path) => {
  if (!user.user_permissions) {
    return false;
  }

  if (user.user_permissions.includes('All')) {
    return true;
  }

  return (
    user.user_permissions.filter((permission) => path.includes(permission.url_address)).length > 0
  );
};
