import { LOAD_INQUIRY_LIST, FETCH_INQUIRY_LIST } from './action-types';

const initialState = {
    inquiryList: [],
    meta: null,
};

export function inquiryManagement(state = initialState, {type, payload = null}) {
    switch (type) {
    case FETCH_INQUIRY_LIST:
        return {
            ...state,
            isLoading: true
        };
    case LOAD_INQUIRY_LIST:
        return {
            ...state,
            inquiryList: payload.data,
            meta: payload.meta,
        };
    default:
        return state;
    }
}