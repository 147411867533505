import * as types from './actionTypes';

const InitialState = {
  auth: false,
  info: {},
  likedList: [],
  unlikedList: [],
  active: null,
};

const reducer = (state = InitialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case types.LOGGED_IN:
      return {
        ...state,
        auth: true,
      };

    case types.SET_AUTH:
      return {
        ...state,
        auth: true,
      };

    case types.LOGGED_OUT:
      return {
        ...state,
        auth: false,
        info: {},
      };

    case types.SET_INFO:
      return {
        ...state,
        info: payload,
      };
    case types.SET_LIKED:
      return {
        ...state,
        likedList: payload,
      };
    case types.SET_UNLIKED:
      return {
        ...state,
        unlikedList: payload,
      };
    case types.SET_ACTIVE:
      return {
        ...state,
        active: payload,
      };
    default:
      return state;
  }
};

export default reducer;
