import { TextField as MatField } from '@material-ui/core';
import { memo } from 'react';
import React from 'react';

function TextField(props) {
  const { noErrorMessage = false, rows, ...rest } = props;

  let helperText = '';

  if (props.error && props.error.length !== undefined) {
    helperText = props.error[0];
  } else if (props.error && props.error.has(props.name)) {
    helperText = props.error.first(props.name);
  }

  let error = false;

  if (props.error && props.error.length !== undefined) {
    error = true;
  } else if (props.error && props.error.has(props.name)) {
    error = true;
  }

  const attributes = {
    ...rest,
    ...(rows && { minRows: rows }),
    name: props.name || '',
    value: props.value || '',
    error,
    helperText: noErrorMessage ? '' : helperText,
  };
  return <MatField fullWidth variant="outlined" margin="dense" {...attributes} />;
}

export default memo(TextField);
